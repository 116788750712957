import fakeTaxInformationsList from "./fake-tax-information-list.json";
import { apiURL } from "../../usecases/api";

export class ApiTaxInformationUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/taxinformation`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/taxinformation/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async sendReply(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/taxinformation/response`,
      method: "post",
      data,
    });
    return response.data;
  }

  async getTaxInformationListByCompany(id) {
    let response = {
      data: fakeTaxInformationsList.filter((ti) => ti.companyId == id),
    };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/taxinformation?companyId=${id}`,
        method: "GET",
      });
    } catch (error) {
      throw new Error(error);
    }
    return response.data;
  }

  async getRemainingTaxInformation(companyId) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/taxinformation?companyid=${companyId}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async sendTaxInformation(data) {
    await this.httpClient.request({
      url: `${apiURL}/taxinformation`,
      method: "POST",
      data,
    });
  }

  async deleteTaxInformation(ids) {
    await this.httpClient.request({
      url: `${apiURL}/taxinformation`,
      method: "DELETE",
      data: { ids },
    });
  }

  async reopen(id) {
    await this.httpClient.request({
      url: `${apiURL}/TaxInformation/Reopen/${id}`,
      method: "PUT",
    });
  }

  // CREATE DELETE FUNCTION
}
