
/**
 * Índice (tópicos de acordo com documentação em planilha):
 * 
 * - Tópico 2: Valor bruto da nota fiscal
 * - Tópico 5 Alíquota da contribuição patronal
 * - Tópico 10: Valor da remuneração de outras fontes
 * - Tópico 11: Valor da base de cálculo da retenção do previdenciária (do INSS)
 * - Tópico 12: Valor da base de cálculo da contribuição patronal
 */

/**
 * Base de cálculo da retenção previdenciária
 * 
 * 1. Se o prestador for um condutor autônomo de veículo rodoviário ou seu auxiliar:
 * Campo numérico em valor de moeda, não editável. Se os valores informados no tópico 10 forem 
 * maiores que R$6.433,57 a base de cáluclo será R$ 0,00. Caso os valores informados no tópico 
 * 10 não forem maiores que R$ 6.433,57 temos que:
 * a) Se tópico 10 + (20% x tópico 2) for menor que R$ 6.433,57 a base de cáluclo será 20% x tópico 2.
 * b) Se tópico 10 + (20% x tópico 2) for maior que R$ 6.433,57, a base de cálculo será R$ 6.433,57 
 * (menos) os valores informados na linha 10.
 * 
 * 2. Se o prestador for odontólogo:
 * Campo numérico em valor de moeda, não editável. Se os valores informados no tópico 10 forem 
 * maiores que R$ 6.433,57 a base de cáluclo será R$ 0,00. Caso os valores informados no tópico 
 * 10 não forem maiores que R$ 6.433,57 temos que: 
 * a) Se tópico 10 + (60% x tópico 2) for menor que R$ 6.433,53 a base de cáluclo será 60% x tópico 2.
 * b) Se tópico 10 + (60% x tópico 2) for maior que R$ 6.433,57, a base de cálculo será R$ 6.433,57 
 * (menos) os valores informados no tópico 10. 
 * 
 * 3. Se o prestador não for condutor ou odontólogo:
 * Campo numérico em valor de moeda, não editável. Se os valores informados no tópico 10 forem maiores 
 * que  R$ 6.433,57 a base de cáluclo será R$ 0,00. Caso os valores informados no tópico 10 não forem 
 * maiores que R$ 6.433,57 temos que:
 * Se a soma do tópico 10 com o tópico 2 for menor que R$ 6.433,53 a base de cáluclo será o próprio valor 
 * do tópico 2
 * Se a soma do tópico 10 com o tópico 2 for maior que R$ 6.433,57, a base de cálculo será R$ 6.433,57 
 * menos os valores informados no tópico 10.  
 */
export function getBaseCalculusPrevidenciaryRetention(values, simulatorValue) {
	const baseValue = 8157.41;

	if (values.otherPaymentFontValue > baseValue) {
		return 0;
	}

	if (values.isVehicleDriverOrAuxiliar === "true") {
		if (values.otherPaymentFontValue + 0.2 * simulatorValue < baseValue) {
			return 0.2 * simulatorValue;
		} else {
			return baseValue - values.otherPaymentFontValue;
		}
	}

	if (values.isDentist === "true") {
		if (values.otherPaymentFontValue + 0.6 * simulatorValue < baseValue) {
			return 0.6 * simulatorValue;
		} else {
			return baseValue - values.otherPaymentFontValue;
		}
	}

	if (values.otherPaymentFontValue + simulatorValue < baseValue) {
		return simulatorValue;
	} else {
		return baseValue - values.otherPaymentFontValue;
	}
}

/**
 * Base de cálculo da contribuição patronal
 * 
 * 1. Se o prestador for um condutor autônomo de veículo rodoviário ou seu auxiliar: 20% x tópico 2.
 * 2. Se o prestador for um odontólogo: 60% x tópico 2.
 * 3. Se o prestador não for um condutor ou odontólogo: igual ao valor do tópico 2.
 */
export function getBaseCalculusPatonalContribution(values, simulatorValue) {
	if (values.isVehicleDriverOrAuxiliar === "true") {
		return 0.2 * simulatorValue;
	}

	if (values.isDentist === "true") {
		return 0.6 * simulatorValue;
	}

	return simulatorValue;
}

/**
 * Valor da contribuição patronal
 * 
 * Tópico 5 x tópico 12
 */
export function getPatronalContributionValue(values, simulatorValue) {
	return values.aliquotPatronalContribution * getBaseCalculusPatonalContribution(values, simulatorValue);
}

/**
 * Valor da retenção previdenciária
 * 
 * 1. Se o prestador for um condutor autônomo de veículo rodoviário ou seu auxiliar:
 * 	a) Se a alíquota for 13,5%: = subtópico 11.1 x 11% + subtópico 12.1 x 2,5%. 
 * 	b) Se a alíquota for 22,5%: = subtópico 11.1 x 20% + subtópico 12.1 x 2,5%.											
 * 2. Se o prestador for um odontólogo: subtópico 11.2 x 11% ou subtópico 11.2 x 20%.											
 * 3. Nem condutor autônomo e nem transportador autônomo: = subtópico 11.3 x 11% ou subtópico 11.3 x 20%.											
 */
export function getInssRetentionValue(values, simulatorValue) {
	const previdenciaryRetention = getBaseCalculusPrevidenciaryRetention(values, simulatorValue);
	const patronalContribution = getBaseCalculusPatonalContribution(values, simulatorValue);
	if (values.isVehicleDriverOrAuxiliar === "true") {
		if (values.aliquotRetention === 0.135) {
			return previdenciaryRetention * 0.11 + patronalContribution * 0.025;
		} else { // values.aliquotRetention === 0.225
			return (previdenciaryRetention * 0.2) + (patronalContribution * 0.025);
		}
	}

	return previdenciaryRetention * Number(values.aliquotRetention.value);
}