import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { getRealTimeZoneDate } from "~/presentation/utils/getTimeZoneData";
import ExplanatoryVideoForm from "./Form";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  explanatoryVideoUseCases,
  getCategories,
  categories,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const isCreate = !data;

  //input value and errors
  const [values, setValues] = useState({
    id: 0,
    title: "",
    publishDate: new Date(),
    text: "",
    videoUrl: "",
    explanatoryVideoCategories: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (data?.id) {
      setValues({
        ...data,
        publishDate: getRealTimeZoneDate(data.publishDate),
      });
    }
  }, [data]);

  useEffect(() => {
    if (categories.length === 0) {
      getCategories();
    }
  }, []);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} conteúdo`);
    const submitValues = {
      ...values,
      description: values.description || "",
    };
    try {
      if (isCreate) {
        await explanatoryVideoUseCases.create(submitValues);
      } else {
        await explanatoryVideoUseCases.update(submitValues);
      }
      showSuccess(`Conteúdo ${isCreate ? "criada" : "editada"}`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, `${isCreate ? "criada" : "editada"} conteúdo explicativo`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Conteúdo`}
        handleBack={onCloseForm}
      />
      <ExplanatoryVideoForm
        values={values}
        onChangeValue={handleChangeValue}
        handleSubmit={handleValidation}
        errors={errors}
        getCategories={getCategories}
        categories={categories}
      />
    </>
  );
}

export default CreateEdit;
