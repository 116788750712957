import React from "react";
import {
  AddShoppingCart,
  AppRegistration,
  Business,
  Category,
  Dashboard,
  Dvr,
  Explicit,
  LocationCity,
  PermIdentity,
  Quiz,
  RssFeed,
  InsertComment,
  Public,
  Announcement,
  FormatListBulleted,
  ListAlt,
  Note,
  PlayArrow,
  QuestionAnswer,
  Search,
  ShowChart,
  Sync,
  ViewList,
  Checklist,
  SupervisorAccountOutlined,
  VerifiedUser,
  UploadFile,
} from "@mui/icons-material";
import { adminPerms } from "../security/SecurePaths";

const menus = [
  {
    id: 0,
    name: "Dashboard",
    icon: <Dashboard />,
    link: "/admin",
  },
  {
    id: 1,
    name: "Cadastro",
    icon: <AppRegistration />,
    securePaths: [
      adminPerms.seePlans,
      adminPerms.seeCompanyCategory,
      adminPerms.seeCompanies,
      adminPerms.seeCities,
      adminPerms.seeValueVariables,
      adminPerms.seeSurvey,
    ],
    subItems: [
      {
        name: "Plano",
        link: "/admin/plano",
        icon: <AddShoppingCart />,
        securePaths: [adminPerms.seePlans],
      },
      {
        name: "Categoria Empresa",
        link: "/admin/empresa/categoria",
        icon: <Category />,
        securePaths: [adminPerms.seeCompanyCategory],
      },
      {
        name: "Empresa",
        link: "/admin/empresa",
        icon: <Business />,
        securePaths: [adminPerms.seeCompanies],
      },
      {
        name: "Município",
        link: "/admin/consulta-iss/atualizar-municipios",
        icon: <LocationCity />,
        securePaths: [adminPerms.seeCities],
      },
      {
        name: "Variável de Valor",
        link: "/admin/variaveis-de-valor",
        icon: <Explicit />,
        securePaths: [adminPerms.seeValueVariables],
      },
      {
        name: "Enquete",
        link: "/admin/enquetes",
        icon: <Quiz />,
        securePaths: [adminPerms.seeSurvey],
      },
    ],
  },
  {
    id: 2,
    name: "Segurança",
    icon: <VerifiedUser />,
    securePaths: [adminPerms.seeUsers, adminPerms.seeUserProfile],
    subItems: [
      {
        name: "Usuário",
        link: "/admin/usuario",
        icon: <PermIdentity />,
        securePaths: [adminPerms.seeUsers],
      },
      {
        name: "Perfis",
        link: "/admin/perfis-de-usuário",
        icon: <SupervisorAccountOutlined />,
        securePaths: [adminPerms.seeUserProfile],
      },
      {
        name: "Permissões de Segurança",
        link: "/admin/permissoes-de-seguranca",
        icon: <Checklist />,
      },
    ],
  },
  {
    id: 3,
    name: "Conteúdo do site",
    icon: <Dvr />,
    securePaths: [
      adminPerms.seeBlogCategory,
      adminPerms.seeBlog,
      adminPerms.seeComments,
      adminPerms.seeContent,
      adminPerms.seeVideo,
      adminPerms.seeVideoCategory,
    ],
    subItems: [
      {
        name: "Categoria Blog",
        link: "/admin/blog/categoria",
        icon: <Category />,
        securePaths: [adminPerms.seeBlogCategory],
      },
      {
        name: "Blog",
        link: "/admin/blog",
        icon: <RssFeed />,
        securePaths: [adminPerms.seeBlog],
      },
      {
        name: "Comentário",
        link: "/admin/comentarios",
        icon: <InsertComment />,
        securePaths: [adminPerms.seeComments],
      },
      {
        name: "Conteúdo Selecionado",
        link: "/admin/conteudo",
        icon: <Public />,
        securePaths: [adminPerms.seeContent],
      },
      {
        name: "Conteúdo Explicativo",
        link: "/admin/ajuda",
        icon: <PlayArrow />,
        securePaths: [adminPerms.seeVideo],
      },
      {
        name: "Categorias C. Explicativo",
        link: "/admin/ajuda/categoria",
        icon: <Category />,
        securePaths: [adminPerms.seeVideoCategory],
      },
    ],
  },
  {
    id: 4,
    name: "GT-Fácil",
    icon: <Sync />,
    securePaths: [
      adminPerms.seeCnaes,
      adminPerms.seeServiceCodes,
      adminPerms.seeAnnotations,
      adminPerms.seeSearchTerms,
      adminPerms.seeAnswerItems,
      adminPerms.seeAnswerTypes,
      adminPerms.seeAnswers,
      adminPerms.seeIssAnswers,
      adminPerms.seeAnswerImport,
    ],
    subItems: [
      {
        name: "CNAE",
        link: "/admin/cnaes",
        icon: <ListAlt />,
        securePaths: [adminPerms.seeCnaes],
      },
      {
        name: "Código de Serviço",
        link: "/admin/servicos",
        icon: <ViewList />,
        securePaths: [adminPerms.seeServiceCodes],
      },
      {
        name: "Anotações",
        link: "/admin/anotacoes",
        icon: <Note />,
        securePaths: [adminPerms.seeAnnotations],
      },
      {
        name: "Termo de Pesquisa",
        link: "/admin/termos-de-pesquisa",
        icon: <Search />,
        securePaths: [adminPerms.seeSearchTerms],
      },
      {
        name: "Item de Resposta",
        link: "/admin/itens-de-resposta",
        icon: <FormatListBulleted />,
        securePaths: [adminPerms.seeAnswerItems],
      },
      {
        name: "Tipo de Resposta",
        link: "/admin/tipos-de-resposta",
        icon: <Category />,
        securePaths: [adminPerms.seeAnswerTypes],
      },
      {
        name: "Resposta",
        link: "/admin/respostas",
        icon: <QuestionAnswer />,
        securePaths: [adminPerms.seeAnswers],
      },
      {
        name: "Resposta do ISS",
        link: "/admin/respostas/respostas-iss",
        icon: <QuestionAnswer />,
        securePaths: [adminPerms.seeIssAnswers],
      },
      {
        name: "Importar Respostas",
        link: "/admin/gt-facil/importar/respostas",
        icon: <UploadFile />,
        securePaths: [adminPerms.seeAnswerImport],
      },
    ],
  },
  {
    id: 5,
    name: "Informação Fiscal",
    icon: <Announcement />,
    link: "/admin/informacoes-fiscais",
    securePaths: [adminPerms.seeFiscalInformation],
  },
  {
    id: 6,
    name: "Estatística",
    icon: <ShowChart />,

    subItems: [
      {
        name: "Informação Estatística",
        link: "/admin/estatistica",
        icon: <PermIdentity />,
      },
      {
        name: "Visualizações",
        link: "/admin/estatistica/contagem-de-visualizacoes",
        icon: <PermIdentity />,
      },
    ],
  },
];
export default menus;
