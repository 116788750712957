import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";

const AlertDialog = ({
  message = "Deseja exibir uma mensagem?",
  handleConfirm,
  handleDeny,
  confirmText = "Sim",
  denyText = "Não",
}) => (
  <>
    <DialogContent sx={styles.content}>
      <Typography sx={styles.content.message}>{message}</Typography>
    </DialogContent>
    <DialogActions sx={styles.actions}>
      <Button variant="outlined" onClick={handleConfirm} autoFocus>
        {confirmText}
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleDeny}>
        {denyText}
      </Button>
    </DialogActions>
  </>
);

const DialogBox = ({ config, setDialogBox, onClose }) => {
  const {
    customComponent: CustomComponent = false,
    open = false,
    callback = () => {},
    negativeCallback = () => {},
    message,
    confirmText,
    denyText,
    fullScreen,
    blockScreen = false, //block screen é para não deixar sair do modal até responder, como no Re-login quando o token expira
    maxWidth = false, // 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    fullWidth = false,
  } = config;

  const handleConfirm = () => {
    callback();
    onClose();
  };

  const handleDeny = () => {
    negativeCallback();
    onClose();
  };

  const render = () => {
    if (CustomComponent) {
      return <CustomComponent handleDeny={handleDeny} />;
    }
    if (message) {
      return (
        <AlertDialog
          handleConfirm={handleConfirm}
          handleDeny={handleDeny}
          confirmText={confirmText}
          denyText={denyText}
          message={message}
        />
      );
    }
    return null;
  };

  return (
    <Dialog
      sx={styles.container}
      open={open}
      onClose={blockScreen ? null : handleDeny}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {render()}
    </Dialog>
  );
};

export default DialogBox;

const styles = {
  container: {
    "& .MuiDialog-paper": {
      borderRadius: "12px",
    },
  },
  content: {
    textAlign: "center",
    message: {
      color: "#383f48",
      fontFamily: "Montserrat",
    },
  },
  actions: {
    justifyContent: "center",
    mb: 1,
  },
};
