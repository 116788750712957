import React, { useContext, useEffect, useState } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Stack,
  Box,
  Checkbox,
  Typography as Text,
  Tooltip,
} from "@mui/material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { formatDateToBrazilian } from "../../Utils/utilsDate";
import SearchInput from "../../components/Search/SearchInput";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { tableStyles } from "../../components/Styles/TableStyles";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import { EventDetailsModal } from "../components/EventSend/EventDetailsModal";
import RetificationPayment from "./Retification/RetificationPayments";
import { ApiPaymentRecordUseCases } from "~/data/usecases/paymentRecord/apiPaymentRecord";
import { EventSend } from "../components/EventSend/EventSend";
import { useTableFilters } from "../../Hooks/useTableFilters";

function SentTablePayments({
  certificateData,
  sendFilter,
  refreshData,
  eventsDataSend,
  validPaymentsData,
  isEventClosed,
}) {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const apiPayment = new ApiPaymentRecordUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { handleSearchTable } = useTableFilters();
  const [eventsData, setEventsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [sendProtocols, setSendProtocols] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [protocolSelected, setProtocolSelected] = useState([]);
  const [docDataWithEventSent, setDocDataWithEventSent] = useState([]);
  const selectedEvent = sendFilter?.event || "r4010";
  // CONSULT MODAL
  const [openConsultDetails, setOpenConsultDetails] = useState(false);
  const [consultDetails, setConsultDetails] = useState([]);

  useEffect(() => {
    setEventsData(eventsDataSend);
    eventsDataSend.forEach(async (event, index) => {
      handleOpenCollapse(index, true);
      await fetchPayments(event.protocol);
    });
    handleSelectAllClick({ target: { checked: false } });
  }, [eventsDataSend]);

  // OPEN SEND MODAL
  const [openEventSendModal, setOpenEventSendModal] = useState(false);

  // ⇣⇣⇣ abrir e fechar modal de envio de documentos
  const handleOpen = () => setOpenEventSendModal(true);
  const handleClose = () => setOpenEventSendModal(false);

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState(null);

  //Open/Close Collapse
  const handleOpenCollapse = (index, isOpen) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: !isOpen,
    }));
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchTable(value, eventsDataSend, setEventsData);
  };

  useEffect(() => {
    setDocDataWithEventSent(
      validPaymentsData.filter(
        (doc) => !!eventsDataSend.some((event) => event.tag === doc.businessPartnerCNPJ)
      )
    );
  }, [eventsDataSend, validPaymentsData]);

  const fetchPayments = async (protocol) => {
    try {
      const response = await apiPayment.getPaymentByProtocol(protocol);

      return response || []; // Retorna os dados ou um array vazio como fallback
    } catch (error) {
      console.error("Erro ao buscar pagamentos para o protocolo:", protocol, error);
      return []; // Retorna um array vazio em caso de erro
    }
  };

  const dataAbledToCheck = eventsData?.filter((item) => item.receiptNumber);
  const numSelected = selectedRows.length;
  const rowCount = dataAbledToCheck.length;

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleSort = (field) => {
    if (field) {
      const isAsc = orderBy === field && orderDirection === "asc";
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(field);
      const sortedData = [...eventsData].sort((a, b) => {
        if (a[field] < b[field]) {
          return orderDirection === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return orderDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      setEventsData(sortedData);
    }
  };
  const anyStatus1 = eventsData?.filter((item) => item.codeStatus === 1);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(dataAbledToCheck);
    } else {
      setSelectedRows([]);
    }
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((selectedItem) => selectedItem.protocol !== item.protocol)
      );
    }
  };

  const isSelected = (item) => selectedRows.some((i) => i.protocol === item.protocol);
  //retificação automatica, junta nota do mesmo par

  const handleAutomatedRectification = async () => {
    const promises = eventsData.map(async (item) => {
      const paymentsData = await fetchPayments(item.protocol);
      return { ...item, paymentsData };
    });

    try {
      openBackdrop(true, "Adicionando documentos fiscais que não foram enviados");
      setSelectedRows([]);
      const response = await Promise.all(promises);
      eventsDataSend.forEach((event, index) => {
        handleOpenCollapse(index, true);
      });
      const eventsCollapseStatus = [];
      const updatedEvents = response.map((item, index) => {
        const paymentsData = validPaymentsData.filter(
          (doc) => doc.businessPartnerCNPJ === item.tag
        );
        if (paymentsData.length > 0) {
          eventsCollapseStatus.push({ index: index, isOpen: false });
          return {
            ...item,
            paymentsData: [...item.paymentsData, ...paymentsData],
          };
        }
        eventsCollapseStatus.push({ index: index, isOpen: true });
        return item;
      });

      setEventsData(updatedEvents);
      eventsCollapseStatus.forEach((item) => {
        if (!item.isOpen) {
          setSelectedRows((prev) => [...prev, updatedEvents[item.index]]);
        }
        handleOpenCollapse(item.index, item.isOpen);
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };
  const handleSubmit = async () => {
    const updatedRows = await Promise.all(
      selectedRows.map(async (item) => {
        if (!item.paymentsData) {
          const paymentsData = await fetchPayments(item.protocol);
          return { ...item, paymentsData };
        }
        return item;
      })
    );

    setSelectedRows(updatedRows);

    handleOpen();
  };

  const handleProtocolConsult = async () => {
    const promises = anyStatus1?.map(async (event) => {
      if (event.receiptNumber) return event;

      // Define o isProduction com base no primeiro dígito de event.protocol
      const isProduction = event.protocol.startsWith("1");

      const params = {
        DigitalCertId: sendFilter.certificate,
        ProtocolNumber: event.protocol,
        IsProduction: isProduction,
      };
      const result = await apiReinf.getReinfConsult(params);
      return result;
    });

    try {
      openBackdrop(true, "Consultando protocolos");
      const response = await Promise.all(promises);
      if (response.length > 0) {
        setConsultDetails(response);
        setOpenConsultDetails(true);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data || error?.response,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const getColumns = () => {
    return [
      { field: "protocol", headerName: "Protocolo" },
      { field: "receiptNumber", headerName: "Nº Recibo" },
      { field: "sendingDate", headerName: "Data do envio" },
      { field: "userSent", headerName: "Email do usuário" },
      { headerName: "Evento", sortable: false },
      { headerName: "Situação", sortable: false },
    ];
  };

  const headStyle = { ...tableStyles.headCell, padding: "12px" };

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = eventsData?.length > 0 && eventsData.slice(startIndex, endIndex);

  const renderStatus = (status, message) => {
    if (status === 2) {
      return (
        <Tooltip title={message}>
          <IconButton>
            <CheckCircleOutlineOutlinedIcon color="success" />
          </IconButton>
        </Tooltip>
      );
    }

    if (status > 2) {
      return (
        <Tooltip title={message}>
          <IconButton>
            <ErrorOutlineOutlinedIcon color="error" />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={message || "Aguardando consulta..."}>
        <IconButton>
          <PendingOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      <Box sx={{ paddingInline: 3 }}>
        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.buttonsContainer.buttons}>
            <RButton
              // disabled={eventsData?.length === 0 || docDataWithEventSent.length === 0}
              sx={styles.Button}
              onClick={handleAutomatedRectification}
            >
              Adicionar Documentos
            </RButton>
            {anyStatus1.length > 0 && (
              <Tooltip
                title="Consulta a situação dos protocolos enviados que ainda não possuem confirmação / Recibo."
                placement="right-start"
              >
                <RButton sx={styles.Button} onClick={handleProtocolConsult}>
                  Consultar protocolos
                </RButton>
              </Tooltip>
            )}
          </Box>
          <SearchInput value={searchTerm} onChange={handleSearch} />
        </Box>
      </Box>
      <Box sx={{ ...tableStyles.container, margin: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headStyle}></TableCell>
              <TableCell sx={{ ...headStyle, padding: 0 }}>
                <Checkbox
                  color="default"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {getColumns().map((col, i) => (
                <TableCell
                  sx={{
                    ...headStyle,
                    cursor: col.sortable === false ? "auto" : "pointer",
                  }}
                  key={i}
                  onClick={() => {
                    if (col.sortable === false) return; //precisa ser === false
                    handleSort(col.field);
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {col.headerName}
                    {orderBy === col.field ? (
                      orderDirection === "asc" ? (
                        <ArrowUpward fontSize="small" />
                      ) : (
                        <ArrowDownward fontSize="small" />
                      )
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.length > 0 ? (
              currentData.map((item, index) => {
                const isOpen = openCollapse[index] || false; // Verifique se este `Collapse` está aberto
                const isRowSelected = isSelected(item);
                const hasReceiptNumber = item?.receiptNumber;

                return (
                  <React.Fragment key={index}>
                    <TableRow sx={{ cursor: "pointer" }}>
                      <TableCell sx={tableStyles.cell}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            if (!isRowSelected && !isOpen)
                              handleCheckboxChange({ target: { checked: true } }, item);
                            handleOpenCollapse(index, isOpen);
                          }}
                        >
                          {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ ...tableStyles.cell, padding: 0 }}>
                        <Checkbox
                          color="default"
                          onChange={(event) => handleCheckboxChange(event, item)}
                          checked={isRowSelected}
                          disabled={!hasReceiptNumber}
                        />
                      </TableCell>
                      <TableCell sx={tableStyles.cell}>{item.protocol}</TableCell>
                      <TableCell sx={tableStyles.cell}>{item.receiptNumber}</TableCell>
                      <TableCell sx={tableStyles.cell}>
                        {formatDateToBrazilian(item.sendingDate)}
                      </TableCell>
                      <TableCell sx={tableStyles.cell}>{item.userSent}</TableCell>
                      <TableCell sx={tableStyles.cell}>{`R-${item?.reinfEvent.slice(
                        1,
                        5
                      )}`}</TableCell>
                      <TableCell sx={tableStyles.cell}>
                        {renderStatus(item.codeStatus, item.status)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ ...tableStyles.cellCollapse, padding: "2px " }}
                        colSpan="100%"
                      >
                        {isOpen && (
                          <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <RetificationPayment
                              // data={protocolSelected}
                              // setData={setProtocolSelected}
                              // reloadPayData={fetchPayments}
                              protocol={item.protocol}
                              filter={sendFilter}
                              validNfseData={validPaymentsData}
                              status={item.codeStatus}
                              certificateData={certificateData}
                              receiptNumber={item.receiptNumber}
                              refreshData={refreshData}
                              isEventClosed={isEventClosed}
                              automatedData={eventsData[index]?.nfseData || null}
                              selecetedRowsData={setSelectedRows}
                              setEventsData={setEventsData}
                            />
                          </Collapse>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell sx={tableStyles.cellError} colSpan="100%">
                  <p style={{ textAlign: "center" }}>Não existe eventos no período escolhido.</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {eventsData?.length > 9 && (
          <Stack spacing={1} sx={tableStyles.pagination}>
            <TablePagination
              component="div"
              count={eventsData?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Informações por página:"
              rowsPerPageOptions={[10, 15, 20]}
            />
          </Stack>
        )}
      </Box>
      <Box sx={{ paddingRight: 3, display: "flex", justifyContent: "right" }}>
        <RButton
          sx={{ width: "200px" }}
          onClick={handleSubmit}
          disabled={selectedRows?.length === 0}
        >
          Enviar
        </RButton>
      </Box>
      <ModalContainer open={openEventSendModal} onClose={handleClose}>
        <EventSend
          data={selectedRows}
          filter={{
            ...sendFilter,
            event: selectedEvent === "r4010" ? `automated-${selectedEvent}` : selectedEvent,
          }}
          certificateData={certificateData}
          closeModal={handleClose}
          refreshData={refreshData}
        />
      </ModalContainer>
      <ModalContainer open={openConsultDetails} onClose={() => setOpenConsultDetails(false)}>
        <EventDetailsModal
          onClose={() => setOpenConsultDetails(false)}
          eventDetails={consultDetails}
        />
      </ModalContainer>
    </>
  );
}

export default SentTablePayments;
const styles = {
  Button: {
    width: "min(300px, 100%)",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 600,
    color: "#404040",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    "@media(min-width: 900px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    buttons: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 1,
      "@media(min-width: 600px)": {
        flexDirection: "row",
      },
      "@media(min-width: 900px)": {
        justifyContent: "start",
      },
    },
  },
};
