import React from "react";
import { SearchForm } from "~/presentation/views/Blog/components/SearchForm";
import { useNavigate } from "react-router";
import { Box, Container, Fade, Typography } from "@mui/material";

export const SectionTitlePost = ({
  title,
  marginToHeader,
  redirectURL,
  image,
  onSearch,
  searchButtonBg,
  searchPlaceholder,
  blur,
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.container(image, marginToHeader)}>
      <Box sx={styles.blur(blur)} />
      <Container maxWidth="lg" sx={{ zIndex: 0 }}>
        <Box sx={styles.containerContent}>
          <Fade in={true} timeout={1000}>
            <Typography sx={styles.title} onClick={() => navigate(redirectURL)}>
              {title}
            </Typography>
          </Fade>
          {onSearch && (
            <SearchForm
              title={""}
              searchButtonBg={searchButtonBg} // default -> primary (blue) // secondary (orange)
              componentRef={null}
              initialValue={""}
              onSearch={(value) => onSearch(value, true)}
              placeholder={searchPlaceholder}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

const styles = {
  container: (image, marginToHeader) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "10.4rem",
    padding: "0", // o último padding sobressai
    backgroundColor: "#081445",
    background: `url(${image})`,
    backgroundPosition: "50% 35%",
    backgroundSize: "cover",
    ...(!!marginToHeader && { marginTop: "4.375rem" }), // aplica o margin-top condicionalmente
  }),

  blur: (blur = 0.8) => ({
    height: "100%",
    width: "100%",
    backgroundColor: "#021148",
    opacity: blur,
    position: "absolute",
    top: 0,
    left: 0,
  }),

  containerContent: {
    display: "flex",
    margin: "0 auto",
    gap: "20px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 0,
      width: "100%",
    },
  },
  title: {
    color: "#fff",
    cursor: "pointer",
    textAlign: "left",
    fontFamily: "Montserrat",
    fontSize: "2.2rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "119.4%",
    "@media (min-width: 768px)": {
      fontSize: "2.75rem",
    },
    "@media (min-width: 1024px)": {
      fontSize: "3.25rem",
    },
  },
};
