import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiItemAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeAnswerItems])) {
        return new Error(securityErrorMessage("visualizar item de resposta."));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getById(id) {
    if (!hasPermission([adminPerms.seeAnswerItems])) {
      return new Error(securityErrorMessage("visualizar item de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async create(data) {
    if (!hasPermission([adminPerms.addAnswerItems])) {
      return new Error(securityErrorMessage("adicionar item de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async update(data) {
    if (!hasPermission([adminPerms.editAnswerItems])) {
      return new Error(securityErrorMessage("editar item de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer`,
        method: "PUT",
        data,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async delete(id) {
    if (!hasPermission([adminPerms.deleteAnswerItems])) {
      return new Error(securityErrorMessage("excluir item de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
