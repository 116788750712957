import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiExplanatoryVideoUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async create(data) {
    if (!hasPermission([adminPerms.addVideo])) {
      throw new Error(securityErrorMessage("adicionar conteúdo explicativo"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideo`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async get() {
    if (!hasPermission([adminPerms.seeVideo])) {
      throw new Error(securityErrorMessage("visualizar conteúdo explicativo"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideo`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getById(id) {
    if (!hasPermission([adminPerms.seeVideo])) {
      throw new Error(securityErrorMessage("visualizar conteúdo explicativo"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideo/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async update(data) {
    if (!hasPermission([adminPerms.editVideo])) {
      throw new Error(securityErrorMessage("editar conteúdo explicativo"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideo/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async delete(id) {
    if (!hasPermission([adminPerms.deleteVideo])) {
      throw new Error(securityErrorMessage("excluir conteúdo explicativo"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideo/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
