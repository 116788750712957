import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { reinfPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiReinfUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async sendEvent(data, event) {
    if (!hasPermission([`reinf\\envio\\${event}`])) {
      throw new Error(securityErrorMessage(`enviar evento ${event}`));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/${event}`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      console.error("o erro no descrito é:", error.response.data);
      throw error;
    }
  }

  async getReinfConsult(data) {
    try {
      const queryParams = new URLSearchParams(data).toString();

      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/Consult?${queryParams}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getReinfProtocol(data) {
    if (!hasPermission([reinfPerms.checkProtocol])) {
      throw new Error(securityErrorMessage("consultar protocolo"));
    }

    try {
      const url = data
        ? `${apiURL}/ReinfProtocol?${new URLSearchParams(data).toString()}`
        : `${apiURL}/ReinfProtocol`;
      const response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getReinfProtocolById(id) {
    if (!hasPermission([reinfPerms.checkProtocol])) {
      throw new Error(securityErrorMessage("consultar protocolo"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ReinfProtocol/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteReinfProtocol(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ReinfProtocol/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async verifyClosedPeriod(data, event) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/Verifyclosed/${event}?companyId=${data.companyId}&calculationDate=${data.calculationDate}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getProtocolHistory(date) {
    const params = new URLSearchParams(date).toString();
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ReinfProtocol/StatusHistory?${params}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async sendRetification(data) {
    if (!hasPermission([reinfPerms.sendRectification])) {
      throw new Error(securityErrorMessage("enviar evento de retificação"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/Rectification`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async generateReport(report, reportRequestParams) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Report/Reinf/${report}`,
        method: "POST",
        params: { ...reportRequestParams },
        responseType: "blob",
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      return window.URL.createObjectURL(blob);
    } catch (error) {
      throw new Error(error);
    }
  }
}
