import React from "react";

const TextIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.1113 60.4445H23.1113C22.6398 60.4445 22.1876 60.2572 21.8542 59.9238C21.5208 59.5904 21.3335 59.1382 21.3335 58.6667V19.5556C21.3335 19.0841 21.5208 18.6319 21.8542 18.2985C22.1876 17.9651 22.6398 17.7778 23.1113 17.7778H55.1113C55.5828 17.7778 56.035 17.9651 56.3684 18.2985C56.7018 18.6319 56.8891 19.0841 56.8891 19.5556V58.6667C56.8891 59.1382 56.7018 59.5904 56.3684 59.9238C56.035 60.2572 55.5828 60.4445 55.1113 60.4445ZM24.8891 56.8889H53.3335V21.3334H24.8891V56.8889Z"
        fill="white"
      />
      <path d="M28.4448 28.4445H49.7782V32H28.4448V28.4445Z" fill="white" />
      <path
        d="M28.4448 35.5555H49.7782V39.1111H28.4448V35.5555Z"
        fill="white"
      />
      <path
        d="M28.4448 42.6666H49.7782V46.2222H28.4448V42.6666Z"
        fill="white"
      />
      <path
        d="M10.6669 42.6667V7.1111H42.6669V5.33332C42.6669 4.86182 42.4796 4.40964 42.1462 4.07624C41.8128 3.74284 41.3606 3.55554 40.8891 3.55554H8.88911C8.41761 3.55554 7.96543 3.74284 7.63203 4.07624C7.29863 4.40964 7.11133 4.86182 7.11133 5.33332V44.4444C7.11133 44.9159 7.29863 45.3681 7.63203 45.7015C7.96543 46.0349 8.41761 46.2222 8.88911 46.2222H10.6669V42.6667Z"
        fill="white"
      />
      <path
        d="M17.7782 49.7777V14.2222H49.7782V12.4444C49.7782 11.9729 49.5909 11.5207 49.2575 11.1873C48.9241 10.8539 48.4719 10.6666 48.0004 10.6666H16.0004C15.5289 10.6666 15.0768 10.8539 14.7434 11.1873C14.41 11.5207 14.2227 11.9729 14.2227 12.4444V51.5555C14.2227 52.027 14.41 52.4792 14.7434 52.8126C15.0768 53.146 15.5289 53.3333 16.0004 53.3333H17.7782V49.7777Z"
        fill="white"
      />
    </svg>
  );
};

export default TextIcon;
