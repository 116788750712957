import React, { useState } from "react";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";

const CopyItems = ({ onClose, allIssAnswers, onCopy, currentId }) => {
  const [city, setCity] = useState(null);

  const data =
    allIssAnswers.map((item) => {
      return {
        label: item.cityName,
        ...item,
      };
    }) || null;

  const filteredData = data ? data.filter((item) => item.id !== currentId) : [];

  const handleChooseCity = (city) => {
    setCity(city);
    if (city[0]) onCopy(city[0]);
  };

  return (
    <ListComponent
      title="Escolha a cidade"
      data={filteredData}
      handleAdd={handleChooseCity}
      checkedData={city}
      onClose={onClose}
      blockCheckNumber={1}
    />
  );
};

export default CopyItems;
