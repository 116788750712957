import React from "react";
import { ContentCopy, PostAdd, Undo } from "@mui/icons-material";
import { IconButton, Typography, Tooltip, Box } from "@mui/material";
import { useSize } from "~/presentation/hooks/useSize";
import { SecurityPath } from "~/presentation/security/SecurityPath";

const PageListHeader = ({
  title,
  handleAdd,
  titleAdd,
  handleBack,
  titleBack,
  fontSize,
  error,
  addSecurePath = [],
  handleCopyModal = null,
}) => {
  const { isMobile } = useSize();
  const size = Number(fontSize) || 28;

  const renderIcon = () => {
    const icon = (
      <Box sx={styles.addIcon(false)}>
        <Tooltip title={titleAdd || `Criar ${title}`}>
          <IconButton onClick={handleAdd}>
            <PostAdd sx={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Box>
    );
    if (addSecurePath?.length > 0) {
      return <SecurityPath securePaths={addSecurePath}>{icon}</SecurityPath>;
    }
    return icon;
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleAndButtonContainer}>
        <Box>
          <Typography
            sx={{
              ...styles.title,
              fontSize: isMobile ? size - 6 : size,
              color: error && "#d32f2f",
            }}
          >
            {title}
          </Typography>
        </Box>
        {handleAdd ? renderIcon() : null}
        {handleCopyModal ? (
          <Box sx={styles.addIcon(handleCopyModal.disabled)}>
            <Tooltip title={handleCopyModal?.title}>
              <IconButton onClick={handleCopyModal?.command} disabled={handleCopyModal.disabled}>
                <ContentCopy sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
      {handleBack && (
        <Tooltip title={titleBack || "Voltar"}>
          <IconButton onClick={handleBack}>
            <Undo sx={{ color: "#000" }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default PageListHeader;

const styles = {
  container: {
    background: "#E6E6E6",
    padding: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleAndButtonContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  addIcon: (disabled) => ({
    backgroundColor: disabled ? "gray" : "#001A50",
    padding: "2px",
    borderRadius: "12px",
    transition: "scale 300ms",
    "&:hover": { scale: !disabled && 1.05 },
  }),
  title: {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "left",
    fontWeight: 600,
    color: "#242424",
  },
};
