import React, { useContext, useEffect, useState } from "react";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";

import { MoneyInput } from "~/presentation/components/MoneyInput";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import generateSimulatorReport from "~/reports/simulator-report";

import {
  InssCooperative,
  IrrfCooperative,
  CsllPisPasepCofinsCooperative,
  IssCooperative,
} from "~/presentation/views/GTFacil/Simulator/forms/Cooperative";
import { InssMei, IrPisPasepCofinsIssMei } from "~/presentation/views/GTFacil/Simulator/forms/Mei";
import {
  CsllPisPasepCofinsPJNotSimples,
  InssPJNotSimples,
  IrpjCsllPisPasepCofinsPJNotSimples,
  IrrfPJNotSimples,
  IssPJNotSimples,
} from "~/presentation/views/GTFacil/Simulator/forms/PJNotSimples";
import {
  InssPJSimples,
  IssPJSimples,
} from "~/presentation/views/GTFacil/Simulator/forms/PJSimples";
import { ErrorMessage } from "~/presentation/components/ErrorMessage";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { InssIndividual, IrrfIndividual, IssIndividual } from "./forms/Individual";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { Box, Typography } from "@mui/material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { InfoIcon } from "~/presentation/views/GTFacil/Search/components/SearchForm/styles";
import { GenerateSimulatorReportDialog } from "~/presentation/views/GTFacil/Simulator/components/GenerateSimulatorReportDialog";
import { Tabs } from "./components/Tabs";
import Grid from "@mui/material/Grid2";

const TabCollection = () => {
  const tabs = [];
  const titles = {
    inss: "INSS",
    irpj: "IRPJ",
    irpjCsllPisPasepCofins: "IRPJ, CSLL, PIS/Pasep e COFINS",
    irrf: "IRRF",
    csllPisCofins: "CSLL, PIS/Pasep e COFINS",
    iss: "ISS",
    irPisPasepCofinsIss: "IRPJ, CSLL, PIS/Pasep, Cofins e ISS",
  };

  const addTab = (name, panel) => {
    tabs.push({
      title: titles[name],
      panel,
    });
  };

  return {
    addTab,
    tabs,
  };
};

const HiredTypes = {
  PJ: 0,
  MEI: 1,
  INDIVIDUAL: 2,
  COOPERATIVE: 3,
};

const getTabs = (hiredType, contractorCategory, isSimples, companyCategories) => {
  const { addTab, tabs } = TabCollection();
  const irpjCsllPisPasepCofins = companyCategories?.filter((item) => item.id <= 5);
  const irpjWithoutCsll = companyCategories?.filter((item) => item.id >= 6 && item.id <= 11);
  const irpjCsllSeparated = companyCategories?.filter((item) => item.id >= 12 && item.id <= 18);

  const retentionAliquotOptionsIrpjSeparated = () => {
    const options = [
      {
        value: 0.01,
        code: 1708,
        label:
          "Serviços do art. 716 do RIR/2018 - Limpeza, conservação, vigilância, segurança ou locação de mão de obra.",
      },
      {
        value: 0.015,
        code: 1708,
        label: "Serviços profissionais do art. 714 do RIR/2018",
      },
    ];

    return options.map((r) => {
      r.label = `${(r.value * 100).toFixed(2)}% - ${r.code} - ${r.label}`;
      return r;
    });
  };

  const retentionAliquotOptionsIrpjWithoutCsll = () => {
    const options = [
      {
        value: 0.012,
        label:
          "Mercadorias e bens em geral; de transporte de cargas, hospitalares e de auxílio e diagnóstico.",
      },
      {
        value: 0.0024,
        label: "Derivados do petróleo, álcool etílico hidratado e biodisesel",
      },
      {
        value: 0.024,
        label: "Passagens aéreas, rodoviárias e demais serviços de transporte de passageiros",
      },
      {
        value: 0.015,
        label: "Serviços prestados por associações profissionais ou assemelhadas e cooperativas",
      },
      {
        value: 0.024,
        label:
          "Serviços prestados por bancos, sociedades de crédito, financiamento e investimento, distribuidoras de títulos e valores mobiliários e seguro saúde",
      },
      {
        value: 0.048,
        label: "Demais serviços",
      },
    ];
    return options.map((r) => {
      r.label = `${(r.value * 100).toFixed(2)}% - ${r.label}`;
      return r;
    });
  };

  if (isSimples != undefined) {
    if (hiredType.value === HiredTypes.PJ && !isSimples) {
      addTab("inss", InssPJNotSimples);
      if (irpjCsllPisPasepCofins.find((item) => item.id === contractorCategory.id)) {
        addTab("irpjCsllPisPasepCofins", IrpjCsllPisPasepCofinsPJNotSimples);
      } else if (irpjWithoutCsll.find((item) => item.id === contractorCategory.id)) {
        addTab("irpj", () => (
          <IrrfPJNotSimples aliquots={retentionAliquotOptionsIrpjWithoutCsll} />
        ));
      } else {
        irpjCsllSeparated.find((item) => item.id === contractorCategory.id);
        addTab("irpj", () => <IrrfPJNotSimples aliquots={retentionAliquotOptionsIrpjSeparated} />);
        addTab("csllPisCofins", () => (
          <CsllPisPasepCofinsPJNotSimples contractorCategory={contractorCategory} />
        ));
      }
      addTab("iss", IssPJNotSimples);
      return tabs;
    } else if (hiredType.value === HiredTypes.PJ && isSimples) {
      addTab("inss", InssPJSimples);
      addTab("iss", IssPJSimples);
      return tabs;
    } else if (hiredType.value === HiredTypes.MEI) {
      addTab("inss", InssMei);
      addTab("irPisPasepCofinsIss", IrPisPasepCofinsIssMei);
      return tabs;
    } else if (hiredType.value === HiredTypes.INDIVIDUAL) {
      addTab("inss", InssIndividual);
      addTab("irrf", IrrfIndividual);
      addTab("iss", IssIndividual);
      return tabs;
    } else if (hiredType.value === HiredTypes.COOPERATIVE) {
      addTab("inss", InssCooperative);
      addTab("irrf", IrrfCooperative); // adicionar condicional para contractorCategory 1 a 5 ou 6 a 8?
      addTab("csllPisCofins", () => (
        <CsllPisPasepCofinsCooperative contractorCategory={contractorCategory} />
      ));
      addTab("iss", IssCooperative);
      return tabs;
    }
  }
};

export const Simulator = ({
  simulatorRef,
  cnae,
  hiredType,
  contractorCategory,
  isSimples,
  companyType,
  answer,
  handleClose,
}) => {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [simulatorValue, setSimulatorValue] = useState("");
  const [companyCategories, setCompanyCategories] = useState([]);
  const [inssIndividualValue, setInssIndividualValue] = useState(0);
  const [invalidSimulatorValue, setInvalidSimulatorValue] = useState(null);

  const [cacheReportValues, setCacheReportValues] = useState([]);
  const [openGenerateReportDialog, setOpenGenerateReportDialog] = useState(false);

  useEffect(() => {
    let abort = false;
    const fetchCategories = async () => {
      const companyUseCases = makeCompanyUseCases();
      const categories = await companyUseCases.getCompanyCategories({ checkPermission: false });
      if (abort) return;
      setCompanyCategories(categories);
    };
    fetchCategories();
    return () => {
      abort = true;
    };
  }, []);

  const tabsData = Object.values(
    getTabs(hiredType, contractorCategory, isSimples, companyCategories)
  );

  const handleCacheReport = async (data) => {
    const retrieveExistentItems = data.items.filter((item) => !!item.value);

    let subItem = "";

    if (cnae.search && cnae.observation) {
      const observation = cnae.observation;

      subItem += cnae.search === "cnae" ? "Relacionado ao subitem: " : "Relacionado ao CNAE: ";
      subItem += observation.substring(0, observation.indexOf("-"));
    }

    let cnaeText = `${cnae.code} - ${cnae.description}`;
    if (cnae.detailCode) {
      cnaeText = `${cnae.code} (${cnae.detailCode}) - ${cnae.description}`;
    }

    cnaeText = cnaeText.endsWith(".") ? cnaeText : cnaeText + ".";

    const report = {
      number: answer != null && answer.length > 0 ? answer[0].number : 0,
      serviceTypeDescription: `${cnaeText} ${subItem}`,
      observation: cnae.observation,
      natureOfContractor: contractorCategory.name,
      contractTypeDescription: hiredType.label,
      nationalSimple: !isSimples ? "Não" : "Sim",
      tax: data.tax,
      title: data.title,
      items: retrieveExistentItems,
      grossValueFormatted: simulatorValue ? formatNumberToRealString(simulatorValue) : "",
      grossValue: simulatorValue ? simulatorValue : undefined,
      retentionValue: data.retentionValue,
    };

    setCacheReportValues((prevState) => {
      const filteredCache = prevState.filter((x) => x.title !== report.title);
      return [report, ...filteredCache];
    });
  };

  const handleGenerateReport = async (reportData) => {
    if (cacheReportValues.length === 0) {
      return setFeedbackSnackbar({
        isOpen: true,
        message: "Não há itens para gerar relatório",
        type: "error",
      });
    }

    try {
      await generateSimulatorReport(reportData);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Relatório gerado com sucesso",
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao gerar relatório",
        type: "error",
      });
    }
  };

  const handleOpenGenerateReportDialog = () => setOpenGenerateReportDialog(true);

  useEffect(() => {
    if (invalidSimulatorValue == false && simulatorValue == 0) {
      setInvalidSimulatorValue(true);
    } else {
      setInvalidSimulatorValue(false);
    }
  }, [simulatorValue]);

  return (
    <SimulatorContext.Provider
      value={{
        handleCacheReport,
        simulatorValue,
        setInvalidSimulatorValue,
        handleGenerateReport,
        inssIndividualValue,
        setInssIndividualValue,
        cacheReportValues,
        tabsData,
      }}
    >
      {openGenerateReportDialog ? (
        <GenerateSimulatorReportDialog
          reportData={cacheReportValues}
          onSubmit={handleGenerateReport}
          setOpenGenerateReportDialog={setOpenGenerateReportDialog}
        />
      ) : (
        <Card
          title="Simulador de Cálculo"
          border={false}
          handleClose={() => {
            setSimulatorValue("");
            handleClose();
          }}
        >
          <Grid container spacing={1} sx={{ paddingX: 1.5 }} ref={simulatorRef}>
            <Grid
              size={{ xs: 12, md: 6 }}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: `"Roboto", sans-serif`,
                  minWidth: "fit-content",
                }}
                variant="subtitle2"
              >
                {companyType === "2" ? "Valor Bruto da Remuneração" : "Valor Bruto da Nota Fiscal"}
              </Typography>
              <MoneyInput
                value={simulatorValue || ""}
                onChange={(value) => {
                  setSimulatorValue(value);
                  if (value !== simulatorValue) setCacheReportValues([]);
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.25rem",
                  alignItems: "center",
                  width: "18rem",
                  marginLeft: "auto",
                }}
              >
                <InfoIcon fontSize="small" />
                <Typography
                  sx={{
                    fontFamily: `"Roboto", sans-serif`,
                  }}
                  variant="subtitle2"
                >
                  Clique nas abas e responda as perguntas para obter o cálculo.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              paddingX: 2,
              marginTop: "1rem",
              display: "flex",
              gap: "1.5rem",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {invalidSimulatorValue && (
              <Box>
                <ErrorMessage>Valor Bruto da Nota não pode ser R$ 0,00</ErrorMessage>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              marginTop: "1.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <Tabs tabs={tabsData} handleOpenGenerateReportDialog={handleOpenGenerateReportDialog} />
          </Box>
        </Card>
      )}
    </SimulatorContext.Provider>
  );
};
