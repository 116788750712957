import React, { useState } from "react";
import { Box } from "@mui/material";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { PersonAddAlt1 } from "@mui/icons-material";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import Grid from "@mui/material/Grid2";

export const Payments = ({
  payData,
  setPayData,
  partners,
  getPartners,
  incomeNatureOptions,
  handleSubmit,
}) => {
  const [open, setOpen] = useState(false);

  //funtions utils
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const cpfPartnersFilter = partners.filter((partners) => partners.personType === 0);

  //changes values forms
  const handleDateChange = (name, date) => {
    setPayData((prev) => ({
      ...prev,
      [name]: date ? new Date(date) : "",
    }));
  };

  const handleChange = (name, value) => {
    setPayData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#E6E6E6", borderRadius: 2 }}>
        <Grid container spacing={2} sx={{ paddingInline: 5, paddingBlock: 2 }}>
          <Grid size={{ xs: 12, sm: 2 }}>
            <Input
              title="Período de apuração"
              type="date"
              viewType="month-year"
              value={payData.dateRegister || ""}
              onChange={(date) => handleDateChange("dateRegister", date)}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Input
              type="select"
              title="Beneficiário"
              options={cpfPartnersFilter}
              getOptionLabel={(option) => `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`}
              placeholder="Selecionar contratado"
              value={
                cpfPartnersFilter.find((item) => item.id === payData.businessPartnerId) || null
              }
              onChange={(value) => handleChange("businessPartnerId", value?.id)}
              icon={{
                title: "Adicionar beneficiário",
                func: handleOpen,
                component: <PersonAddAlt1 sx={{ color: "#fff" }} />,
              }}
              error={!payData?.businessPartnerId && "Campo Obrigatório"}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((item) => item.id === payData.incomeNatureId) || null}
              onChange={(value) => handleChange("incomeNatureId", value?.id)}
              error={!payData?.incomeNatureId && "Campo Obrigatório"}
            />
          </Grid>
          <Grid size={{ xs: 4, sm: 2 }}>
            <Input
              title="Data do fato gerador"
              type="date"
              value={payData.datePayment || ""}
              onChange={(date) => handleDateChange("datePayment", date)}
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do rendimento bruto"
              name="totalAmount"
              value={payData.totalAmount || ""}
              onChange={(e) => handleChange("totalAmount", e)}
              error={
                payData?.totalAmount < payData?.taxedAmount
                  ? "Se informado, deve ser maior ou igual ao valor Rend. Tributável."
                  : null
              }
            />
          </Grid>
          <Grid size={3}>
            <Input
              type="amount"
              title="Valor do rendimento tributável"
              name="taxedAmount"
              value={payData.taxedAmount || ""}
              onChange={(e) => handleChange("taxedAmount", e)}
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do IRRF"
              name="amountIR"
              value={payData.amountIR || ""}
              onChange={(e) => handleChange("amountIR", e)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, p: 4 }}>
        <RButton sx={{ width: "150px" }} variant="outlined">
          Cancelar
        </RButton>
        <RButton
          sx={{ width: "150px" }}
          onClick={handleSubmit}
          disabled={!payData?.businessPartnerId || !payData?.incomeNatureId}
        >
          Salvar
        </RButton>
      </Box>
      <ModalContainer scroll={true} open={open} onClose={handleClose}>
        <PartnerForm personType={0} onClose={handleClose} refreshData={getPartners} />
      </ModalContainer>
    </>
  );
};
