import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import validationSchema from "./Forms/validationSchema";
import Input from "~/presentation/components/Common/Input";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Typography as Text,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  Stack,
  Switch,
} from "@mui/material";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import UserForms from "./Forms/UserForms";
import { useTableFilters } from "~/presentation/hooks/useTableFilters";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { LoadingTable } from "../../../../components/LoadingTable";

const newUserObject = {
  id: 0,
  name: "",
  email: "",
  homePhone: "",
  mobilePhone: "",
  password: "",
  confirmPassword: "",
  role: "",
  userProfileId: null,
  active: true,
};

export const UserTable = ({
  userUseCases,
  companyId,
  users,
  refreshUsers,
  userProfiles,
  refreshProfiles,
  userProfilesUseCases,
  loading,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const { handleSearchTable } = useTableFilters();
  const [user, setUser] = useState();
  const [errors, setErrors] = useState({});
  const [currentUsers, setCurrentUsers] = useState([]);

  useEffect(() => {
    if (users.length === 0) {
      refreshUsers();
    }
    if (users.length > 0) {
      setCurrentUsers(users);
    }
  }, [users]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [open, setOpen] = useState(false);

  const handleSearchUsers = (searchValue) => {
    if (searchValue === null || searchValue === undefined || searchValue.trim() === "") {
      setCurrentUsers(users);
    } else {
      handleSearchTable(searchValue, users, setCurrentUsers);
    }
  };

  const handleChangeInput = (field, value) => {
    setUser((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleUserEdition = (data = null) => {
    setUser(data || newUserObject);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleValidation = async (event, user) => {
    event.preventDefault();
    const data = {
      companyId: companyId,
      ...user,
    };

    if (user.id === 0) {
      validationSchema
        .validate(data, { abortEarly: false })
        .then(() => {
          setErrors({});
          handleCreate(data);
        })
        .catch((errors) => {
          setErrors(
            errors.inner.reduce((acc, error) => {
              acc[error.path] = error.message;
              return acc;
            }, {})
          );
        });
    }

    if (user.id !== 0) {
      const fieldsToValidate = ["name", "email", "homePhone", "mobilePhone", "role"];

      if (data.password || data.confirmPassword) {
        fieldsToValidate.push("password", "confirmPassword");
      }

      const editErrors = (
        await Promise.all(
          fieldsToValidate.map((field) =>
            validationSchema
              .validateAt(field, data)
              .then(() => null)
              .catch((error) => error)
          )
        )
      ).filter((error) => error !== null);

      if (editErrors.length > 0) {
        return setErrors(
          editErrors.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      }

      setErrors({});
      handleEdit(data);
    }
  };

  const handleCreate = async (data) => {
    try {
      openBackdrop(true, "Criando usuário");
      await userUseCases.register({ ...data, role: "user" });
      showSuccess("Usuário criado com sucesso!");
      await refreshUsers();
    } catch (error) {
      showError(error, "Erro ao registrar usuário");
    } finally {
      openBackdrop(false);
      setOpen(false);
    }
  };

  const handleEdit = async (data) => {
    try {
      openBackdrop(true, "Editando usuário");
      await userUseCases.updateUser({ id: data.id, data: data, checkPermission: true });
      showSuccess("Usuário editado com sucesso!");
      await refreshUsers();
    } catch (error) {
      showError(error, "Erro ao editar");
    } finally {
      openBackdrop(false);
      handleClose();
    }
  };

  const handleDelete = (userId) => {
    openDialogBox({
      message: `Deseja mesmo deletar este usuário?`,
      callback: async () => {
        try {
          openBackdrop(true, "Excluindo usuário");
          await userUseCases.deleteUser(userId);
          await refreshUsers();
          showSuccess("Usuário deletado com sucesso!");
        } catch (error) {
          showError(error, "Erro ao deletar usuário");
        } finally {
          openBackdrop(false);
          setOpen(false);
        }
      },
    });
  };

  const changeStatus = (userId, isChecked) =>
    setCurrentUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id === userId) {
          return { ...user, active: isChecked };
        } else {
          return user;
        }
      })
    );

  const handleChangeSwitch = async (userId, isChecked) => {
    try {
      changeStatus(userId, isChecked); //alterar na tabela
      await userUseCases.updateUser({
        id: userId,
        data: { active: isChecked }, //chamar endpoint PUT User
        checkPermission: true,
      });
      showSuccess(isChecked ? "Usuário ativado com sucesso!" : "Usuário desativado com sucesso!");
    } catch (error) {
      showError(error, "Erro ao atualizar usuário");
      changeStatus(userId, !isChecked); //caso erro, voltar ao valor anterior na tabela
    }
  };

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 4,
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Text sx={styles.title}>Usuários cadastrados</Text>
        <SecurityPath securePaths={[clientPerms.addUsers]}>
          <Button sx={styles.ButtonAdd} onClick={() => handleUserEdition()}>
            Adicionar novo usuário <AddIcon />
          </Button>
        </SecurityPath>
      </Box>

      {loading ? (
        <LoadingTable />
      ) : (
        <Paper elevation={2} sx={{ width: "100%", overflowX: "auto" }}>
          <Box sx={{ width: "100%" }}>
            <Input
              styles={styles.filterField}
              type="text"
              placeholder="Buscar por nome"
              onChange={(e) => handleSearchUsers(e.target.value)}
            />
          </Box>
          <Table>
            <TableHead sx={styles.TableHead}>
              <TableRow sx={styles.TableRow}>
                <TableCell
                  sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]}
                  align="center"
                >
                  Nome
                </TableCell>
                <TableCell
                  sx={[
                    styles.TableCell,
                    { fontWeight: 600, fontSize: 18, display: { xs: "none", md: "table-cell" } },
                  ]}
                  align="center"
                >
                  Email
                </TableCell>
                <SecurityPath securePaths={[clientPerms.editUsers]}>
                  <TableCell
                    sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]}
                    align="center"
                  >
                    Status
                  </TableCell>
                </SecurityPath>
                <SecurityPath securePaths={[clientPerms.editUsers, clientPerms.deleteUsers]}>
                  <TableCell
                    sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]}
                    align="center"
                  >
                    Ações
                  </TableCell>
                </SecurityPath>
              </TableRow>
            </TableHead>
            {currentUsers
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((u, index) => (
                <TableBody key={index}>
                  <TableCell sx={styles.TableCell}>{u?.name}</TableCell>
                  <TableCell sx={[styles.TableCell, { display: { xs: "none", md: "table-cell" } }]}>
                    {u?.email}
                  </TableCell>
                  <SecurityPath securePaths={[clientPerms.editUsers]}>
                    <TableCell sx={[styles.TableCell, { width: 200 }]}>
                      {u.active ? "Ativo" : "Inativo"}
                      <Switch
                        checked={u?.active}
                        onChange={(e) => handleChangeSwitch(u.id, e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                  </SecurityPath>
                  <SecurityPath securePaths={[clientPerms.editUsers, clientPerms.deleteUsers]}>
                    <TableCell sx={styles.TableCell} align="center">
                      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <SecurityPath securePaths={[clientPerms.deleteUsers]}>
                          <Button sx={styles.DeleteButton} onClick={() => handleDelete(user.id)}>
                            Deletar
                          </Button>
                        </SecurityPath>
                        <SecurityPath securePaths={[clientPerms.editUsers]}>
                          <Button sx={styles.Button} onClick={() => handleUserEdition(u)}>
                            Editar
                          </Button>
                        </SecurityPath>
                      </Box>
                    </TableCell>
                  </SecurityPath>
                </TableBody>
              ))}
          </Table>
          <Stack spacing={1} sx={{ width: "100%", mt: 2 }}>
            <TablePagination
              component="div"
              count={currentUsers?.length || 0}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
              labelRowsPerPage="Usuários por página:"
              rowsPerPageOptions={[10, 15, 20]}
            />
          </Stack>
        </Paper>
      )}
      <ModalBox open={open} onClose={handleClose}>
        <UserForms
          user={user}
          handleChangeInput={handleChangeInput}
          errors={errors}
          handleClose={handleClose}
          handleSubmit={handleValidation}
          userProfiles={userProfiles}
          refreshProfiles={refreshProfiles}
          userProfilesUseCases={userProfilesUseCases}
        />
      </ModalBox>
    </Box>
  );
};

const styles = {
  TableHead: {
    justifyContent: "flex-start",
    fontWeight: 500,
    backgroundColor: "#EFF2F4",
  },
  TableCell: {
    fontSize: "0.9rem",
    textAlign: "left",
    backgroundColor: "#EFF2F4",
    color: "#021148",
    fontFamily: "Montserrat",
  },
  TableRow: {
    backgroundColor: "#EFF2F4",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  Button: {
    background: "#081445",
    border: "1px solid #081445",
    paddingInline: { xs: 2, md: 4 },
    color: "#FFF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#081445",
    },
  },
  DeleteButton: {
    border: "1px solid red",
    paddingInline: { xs: 1, md: 2 },
    color: "red",
    "&:hover": {
      backgroundColor: "red",
      color: "#fff",
    },
    "&:disabled": {
      border: "1px solid #999",
      background: "transparent",
    },
  },
  ButtonAdd: {
    background: "#1341a1",
    padding: 0,
    color: "#FFF",
    paddingInline: 4,
    borderRadius: 20,
    height: 48,
    "&:hover": {
      backgroundColor: "#1341a1",
    },
    "&:disabled": {
      border: "1px solid #999",
      background: "transparent",
    },
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    textAlign: { xs: "center", md: "left" },
  },
  filtersContainer: {
    display: "flex",
    width: "100%",
    flexDirection: { xs: "column", md: "row" },
  },
  filterField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    borderRadius: "3px",
    height: "54px",
    border: "1px solid #ccc ",
    borderColor: "#ccc",
  },
};
