import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { scrollToElement } from "../functions/scrollToElement";
import useMobile from "./useMobile";

export const usePaginatedState = ({ getData, itemsPerPage }) => {
  const scrollToRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobile] = useMobile(960);
  const [numberOfPages, setNumberOfPages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState();

  function escapeHTMLEntities(value) {
    return value
      .replace(/&/g, "&amp;")
      .replace(/â/g, "&acirc;")
      .replace(/ê/g, "&ecirc;")
      .replace(/á/g, "&aacute;")
      .replace(/é/g, "&eacute;")
      .replace(/í/g, "&iacute;")
      .replace(/ó/g, "&oacute;")
      .replace(/ú/g, "&uacute;")
      .replace(/ç/g, "&ccedil;")
      .replace(/ã/g, "&atilde;")
      .replace(/Â/g, "&Acirc;")
      .replace(/Ê/g, "&Ecirc;")
      .replace(/Á/g, "&Aacute;")
      .replace(/É/g, "&Eacute;")
      .replace(/Í/g, "&Iacute;")
      .replace(/Ó/g, "&Oacute;")
      .replace(/Ú/g, "&Uacute;")
      .replace(/Ç/g, "&Ccedil;")
      .replace(/`/g, "&#96;")
      .replace(/´/g, "&#180;")
      .replace(/~/g, "&tilde;")
      .replace(/\^/g, "&circ;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
  }

  const query = new URLSearchParams(location.search);
  const filter = query.get("filter");
  const categoryId = query.get("categoryId");
  const search = query.get("search")
    ? escapeHTMLEntities(decodeURIComponent(query.get("search")))
    : "";
  const queryPage = query.get("page");
  const page = queryPage || 1;
  const isSummaryOnly = query.get("isSummaryOnly") || false;

  const fetchData = async () => {
    scrollToContent();
    setIsLoading(true);
    try {
      const response = await getData({
        categoryId: categoryId ? categoryId : filter,
        text: search,
        pageIndex: page,
        limit: itemsPerPage,
        IsSummaryOnly: isSummaryOnly,
      });
      const { contents, totalPages, multimidias } = response;
      if (response) {
        let items;
        if (Array.isArray(contents)) {
          items = contents;
        } else if (Array.isArray(contents?.posts)) {
          items = contents.posts;
        } else if (Array.isArray(multimidias)) {
          items = multimidias;
        } else if (Array.isArray(response?.posts)) {
          items = response?.posts;
        } else {
          items = response;
        }

        setContent(items);
        setNumberOfPages(totalPages || 1);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToContent = () => {
    if (scrollToRef.current && (filter || search || queryPage || categoryId)) {
      scrollToElement(scrollToRef.current, -75, mobile);
    }
  };

  const handleFilter = (nextFilter) => {
    handlePath(1, search, nextFilter, categoryId);
  };

  const handleFilterBycategory = (nextCategoryId) => {
    handlePath(1, search, filter, nextCategoryId);
  };

  const handleSearch = (nextSearch) => {
    handlePath(1, nextSearch, filter, categoryId);
  };

  const handlePage = (nextPage) => {
    handlePath(nextPage, search, filter, categoryId);
  };

  const clearSearch = () => {
    handlePath(1, "", "", "");
  };

  const clearCategory = () => {
    handlePath(1, search, "", "");
  };

  const handlePath = (nextPage, nextSearch, nextFilter, nextCategoryId) => {
    const url = location.pathname;
    const filterStr = nextCategoryId
      ? "categoryId=" + nextCategoryId
      : "filter=" + (nextFilter ? nextFilter : "");
    const searchStr = "&search=" + (nextSearch ? encodeURIComponent(nextSearch) : "");
    const pageStr = "&page=" + (nextPage ? nextPage : "");
    const encodedUrl = encodeURI(url + "?" + filterStr + searchStr + pageStr);
    navigate(encodedUrl);
  };

  useEffect(() => {
    fetchData();
  }, [page, search, filter, categoryId]);

  return {
    handleFilter,
    handleSearch,
    handlePage,
    numberOfPages,
    isLoading,
    content,
    setContent,
    filter,
    search,
    page,
    scrollToRef,
    clearSearch,
    handleFilterBycategory,
    clearCategory,
  };
};
