import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import { formatDateToTable } from "~/presentation/functions/date";
import { adminPerms } from "~/presentation/security/SecurePaths";

const ExplanatoryVideo = ({ explanatoryVideoUseCases, explanatoryVideoCategory }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  const [explanatoryVideos, setExplanatoryVideos] = useState([]);
  const [categories, setCategories] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "lg",
      fullWidth: true,
      customComponent: () => (
        <CreateEdit
          data={data}
          onCloseForm={closeDialog}
          refreshData={fetchData}
          explanatoryVideoUseCases={explanatoryVideoUseCases}
          getCategories={getCategories}
          categories={categories}
        />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const fetchedContents = await explanatoryVideoUseCases.get();
      setExplanatoryVideos(fetchedContents);
    } catch (error) {
      showError(error, "Erro ao carregar vídeos explicativos");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "publishDate",
      headerName: "Data da Publicação",
      flex: 1,
      align: "center",
      renderCell: (params) => (params.value ? formatDateToTable(params.value) : "-"),
    },
    { field: "title", headerName: "Título", flex: 3 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editVideo]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteVideo]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo vídeo explicativo");
      await explanatoryVideoUseCases.delete(id);
      showSuccess("Vídeo excluído com successo!");
      setExplanatoryVideos((prevVideos) => prevVideos.filter((video) => video.id !== id));
    } catch (error) {
      showError(error, "Erro ao tentar excluir vídeo");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este vídeo?`,
      callback: () => handleDelete(id),
    });
  };

  const getCategories = async () => {
    try {
      openBackdrop(true, "Carregando Categorias");
      const result = await explanatoryVideoCategory.get({ checkPermission: false });
      setCategories(result);
    } catch (error) {
      showError("Erro ao carregar categorias");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageListHeader
        title="Conteúdos explicativos"
        titleAdd="Criar novo conteúdo"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addVideo]}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={explanatoryVideos} />
      </Box>
    </>
  );
};

export default ExplanatoryVideo;
