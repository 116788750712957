import React, { useState, useEffect } from "react";
// date picker context component
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import brLocale from "date-fns/locale/pt-BR";
import GlobalStyles from "~/presentation/themes/styles.App";
// routes
import RoutesMain from "~/presentation/routes/index";
// context
import AppContext from "~/presentation/AppContext";
// styles
import { makeAuthenticationUseCases } from "./factories/use-cases/authentication";
//context providers
import { SnackbarProvider } from "~/presentation/providers/SnackbarProvider";
import { BackdropProvider } from "~/presentation/providers/BackdropProvider";
import { DialogProvider } from "~/presentation/providers/DialogProvider";
import { TawkChat } from "~/presentation/components/TawkChat";

function App() {
  const [user, setUser] = useState();
  const [didLogin, setDidLogin] = useState(true); //importante para renderizar o app quando existe Login novo
  const authenticationUseCases = makeAuthenticationUseCases();

  useEffect(() => {
    if (!localStorage.GT_user) {
      localStorage.clear();
    }
  }, [localStorage]);

  const handleLogout = () => {
    authenticationUseCases.logout();
    setUser(null);
  };

  const handleLogin = async (values) => {
    return authenticationUseCases.auth(values);
  };

  const isLogged = () => {
    return authenticationUseCases.isLogged();
  };

  const contextValues = {
    user,
    setUser,
    handleLogin,
    handleLogout,
    isLogged,
    didLogin,
    setDidLogin,
  };

  return (
    <AppContext.Provider value={contextValues}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={brLocale}
        localeText={{
          fieldYearPlaceholder: () => "AAAA",
        }}
      >
        <BackdropProvider>
          <SnackbarProvider>
            <DialogProvider>
              <GlobalStyles />
              <RoutesMain />
              <TawkChat />
            </DialogProvider>
          </SnackbarProvider>
        </BackdropProvider>
      </LocalizationProvider>
    </AppContext.Provider>
  );
}

export default App;
