import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import LogicAndValidation from "./Components/LogicAndValidation/LogicAndValidation";
import { Box } from "@mui/material";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { reinfPerms } from "~/presentation/security/SecurePaths";

export default function RenderLayout({
  nfseData,
  serviceClassificationOptions = [],
  incomeNatureOptions = [],
  businessPartnerOptions = [],
  selectedEvent,
  refreshData,
  isEventClosed,
  handleChangeFieldValue,
  handleChangeINSSfield,
  errors,
  setErrors,
  fetchOptions,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const apiNfsevalues = new ApiNfseUseCases(makeHttpClient());
  const [inputDisabled, setInputDisabled] = useState(null);

  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  //UPDATE NFSE
  const handleUpdateNfse = async (data) => {
    openBackdrop(true, "Atualizando nota");
    //enviar para o back a nota sem o campo hadChanges
    const { hadChanges, isReady, ...values } = data;
    try {
      await apiNfsevalues.updateNfse(values);
      //mudar campo hadChanges no state para false
      handleChangeFieldValue({
        newItem: { hadChanges: false },
        id: values?.id,
        byPass: true,
      });
      showSuccess("Doc. Fiscal atualizado com sucesso");
      if (selectedEvent) {
        refreshData();
      }
    } catch (error) {
      showError(error, "Erro ao atualizar Doc. Fiscal");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    const hasEditDocPermission = hasPermission([reinfPerms.editFiscalDocument]);
    if (!hasEditDocPermission) {
      return setInputDisabled("ALL");
    }
    if (nfseData?.isR2010) {
      return setInputDisabled("R2010");
    }
    if (nfseData?.isR4020) {
      if (QSO === "A" || QSO === "B") {
        return setInputDisabled("R4020AB");
      } else if (QSO === "C") {
        return setInputDisabled("R4020C");
      } else if (QSO === "D") {
        return setInputDisabled("R4020D");
      }
    }
  }, [nfseData?.id]);

  useEffect(() => {
    if (
      serviceClassificationOptions?.length === 0 ||
      incomeNatureOptions?.length === 0 ||
      businessPartnerOptions?.length === 0
    ) {
      fetchOptions();
    }
  }, []);

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <LogicAndValidation
          values={nfseData}
          onSubmit={handleUpdateNfse}
          serviceClassificationOptions={serviceClassificationOptions}
          incomeNatureOptions={incomeNatureOptions}
          businessPartnerOptions={businessPartnerOptions}
          handleChangeINSSfield={handleChangeINSSfield}
          handleChangeFieldValue={handleChangeFieldValue}
          selectedEvent={selectedEvent}
          isEventClosed={isEventClosed}
          errors={errors}
          setErrors={setErrors}
          inputDisabled={inputDisabled}
        />
      </Box>
    </>
  );
}
