import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import Table from "~/presentation/components/Table";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";
import { Box } from "@mui/material";
import TableActions from "~/presentation/components/TableActions";
import { adminPerms } from "~/presentation/security/SecurePaths";

const Terms = ({ termUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  const [terms, setTerms] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <CreateEdit data={data} onCloseForm={closeDialog} refreshData={fetchData} />
      ),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando os dados");
      const terms = await termUseCases.getAll({ checkPermission: true });
      setTerms(terms);
    } catch (error) {
      showError(error, "Erro ao carregar termos");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "term", headerName: "Termo de busca", flex: 1 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editSearchTerms]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteSearchTerms]}
        />
      ),
    },
  ];

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este termo?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo termo");
      await termUseCases.deleteTerm(id);
      setTerms((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError("Erro ao excluir o termo");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title="Termos de Pesquisa"
        titleAdd="Criar novo termo de pesquisa"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addSearchTerms]}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={terms} />
      </Box>
    </>
  );
};

export default Terms;
