import React from "react";
import {makeCityUseCases} from "~/main/factories/use-cases/city";
import {makeCompanyUseCases} from "~/main/factories/use-cases/company";
import UpdateCities from "~/presentation/views/Admin/ISS/UpdateCities";

export const makeUpdateCities = (params) => {
  return (
    <UpdateCities
      {...params}
      cityUseCases={makeCityUseCases()}
      companyUseCases={makeCompanyUseCases()}
    />
  );
};
