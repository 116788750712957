export const reportTypes = [
  {
    title: "Retenção de IR",
    reports: [
      {
        label: "Geral",
        value: "IR",
        filters: [
          {
            param: "isPayment",
            label: "Por data de pagamento",
            value: false,
          },
        ],
      },
      {
        label: "Por natureza de rendimento (R4020)",
        value: "R4020",
      },
      {
        label: "Por natureza de rendimento - Resumo (R4020)",
        value: "resume-R4020",
      },
      {
        label: "Por Unidade Gestora (R4020)",
        value: "R4020/UG",
      },
    ],
  },
  {
    title: "Retenção de INSS",
    reports: [
      {
        label: "Geral",
        value: "INSS",
        filters: [
          {
            param: "isPayment",
            label: "Por data de pagamento",
            value: false,
          },
        ],
      },

      {
        label: "Por código do serviço (R2010)",
        value: "R2010",
      },
      {
        label: "Por código do serviço - Resumo (R2010)",
        value: "resume-R2010",
      },
      {
        id: 5,
        label: "Por Unidade Gestora (R2010)",
        value: "R2010/UG",
      },
    ],
  },
  {
    title: "Outros",
    reports: [
      {
        label: "Protocolos",
        value: "Protocols",
      },
    ],
  },
];
