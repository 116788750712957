import React, { useContext } from "react";
import ModalCreateEdit from "./ModalCreateEdit";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import TableActions from "~/presentation/components/TableActions";
import Table from "~/presentation/views/Reinf/components/Table/Table";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import { reinfPerms } from "~/presentation/security/SecurePaths";

function Establishments({
  matrizCompanyId,
  refreshData,
  establishmentsData,
  setEstablishmentsData,
  establishmentsUseCases,
}) {
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const currentData = establishmentsData.length > 0 ? establishmentsData.reverse() : [];

  const handleOpenModal = (item) => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <ModalCreateEdit
          matrizCompanyId={matrizCompanyId}
          data={item}
          closeModal={closeDialog}
          establishmentsUseCases={establishmentsUseCases}
          refreshData={refreshData}
        />
      ),
    });
  };

  const handleDelete = async (id) => {
    openDialogBox({
      message: "Deseja eliminar permanentemente este Estabelecimento?",
      callback: () => {
        establishmentsUseCases
          .deleteEstablishmentById(id)
          .then(() => {
            // Remover da tabela
            setEstablishmentsData((prev) => prev.filter((item) => item.id !== id));
            showSuccess("Estabelecimento eliminado com sucesso!");
          })
          .catch((error) => {
            showError(error, `Erro ao excluir Estabelecimento`);
          });
      },
    });
  };

  const columns = [
    {
      field: "cnoCnpj",
      headerName: "CNO/CNPJ do Estabelecimento",
    },
    { field: "name", headerName: "Nome do Estabelecimento" },
    {
      field: "codeUG",
      headerName: "Código da Unidade Gestora",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenModal(params.row)}
          editSecurePath={[reinfPerms.editEstablishment]}
          handleDelete={() => handleDelete(params.row.id)}
          deleteSecurePath={[reinfPerms.deleteEstablishment]}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderSectionPage
        title="Estabelecimentos"
        titleAdd="Incluir"
        handleAdd={handleOpenModal}
        sx={{ backgroundColor: "transparent", paddingInLine: 3, paddingTop: 3, paddingBottom: 0 }}
        addSecurePath={[reinfPerms.addEstablishment]}
      />
      <Table data={currentData} columns={columns} />
    </>
  );
}

export default Establishments;
