import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import PageListHeader from "../../../components/PageListHeader";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import { Box } from "@mui/material";
import CreateEditDialog from "../CreateEditDialog/CreateEditDialog";

const DialogTable = ({ gtFacildialogUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [dialogs, setDialogs] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const apiDialogs = await gtFacildialogUseCases.getDialogs();
    setDialogs(apiDialogs);
    openBackdrop(false);
  };

  const columns = [
    { field: "number", headerName: "Número", flex: 2 },
    { field: "text", headerName: "Texto", flex: 5 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          handleDelete={() => deleteDialog(params.row.id)}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo Diálogo");
    try {
      await gtFacildialogUseCases.delete(id);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data || error?.response,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este diálogo?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dataToTable =
    dialogs?.length > 0 ? dialogs?.map((item, index) => ({ ...item, id: index })) : [];

  return (
    <>
      <PageListHeader
        title="Diálogos"
        titleAdd="Criar novo diálogo"
        handleAdd={() => handleOpenForm()}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={dataToTable} />
      </Box>
      <ModalBox open={openForm} onClose={handleCloseForm} bg="white">
        <CreateEditDialog
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          gtFacildialogUseCases={gtFacildialogUseCases}
        />
      </ModalBox>
    </>
  );
};

export default DialogTable;
