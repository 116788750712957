import { apiURL } from "../../usecases/api";
export class ApiPaymentRecordUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getPaymentRecord(params) {
    try {
      // Monta a URL com ou sem query params
      const url = params
        ? `${apiURL}/PaymentRecord?${new URLSearchParams(params).toString()}`
        : `${apiURL}/PaymentRecord`;
      const response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getPaymentRecordById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/${id}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("API request failed with status: " + response.status);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async getPaymentByProtocol(protocol) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord/reinf/${protocol}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data; // Assuming your data is in the 'data' field of the response.
      } else {
        throw new Error("API request failed with status: " + response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  }

  async updatePaymentRecord(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord`,
        method: "PUT",
        data,
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
  async createPaymentRecord(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord`,
        method: "POST",
        data,
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
  async deletePaymentRecord(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}
