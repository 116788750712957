import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import parse from "html-react-parser";
import PageListHeader from "../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";

const limitCaracteres = (value) => {
  if (value?.length > 50) {
    return value.substring(0, 50) + "...";
  }
  return value;
};

const Annotation = ({ annotationUseCases, cnaesUseCases, termUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //manter a página da tabela sempre atual
  const [page, setPage] = useState(0);

  //Data
  const [notes, setNotes] = useState([]);

  //select
  const [cnaes, setCnaes] = useState([]);
  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados");
      const { data = [] } = await annotationUseCases.getAnnotations();
      setNotes(data);
    } catch (error) {
      showError(error, "Erro ao carregar anotações");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 2,
    },
    {
      field: "text",
      headerName: "Texto",
      flex: 5,
      renderCell: (params) => {
        const text = parse(params.value);
        const textParsed = text?.props?.children;
        return textParsed ? limitCaracteres(textParsed) : null;
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editAnnotations]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteAnnotations]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo resposta");
      await annotationUseCases.deleteAnnotation(id);
      setNotes((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir anotação");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchTerms = async () => {
    try {
      openBackdrop(true, "Carregando Termos");
      const termsListResponse = await termUseCases.getAll({ checkPermission: false });
      setTerms(termsListResponse);
    } catch (error) {
      showError(error, "Erro ao carregar Termos.");
    } finally {
      openBackdrop(false);
    }
  };

  const fetchCnaes = async () => {
    try {
      openBackdrop(true, "Carregando Cnaes");
      const { data } = await cnaesUseCases.listCnaes({ checkPermission: false });
      setCnaes(data);
    } catch (error) {
      showError(error, "Erro ao carregar Cnaes.");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          annotationUseCases={annotationUseCases}
          fetchTerms={fetchTerms}
          fetchCnaes={fetchCnaes}
          cnaes={cnaes}
          terms={terms}
        />
      ) : (
        <>
          <PageListHeader
            title="Anotações"
            titleAdd="Criar anotação"
            handleAdd={() => handleOpenForm()}
            addSecurePath={[adminPerms.addAnnotations]}
          />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={notes} page={page} setPage={setPage} />
          </Box>
        </>
      )}
    </Box>
  );
};
export default Annotation;
