import React, { useContext, useEffect, useState } from "react";
import { makeExplanatoryVideoCategories } from "~/main/factories/use-cases/explanatory-category";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import PageListHeader from "../../components/PageListHeader";
import Form from "./Form";
import validationSchema from "./Form/validationSchema";

function CreateEdit({ data, onCloseForm, refreshData }) {
  const explanatoryVideoCategory = makeExplanatoryVideoCategories();
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);

  //input value and errors
  const [values, setValues] = useState({
    id: 0,
    title: "",
    description: "",
    explanatoryVideos: null,
  });
  const [errors, setErrors] = useState({});

  const isCreate = !data;

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, `${isCreate ? "Criando" : "Editando"} Categoria`);
      if (isCreate) {
        await explanatoryVideoCategory.create(values);
      } else {
        await explanatoryVideoCategory.update(values);
      }
      setErrors({});
      showSuccess(`Categoria ${isCreate ? "criada" : "editada"} com sucesso!`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "criar" : "editar"} categoria`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Categoria`}
        handleBack={onCloseForm}
        titleBack="Voltar para Categorias"
      />
      {values && (
        <Form
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
        />
      )}
    </>
  );
}

export default CreateEdit;
