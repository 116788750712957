import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiSurveyUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async create(data) {
    if (!hasPermission([adminPerms.addSurvey])) {
      throw new Error(securityErrorMessage("criar enquete"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Survey`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAll() {
    if (!hasPermission([adminPerms.seeSurvey])) {
      throw new Error(securityErrorMessage("visualizar enquete"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Survey`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getById(id) {
    if (!hasPermission([adminPerms.seeSurvey])) {
      throw new Error(securityErrorMessage("visualizar enquete"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Survey/${id}`,
        method: "GET",
      });
      let survey = response.data;
      survey.publishDate = new Date(response.data.publishDate);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async update(data) {
    if (!hasPermission([adminPerms.editSurvey])) {
      throw new Error(securityErrorMessage("editar enquete"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Survey`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async delete(id) {
    if (!hasPermission([adminPerms.deleteSurvey])) {
      throw new Error(securityErrorMessage("excluir enquete"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Survey/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
