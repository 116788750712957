import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Form from "./Form";
import removeMask from "~/presentation/functions/removeMask";
import PageListHeader from "../../components/PageListHeader";
import { validationCreate, validationEdit } from "./Form/validationSchema";
import { makeUserProfilesUseCases } from "~/main/factories/use-cases/user-profiles";

function RegisterEdit({ data, onCloseForm, refreshData, userUseCases, companies, fetchCompanys }) {
  const userProfilesUseCases = makeUserProfilesUseCases();
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const isCreate = !data;
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    companyId: "",
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone: "",
    mobile_phone: "",
    userProfileId: null,
    role: "user",
  });

  const [userProfiles, setUserProfiles] = useState([]);

  const getProfiles = async () => {
    try {
      const profiles = await userProfilesUseCases.getProfiles({
        companyId: values?.companyId,
        chechPermission: false,
      });
      const newMap = profiles.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setUserProfiles(newMap);
    } catch (error) {
      showError(error, "Erro ao carregar perfis de usuário");
    }
  };

  useEffect(() => {
    getProfiles();
  }, []);

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (companies.length === 0) {
      fetchCompanys();
    }
  }, []);

  const handleValidation = async (values) => {
    const submitValues = {
      id: values.id,
      companyId: Number(values.companyId),
      name: values.name,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
      homePhone: removeMask(values.phone),
      mobilePhone: removeMask(values.mobile_phone),
      userProfileId: values.userProfileId,
      role: values.role,
    };

    const schema = isCreate ? validationCreate : validationEdit;

    schema
      .validate(values, { abortEarly: false })
      .then(async () => handleSubmit(submitValues))
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Registrando" : "Editando"}  usuário`);
    const { companyId, name, email, homePhone, mobilePhone, userProfileId, role } = values;
    try {
      if (isCreate) {
        await userUseCases.register(values);
      } else {
        await userUseCases.updateUser({
          id: data.id,
          data: {
            companyId,
            name,
            email,
            homePhone,
            mobilePhone,
            userProfileId,
            role,
          },
          checkPermission: true,
        });
      }
      showSuccess(`Usuário ${isCreate ? "registrado" : "editado"} com sucesso!`);
      onCloseForm();
      await refreshData(values.companyId);
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "registrar" : "editar"} usuário`);
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    if (data?.id) {
      setValues(() => {
        return {
          id: data.id,
          name: data.name,
          email: data.email,
          companyId: data.companyId,
          password: "********************",
          confirm_password: "********************",
          phone: data.homePhone,
          mobile_phone: data.mobilePhone,
          userProfileId: data.userProfileId,
          role: data.role,
        };
      });
    }
  }, [data]);

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Registrar" : "Editar"} usuário`}
        handleBack={onCloseForm}
        fontSize={26}
      />
      {values && (
        <Form
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          values={values}
          editing={data?.id}
          companies={companies}
          userProfiles={userProfiles}
          errors={errors}
          getProfiles={getProfiles}
        />
      )}
    </>
  );
}

export default RegisterEdit;
