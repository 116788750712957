import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
// layout
import ReinfDashboardLayout from "~/presentation/layouts/ReinfDashboardLayout";

import Reinf from "~/presentation/views/Reinf/Home/index";
import { ViewDigitalCertificate } from "~/presentation/views/Reinf/DigitalCertificate";
import { SendReinf } from "../views/Reinf/SendReinf";
//R-1000
import { CreateNfse } from "~/presentation/views/Reinf/DocPanel/Create/CreateNfse";
//R-2010
import { ViewHomeNf } from "~/presentation/views/Reinf/DocPanel/Home";
import { PanelR1000 } from "../views/Reinf/Establishments";
import EventsHistory from "../views/Reinf/EventsHistory/EventsHistory";
import BusinessPartner from "~/presentation/views/Reinf/BusinessPartner/BusinessPartner";
import Reports from "~/presentation/views/Reinf/Reports/Reports";
import { SecurityRoute } from "../security/SecurityPath";
import { reinfPerms } from "../security/SecurePaths";
import { Settings } from "../views/Reinf/Settings/Settings";

const ReinfRoutes = () => {
  return (
    <Routes>
      <Route element={<ReinfDashboardLayout />}>
        <Route path="/*" element={<Reinf />} />
        {/*Certificado Digital */}
        <Route
          path="/certificado-digital"
          element={
            <SecurityRoute securePaths={[reinfPerms.seeCertificate]} returnTo="/reinf">
              <ViewDigitalCertificate />
            </SecurityRoute>
          }
        />
        {/*Série R-1000 + Estabelecimentos */}
        <Route
          path="/estabelecimentos-r1000"
          element={
            <SecurityRoute securePaths={[reinfPerms.seeCompany]} returnTo="/reinf">
              <PanelR1000 />
            </SecurityRoute>
          }
        />
        {/*Parceiro de negócio*/}
        <Route path="/parceiros-negocio" element={<BusinessPartner />} />
        {/*Painel doc fiscais + importação*/}
        <Route
          path="/painel-doc-fiscais"
          element={
            <SecurityRoute
              securePaths={[
                reinfPerms.seeFiscalDocument,
                reinfPerms.addFiscalDocument,
                reinfPerms.importXml,
                reinfPerms.importSpreadsheet,
                reinfPerms.importSiafi,
              ]}
              returnTo="/reinf"
            >
              <ViewHomeNf />
            </SecurityRoute>
          }
        />
        {/*Criar Doc Fiscal*/}
        <Route
          path="/criar-doc-fiscal"
          element={
            <SecurityRoute securePaths={[reinfPerms.addFiscalDocument]} returnTo="/reinf">
              <CreateNfse />
            </SecurityRoute>
          }
        />
        {/*Tabela Correção e Envio de Notas + Evetnos enviados*/}
        <Route
          path="/eventos-efd-reinf"
          element={
            <SecurityRoute
              securePaths={[
                reinfPerms.sendR2010,
                reinfPerms.sendR2020,
                reinfPerms.sendR2098,
                reinfPerms.sendR2099,
                reinfPerms.sendR4010,
                reinfPerms.sendR4020,
                reinfPerms.sendR4099,
                reinfPerms.sendR9000,
              ]}
              returnTo="/reinf"
            >
              <SendReinf />
            </SecurityRoute>
          }
        />
        {/*Relatórios*/}
        <Route path="/relatorios" element={<Reports />} />
        {/* configs reinf */}
        <Route path="/configuracoes" element={<Settings />} />
        {/*Histórico Eventos 98 99*/}
        <Route path="/historico-eventos" element={<EventsHistory />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ReinfRoutes;
