import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import { HeaderSectionPage } from "../../components/HeaderSectionPage";
import RButton from "../../components/Common/Buttons/RButton";
import { Container, Stack, Typography as Text } from "@mui/material";
import { CompanyForm } from "./components/CompanyForm/CompanyForm";
import { Person, Store } from "@mui/icons-material";
import { ApiIncomeNature } from "~/data/usecases/incomeNature/incomeNature";
import { CreatePayPerson } from "./components/PersonForm/CreatePayPerson";
import { useSize } from "~/presentation/hooks/useSize";

export const CreateNfse = () => {
  const navigate = useNavigate();
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());
  const apiIncomeNature = new ApiIncomeNature(makeHttpClient());
  const [perfilType, setPerfilType] = useState("CompanyForm");
  //options
  const [partners, setPartners] = useState([]);
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]);
  const { isMobile } = useSize();

  const user = JSON.parse(localStorage.getItem("GT_user"));

  const getPartners = async () => {
    const result = await apiBusinessPartner.listBusinessPartner();
    setPartners(result);
  };

  const getIncomeNatures = async () => {
    const result = await apiIncomeNature.listIncomeNature();
    setIncomeNatureOptions(result);
  };

  const handleBackdropClick = () => {
    navigate("/reinf/painel-doc-fiscais");
  };

  const handleFormSelect = (type) => {
    setPerfilType(type);
  };

  const renderFormAddDoc = () => {
    switch (perfilType) {
      case "CompanyForm":
        return (
          <CompanyForm
            partners={partners}
            handleReturn={handleBackdropClick}
            refreshData={refreshData}
          />
        );
      case "PersonForm":
        return (
          <CreatePayPerson
            partners={partners}
            getPartners={getPartners}
            handleReturn={handleBackdropClick}
            refreshData={refreshData}
            incomeNatureOptions={incomeNatureOptions}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getPartners();
    getIncomeNatures();
  }, []);

  const refreshData = () => {
    getPartners();
  };

  return (
    <>
      <HeaderSectionPage title="Adicionar documentos fiscais" />
      <Container maxWidth={false} sx={{ paddingBottom: 2 }}>
        <Text sx={styles.title}>Qual o perfil do contratado?</Text>
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
          <RButton
            sx={{ width: "min(300px, 100%)", p: 1.5 }}
            variant={perfilType === "PersonForm" ? "contained" : "outlined"}
            color={perfilType === "PersonForm" ? "primary" : "secondary"}
            onClick={() => handleFormSelect("PersonForm")}
            startIcon={<Person />}
            disabled={!["admin", "superAdmin"].includes(user?.role)} //permissão até finalizar desenvolvimento
          >
            Pessoa física
          </RButton>
          <RButton
            sx={{ width: "min(300px, 100%)", p: 1.5 }}
            variant={perfilType === "CompanyForm" ? "contained" : "outlined"}
            color={perfilType === "CompanyForm" ? "primary" : "secondary"}
            onClick={() => handleFormSelect("CompanyForm")}
            startIcon={<Store />}
          >
            Pessoa jurídica
          </RButton>
        </Stack>
      </Container>
      {renderFormAddDoc()}
    </>
  );
};

const styles = {
  title: {
    display: "flex",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 15,
    fontWeight: 600,
    color: "#242424",
    paddingBlock: 2,
  },
};
