import React, { useState } from "react";
import { Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { formStyles } from "../../../../Styles/FormStyles";
import Grid from "@mui/material/Grid2";
import { Subtitle, Title } from "../../../styles";

function CSRF({
  values,
  incomeNatureOptions,
  errors,
  isBcSCRFWrong,
  inputDisabled,
  handleChangeFieldValue,
}) {
  const [show, setShow] = useState(false);

  //ERROR: bcCSRF
  const isBcSCRFWrongText =
    isBcSCRFWrong && "Valor da base CSRF não pode ser maior do que o Valor Bruto do doc!";

  //ERROR: alíquota tem sempre de dar 4.65 que é a soma das % de CSLL+PIS+CONFINS
  const aliquotValidFormula =
    values?.aliquotCSRF?.toFixed(2) == 4.65 || values?.aliquotCSRF?.toFixed(2) == 1.0;
  const isAmountPositive = values?.amountCSRF > 0;
  const isAmountNotRetainedNull = values?.amountNotRetained === 0;
  const isAliquotaWrong = isAmountPositive && isAmountNotRetainedNull && !aliquotValidFormula;
  const isAliquotaWrongText = isAliquotaWrong && "Alíquota deve dar 4.65%";

  //ERROR: amount
  const isAmountWrong = values?.amountCSRF > values?.bcCSRF;
  const isAmountWrongText = isAmountWrong && "A retenção não pode ser maior do que a Base.";

  //ERROR: data no futuro
  const isDateInTheFuture = values?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data no presente";

  return (
    <Box sx={styles.container}>
      <Title>Retenção de CSRF</Title>
      <Box sx={formStyles.fieldsWrapper}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((item) => item.id == values?.incomeNatureId)}
              onChange={(option) => {
                const { id, incomeDescription } = option;
                handleChangeFieldValue({
                  newItem: { incomeNatureId: id, incomeNatureDescription: incomeDescription },
                  field: "incomeNatureId",
                });
              }}
              error={errors[`[${values?.id}].incomeNatureId`]}
              disabled={inputDisabled === "R4020D" || inputDisabled === "ALL"}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={values?.dateOfPayment}
              onChange={(date) => {
                handleChangeFieldValue({
                  newItem: { dateOfPayment: date },
                  field: "dateOfPayment",
                });
              }}
              error={errors[`[${values?.id}].dateOfPayment`] || isDateWrong}
              disabled={inputDisabled === "R4020D" || inputDisabled === "ALL"}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Base da CSRF"
              value={values?.bcCSRF}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { bcCSRF: value },
                  field: "bcCSRF",
                });
              }}
              error={errors[`[${values?.id}].bcCSRF`] || isBcSCRFWrongText}
              disabled={inputDisabled === "R4020D" || inputDisabled === "ALL"}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Retenção da CSRF"
              value={values?.amountCSRF}
              error={errors[`[${values?.id}].amountCSRF`] || isAmountWrongText}
              disabled
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              prefix="%"
              title="Alíquota"
              value={values?.aliquotCSRF.toFixed(2)}
              warning={errors[`[${values?.id}].aliquotCSRF`] || isAliquotaWrongText}
              disabled
            />
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
          <Subtitle onClick={() => setShow(!show)}>
            <KeyboardArrowDown
              style={{
                fontSize: "2.4rem",
                rotate: show ? "0deg" : "-90deg",
                transition: "200ms",
                color: "#A7A7A7",
                marginBlock: 2,
              }}
            />
            Valores de CSLL, PIS/Pasep e COFINS
          </Subtitle>

          {show && (
            <Grid container spacing={1}>
              <Grid size={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do CSLL"
                  value={values?.amountCSLL}
                  percentageValue={
                    values?.bcCSRF > 0
                      ? ((values?.amountCSLL / values?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(value) => {
                    handleChangeFieldValue({
                      newItem: { amountCSLL: value },
                      field: "amountCSLL",
                    });
                  }}
                  disabled={inputDisabled === "R4020D" || inputDisabled === "ALL"}
                />
              </Grid>
              <Grid size={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do PIS/Pasep"
                  value={values?.amountPIS}
                  percentageValue={
                    values?.bcCSRF > 0
                      ? ((values?.amountPIS / values?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(value) => {
                    handleChangeFieldValue({
                      newItem: { amountPIS: value },
                      field: "amountPIS",
                    });
                  }}
                  disabled={inputDisabled === "R4020D" || inputDisabled === "ALL"}
                />
              </Grid>
              <Grid size={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do COFINS"
                  value={values?.amountCOFINS}
                  percentageValue={
                    values?.bcCSRF > 0
                      ? ((values?.amountCOFINS / values?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(value) => {
                    handleChangeFieldValue({
                      newItem: { amountCOFINS: value },
                      field: "amountCOFINS",
                    });
                  }}
                  disabled={inputDisabled === "R4020D" || inputDisabled === "ALL"}
                />
              </Grid>
              <Grid size={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção não efetuada"
                  value={values?.amountNotRetained}
                  onChange={(value) => {
                    handleChangeFieldValue({
                      newItem: { amountNotRetained: value },
                      field: "amountNotRetained",
                    });
                  }}
                  disabled={inputDisabled === "R4020D" || inputDisabled === "ALL"}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBlock: 2,
  },
  BoxStyle: {
    display: "flex",
    alignItems: "end",
    width: "100%",
    gap: 1,
  },
  gridStylesDark: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  percentageStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    maxWidth: "80px",
    paddingBottom: "2px",
  },
};

export default CSRF;
