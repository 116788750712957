import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiIssAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getIssAnswers() {
    if (!hasPermission([adminPerms.seeIssAnswers])) {
      return new Error(securityErrorMessage("visualizar respostas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/issanswer?isSummary=true`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getIssAnswer(id) {
    if (!hasPermission([adminPerms.seeIssAnswers])) {
      return new Error(securityErrorMessage("visualizar respostas."));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/issanswer/${id}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async createIssAnswer(data) {
    if (!hasPermission([adminPerms.addIssAnswers])) {
      return new Error(securityErrorMessage("adicionar respostas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/IssAnswer`,
        method: "POST",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateIssAnswer(data) {
    if (!hasPermission([adminPerms.editIssAnswers])) {
      return new Error(securityErrorMessage("editar respostas."));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/IssAnswer`,
        method: "PUT",
        data,
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteIssAnswer(id) {
    if (!hasPermission([adminPerms.deleteIssAnswers])) {
      return new Error(securityErrorMessage("excluir respostas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/IssAnswer/${id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteIssAnswerItem(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/IssAnswer/item/${id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getGroups() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/groups`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}
