import React, { useContext, useState } from "react";
import { useLocation } from "react-router";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { Menu } from "~/presentation/views/Client/MyPanel/components/Menu";
import { NewTaxInformation } from "./Pages/NewTaxInformation";
import { UserData } from "./Pages/UserData";
import { UserPlan } from "./Pages/UserPlan";
import { Container } from "@mui/material";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const DEFAULT_IMAGE = "/assets/escritorio.jpg";

const pages = [
  {
    id: 1,
    label: "Envio e Controle de IF's",

    securePaths: [clientPerms.seeFiscalInformation],
  },
  { id: 2, label: "Meu Plano" },
  { id: 3, label: "Meus Dados" },
];

const MyPanel = ({
  userUseCases,
  companyUseCases,
  taxInformationUseCases,
  userProfilesUseCases,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const page = query.get("page");
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const { showError } = useContext(SnackbarContext);

  // DADOS DO PLANO PAGINA 2 e EMPRESA PAGINA 3
  const [companyData, setCompanyData] = useState({});

  const getCompany = async () => {
    try {
      const result = await companyUseCases.getCompanyById({
        id: companyId,
        checkPermission: false,
      });
      setCompanyData(result);
    } catch (error) {
      showError(error, "Erro ao carregar dados da empresa");
      setCompanyData({});
    }
  };

  const renderPages = () => {
    const pagesComponents = {
      1: (
        <NewTaxInformation
          taxInformationUseCases={taxInformationUseCases}
          companyId={companyId}
          userUseCases={userUseCases}
        />
      ),
      2: (
        <UserPlan
          planDetails={companyData?.plan}
          onGetPlanDetails={getCompany}
          userUseCases={userUseCases}
          userProfilesUseCases={userProfilesUseCases}
        />
      ),
      3: (
        <UserData userUseCases={userUseCases} companyData={companyData} onGetCompany={getCompany} />
      ),
    };

    //se o usuário for para a page=1 (manualmente) e não tiver permissões, redireciona para page=2
    const permission = hasPermission([clientPerms.seeFiscalInformation]);
    if (page == 1 && !permission) {
      return pagesComponents[2];
    }

    return pagesComponents[page];
  };

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/cliente/meu-painel?page=1"
            title="Meu Painel"
            image={DEFAULT_IMAGE}
            blur={0.8}
          />
          <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
            <Menu pages={pages} />
            {renderPages()}
          </Container>
        </>
      )}
    </HomeLayout>
  );
};

export default MyPanel;
