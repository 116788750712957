import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Wrapper, MessageWrapper } from "~/presentation/views/Admin/BlogComment/Reply/styles";

import MessageReplyForm from "~/presentation/views/Admin/components/MessageReply";
import MessageFromToSubject from "~/presentation/components/MessageFromToSubject";

import { format } from "date-fns";
import parse from "html-react-parser";
import useMobile from "~/presentation/hooks/useMobile";

import { useContext } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import PageListHeader from "../../components/PageListHeader";
import { transparentize } from "polished";

const ReplyBlogComment = ({ commentUseCases }) => {
  const [accordionsExpanded, setAccordionsExpanded] = useState([]);
  const [chat, setChat] = useState();
  const [mobile] = useMobile(768);
  const isExpanded = (id) => accordionsExpanded.includes(id);
  const id = useParams().id;
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const fetchChat = async () => {
    const response = await commentUseCases.getCommentById(id);
    setChat(response);
  };

  const toggleAccordionExpanded = (id) => {
    if (isExpanded(id)) {
      setAccordionsExpanded([...accordionsExpanded].filter((accId) => accId !== id));
    } else {
      setAccordionsExpanded([...accordionsExpanded, id]);
    }
  };

  const formattedDatetime = (datetime) =>
    format(new Date(datetime), mobile ? "HH:mm dd/MM" : "HH:mm dd/MM/yyyy");

  const createComment = async (values) => {
    backdropOpen(values.draft);
    try {
      const submitValues = {
        id: 0,
        publishDate: new Date(),
        commentText: values.response,
        userId: user.id,
        postId: chat.postComment.id,
        commentResponseId: chat.id,
        isDraft: values.draft,
      };
      await commentUseCases.submitCommentResponse(submitValues);
    } catch (error) {
      openErrorFeedback(values.draft);
    } finally {
      closeBackdrop();
      navigate("/admin/comentarios");
    }
  };

  const updateComment = async (values) => {
    backdropOpen(values.draft);
    try {
      const submitValues = {
        id: id,
        publishDate: chat.publishDate,
        commentText: values.response,
        userId: chat.userComment.id,
        postId: chat.postComment.id,
        commentResponseId: chat.commentResponse.id,
        isDraft: values.draft,
      };
      await commentUseCases.updateCommentResponse(submitValues);
    } catch (error) {
      openErrorFeedback(values.draft);
    } finally {
      closeBackdrop();
    }
  };

  const handleSubmit = async (values) => {
    if (id && chat.status === 2) {
      await updateComment(values);
    } else {
      await createComment(values);
    }
    navigate("/admin/comentarios");
  };

  const openErrorFeedback = (isDraft) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: `Erro ao ${isDraft ? "salvar" : "enviar"} mensagem: missing endpoint`,
      type: "error",
    });
  };

  const backdropOpen = (isDraft) => {
    openBackdrop(true, `${isDraft ? "Salvando" : "Enviando"} mensagem`);
  };

  const closeBackdrop = () => openBackdrop(false);

  useEffect(() => {
    fetchChat();
  }, []);

  return (
    <Box>
      <PageListHeader
        title="Responder Comentário"
        handleBack={() => navigate("/admin/comentarios")}
      />

      {chat ? (
        <Wrapper className="scrollbar-white">
          <MessageWrapper>
            <MessageFromToSubject
              from={user.email}
              to={chat.userComment.email}
              subject={chat.postComment.title}
            />
            {chat.status !== 2 && (
              <Accordion
                square={false}
                expanded={isExpanded(id)}
                onChange={() => toggleAccordionExpanded(id)}
                sx={{
                  maxWidth: "100%",
                  backgroundColor: "#e1e1e1",
                  color: "#383f48",
                  boxShadow: "none",
                  margin: 3,
                  borderRadius: "6px",
                  overflow: "hidden",
                }}
              >
                <AccordionSummary>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto min-content",
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {chat.userComment.email}
                      </Typography>
                      <Typography sx={{ whiteSpace: "nowrap", fontFamily: "Montserrat" }}>
                        {formattedDatetime(chat.publishDate)}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        color: transparentize(0.25, "#383f48"),
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 100px)",
                        fontFamily: "Montserrat",
                        opacity: isExpanded(id) ? 0 : 1,
                        "&:nth-of-type(1)": {
                          width: "100%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "block",
                        },
                      }}
                    >
                      {parse(chat.commentText)}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <div>{parse(chat.commentText)}</div>
                </AccordionDetails>
              </Accordion>
            )}
          </MessageWrapper>
          {user.id === chat.userComment.id && (
            <MessageReplyForm
              draft={chat.status === 2 ? true : false}
              handleSubmit={handleSubmit}
              message={chat.status === 2 ? chat.commentText : ""}
            />
          )}
        </Wrapper>
      ) : (
        <div className="absolute-center">
          <CircularProgress color="inherit" />
        </div>
      )}
    </Box>
  );
};

export default ReplyBlogComment;
