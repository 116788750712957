import React, { useState } from "react";
import { Close, ExpandMore } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Popover } from "@mui/material";

function SetPeriodButton({
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  formatDateToBrazilian,
  placeholder,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const date = initialDate && finalDate;
  const isDateWrong = initialDate > finalDate;
  const isDateInTheFuture = initialDate > new Date() || finalDate > new Date();
  const errorDate = isDateWrong || isDateInTheFuture;

  return (
    <Box>
      <p style={{ fontSize: 12, fontStyle: "italic", color: errorDate ? "#d32f2f" : "#222222" }}>
        Período
      </p>
      <button
        style={{ ...styles.Button, border: errorDate ? "2px solid #d32f2f" : "none" }}
        aria-describedby={id}
        onClick={handleClick}
      >
        {!date ? (
          <p style={{ color: "#000", fontStyle: "italic" }}>{placeholder}</p>
        ) : (
          `${formatDateToBrazilian(initialDate)} - ${formatDateToBrazilian(finalDate)}`
        )}
        <ExpandMore
          style={{
            position: "absolute",
            right: "5px",
            color: "#BBBBBB",
            transform: open ? "rotate(180deg)" : "unset",
          }}
        />
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={styles.Box}>
          <Box
            sx={[styles.Option, styles.deleteFilterOption]}
            onClick={() => {
              setInitialDate(null);
              setFinalDate(null);
            }}
          >
            <em style={styles.Filter}>
              <Close fontSize="small" /> Apagar filtro
            </em>
          </Box>
          <Box sx={styles.Option}>
            Inicial:
            <Input
              styles={{ height: "50px" }}
              type="date"
              value={initialDate || null}
              maxDate={finalDate ? finalDate : new Date()}
              onChange={(date) => {
                setInitialDate(date);
              }}
            />
          </Box>
          <Box sx={styles.Option}>
            Final:
            <Input
              styles={{ height: "50px" }}
              type="date"
              value={finalDate || null}
              minDate={initialDate ? initialDate : false}
              onChange={(date) => {
                setFinalDate(date);
              }}
            />
          </Box>
          <Box>
            {isDateWrong && (
              <span style={styles.error}>
                <ErrorIcon fontSize="small" sx={{ padding: 0, margin: 0 }} />
                <span>Data Inicial superior à data Final!</span>
              </span>
            )}
            {isDateInTheFuture && (
              <span style={styles.error}>
                <ErrorIcon fontSize="small" sx={{ padding: 0, margin: 0 }} />
                <span>Selecione uma data no presente!</span>
              </span>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export default SetPeriodButton;

const styles = {
  Button: {
    width: "100%",
    height: "40px",
    maxHeight: "40px",
    backgroundColor: "#DCDCDC",
    boxShadow: "none",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "400",
    color: "#222222",
    cursor: "pointer",
    border: "none",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    borderRadius: "5px",
    position: "relative",
  },
  Box: {
    backgroundColor: "#FBFBFB",
    borderRadius: 1,
  },
  Option: {
    display: "flex",
    fontSize: 14,
    alignItems: "center",
    justifyContent: "space-between",
    gap: "3px",
    cursor: "pointer",
    paddingX: 1.6,
    paddingY: 0.4,
  },
  deleteFilterOption: {
    backgroundColor: "rgba(25, 118, 210, 0.08)",
    mt: 1,
    height: 32,
    "&:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.1)",
    },
  },
  error: {
    userSelect: "none",
    color: "#d32f2f",
    display: "flex",
    alignItems: "center",
    gap: 6,
    justifyContent: "center",
    paddingBlock: 4,
  },
  Filter: {
    fontSize: 14,
    opacity: 0.8,
    fontWeight: "400",
    color: "#d32f2f",
    display: "flex",
    alignItems: "center",
  },
};
