import React from "react";
import { Box, Modal } from "@mui/material";

const ModalBox = ({ open, onClose, children, bg = "#E8E8E8", maxWidth, ...props }) => {
  const styles = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: maxWidth || "800px",
      borderRadius: "12px",
      boxShadow: 24,
      scrollBehavior: "smooth",
      maxHeight: "90vh",
      overflowY: "auto",
    },
  };

  //se onClose === null é impossivel sair do modal clicando no background

  return (
    <Modal open={open} onClose={onClose} {...props}>
      <Box sx={{ ...styles.modal, bgcolor: bg }}>{children}</Box>
    </Modal>
  );
};

export default ModalBox;
