import React from "react";
import { Title } from "../../../styles";
import { Box } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { formStyles } from "../../../../Styles/FormStyles";
import Grid from "@mui/material/Grid2";

function IR({
  values,
  incomeNatureOptions,
  errors,
  isBcIRWrong,
  inputDisabled,
  modalCalculateRetention,
  handleChangeFieldValue,
}) {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  //se é nota de produto NFe
  const isNFe = values?.typeDoc === 1;

  //bcIR
  const isBcIRWrongText =
    isBcIRWrong && "Valor da base IR não pode ser maior do que o Valor de Operação do documento!";

  //aliquotIR
  const aliquotValue = (values?.amountIR / values?.bcIR) * 100;
  const aliquotsABC = ["0.24", "1.20", "1.50", "2.40", "4.80"];
  const aliquotsD = ["1.00", "1.50"];
  const possibleAliquots = QSO === "D" ? [...aliquotsD] : [...aliquotsABC];
  const isAliquotValid = possibleAliquots?.includes(aliquotValue.toFixed(2));
  const isAliquotWrong = values?.bcIR > 0 && values?.amountIR > 0 && !isAliquotValid;
  const isAliquotWrongText = isAliquotWrong && "O valor da retenção pode estar incorreto";

  //ERROR: data no futuro
  const isDateInTheFuture = values?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data no presente";

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Title>Retenção de IR</Title>
        {isNFe && modalCalculateRetention}
      </Box>
      <Box sx={formStyles.fieldsWrapper}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions?.find((item) => item.id == values?.incomeNatureId)}
              onChange={(option) => {
                const { id, incomeDescription } = option;
                handleChangeFieldValue({
                  newItem: { incomeNatureId: id, incomeNatureDescription: incomeDescription },
                  field: "incomeNatureId",
                });
              }}
              error={errors[`[${values?.id}].incomeNatureId`]}
              disabled={
                inputDisabled === "R4020C" || inputDisabled === "R4020D" || inputDisabled === "ALL"
              }
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={values?.dateOfPayment || null}
              onChange={(date) => {
                handleChangeFieldValue({
                  newItem: { dateOfPayment: date },
                  field: "dateOfPayment",
                });
              }}
              error={errors[`[${values?.id}].dateOfPayment`] || isDateWrong}
              disabled={
                inputDisabled === "R4020C" || inputDisabled === "R4020D" || inputDisabled === "ALL"
              }
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Base do IRRF"
              value={values?.bcIR}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { bcIR: value },
                  field: "bcIR",
                });
              }}
              error={errors[`[${values?.id}].bcIR`] || isBcIRWrongText}
              disabled={
                inputDisabled === "R4020C" || inputDisabled === "R4020D" || inputDisabled === "ALL"
              }
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Retenção do IRRF"
              value={values?.amountIR}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { amountIR: value },
                  field: "amountIR",
                });
              }}
              error={errors[`[${values?.id}].amountIR`]}
              disabled={
                inputDisabled === "R4020C" || inputDisabled === "R4020D" || inputDisabled === "ALL"
              }
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              prefix="%"
              title="Alíquota"
              value={values?.aliquotIR.toFixed(2)}
              disabled
              warning={isAliquotWrongText}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Retenção não efetuada"
              value={values?.amountNotRetained}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { amountNotRetained: value },
                  field: "amountNotRetained",
                });
              }}
              error={errors[`[${values?.id}].amountNotRetained`]}
              disabled={
                inputDisabled === "R4020C" || inputDisabled === "R4020D" || inputDisabled === "ALL"
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBlock: 2,
  },
};
export default IR;
