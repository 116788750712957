import React from "react";
import { formatDateToBrazilian } from "../Utils/utilsDate";

function useHandleDocFiscais() {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const QSOAB = QSO === "A" || QSO === "B";
  const QSOC = QSO === "C";
  const QSOD = QSO === "D";

  //objeto zerado do itemsNFse
  const newItemsNFseObject = {
    id: 0,
    serviceClassificationId: 0,
    serviceClassification: "",
    bc: 0,
    amount: 0,
    aliquot: 0,
    principalRententionAmount: 0,
    subcontractorAmount: 0,
    annotation: "",
    serviceAmount15: 0,
    serviceAmount20: 0,
    serviceAmount25: 0,
    additionalRetentionAmount: 0,
    additionalNotRetentionAmount: 0,
  };

  //Valor Aliquota IR = (Retenção IR / Base de calculo IR) * 100
  const calculateAliquotIR = (
    amountIR = 0,
    bcIR = 0,
    amountAggregate = 0,
    amountOperation = 0,
    aliquotIR = 0
  ) => {
    if (QSOC || QSOD) {
      if (amountIR >= 0 && bcIR >= 0) {
        return (amountIR / bcIR) * 100;
      }
    }
    if (QSOAB) {
      if (amountAggregate >= 0 && amountOperation >= 0) {
        return (amountAggregate / amountOperation) * 100;
      }
    }
    return aliquotIR;
  };

  //Aliquota CSRF = (Retenção CSRF / Base de calculo CSRF) * 100
  const calculateAliquotCSRF = (bcCSRF = 0, amountCSRF = 0, aliquotCSRF = 0) => {
    if (bcCSRF >= 0 && amountCSRF >= 0) {
      return (amountCSRF / bcCSRF) * 100;
    }
    return aliquotCSRF;
  };

  //Valor de operação
  const calculateAmountOperation = (amountBC = 0, unconditionalDiscount = 0) => {
    return amountBC - unconditionalDiscount;
  };

  //Valor de Retenção CSRF
  const calculateAmoutCSRF = (amountCSLL = 0, amountPIS = 0, amountCOFINS = 0) =>
    amountCSLL + amountPIS + amountCOFINS;

  //Valor total da Base de Cálculo INSS
  const calculateNewBcINSS = (allBcTypeService = 0, bcINSS = 0) => {
    if (allBcTypeService >= 0) {
      return allBcTypeService;
    }
    return bcINSS;
  };

  //Valor total da Retenção INSS
  const calculateNewAmountINSS = (allAmountTypeService = 0, amountINSS = 0) => {
    if (allAmountTypeService >= 0) {
      return allAmountTypeService;
    }
    return amountINSS;
  };

  //usada na função getNewItemsNFse
  const getCurrentItemNFse = (itemsNFse = null) => {
    if (itemsNFse === null) {
      return [newItemsNFseObject];
    }
    return itemsNFse;
  };

  //usada na função getNewItemsNFse
  const updateItemsNFseAliquot = (items) => {
    return items.map((item) => {
      const { bc, amount, aliquot } = item;
      //SE: bc e amount estejam preenchidos mas aliquot não
      if (bc > 0 && amount > 0 && aliquot === 0) {
        return {
          ...item,
          aliquot: (amount / bc) * 100,
        };
      }
      return item;
    });
  };

  //Função que lida com os itemsNFse de cada nota (usada na altura dos Fetchs das notas fiscais)
  const getNewItemsNFse = (itemsNFse = null, bcINSS = 0, amountINSS = 0) => {
    const allBcTypeService = itemsNFse?.reduce((total, curr) => total + curr.bc, 0);
    const allAmountTypeService = itemsNFse?.reduce((total, curr) => total + curr.amount, 0);

    //Se itemsNFse === null -> ATRIBUIR: [{campos zerados para mostrar na tela ao usuário}]
    let newItemsNFse = getCurrentItemNFse(itemsNFse);

    //ATRIBUIR: bcINSS a itemsNFse[0].bc e amountINSS a itemsNFse[0].amount
    if (allBcTypeService === 0 && allAmountTypeService === 0) {
      //SE: bc e amout do itemsNFse[0] sejam 0 e mas bcINSS e amountINSS sejam positivos
      if (bcINSS > 0 && amountINSS > 0) {
        newItemsNFse[0] = {
          ...newItemsNFse[0],
          bc: bcINSS,
          amount: amountINSS,
          aliquot: (amountINSS / bcINSS) * 100,
        };
      }
    }

    //ATRIBUIR: calcular a aliquot de cada itemsNFse (SE: bc e amount > 0)
    newItemsNFse = updateItemsNFseAliquot(newItemsNFse);

    return newItemsNFse;
  };

  //Função que compara notas alteradas com o seu estado inicial
  const handleChangedNfse = ({
    changedNfse = [], //array com os campos alterados {id: nota.id, campo1: nota.compa1value, ...}
    allNfse = [], //array com as notas em estado inicial (no fetch)
    setEqual = () => {}, //função que atribui à nota o estado de NÃO ALTERADA -> recebe o id da nota em props
    setChanged = () => {}, //função que atrubui à nota o estado de ALTERADA -> recebe o id da nota em props
  }) => {
    if (changedNfse.length === 0) return;

    changedNfse.forEach((doc) => {
      const initialObj = allNfse.find((i) => i.id === doc.id);

      if (!initialObj) {
        return;
      }

      let hasChanges = false;

      for (let key in doc) {
        if (Object.prototype.hasOwnProperty.call(doc, key)) {
          //caso itemsNFse não seja null
          if (key === "itemsNFse" && Array.isArray(doc[key]) && Array.isArray(initialObj[key])) {
            doc[key].forEach((item, index) => {
              //comprar mesmo objeto do itemsNFse com o do inital state
              const initialItem = initialObj[key][index];
              if (initialItem) {
                for (let itemKey in item) {
                  if (Object.prototype.hasOwnProperty.call(item, itemKey)) {
                    if (item[itemKey] !== initialItem[itemKey]) {
                      setChanged(doc.id);
                      hasChanges = true;
                    }
                  }
                }
              } else {
                //caso itemsNFse.length do changed > inital, ou seja, foram adicionados novos objetos a itemsNFse
                setChanged(doc.id);
                hasChanges = true;
              }
            });
          } else if (key.toLowerCase().includes("date")) {
            // comparar datas
            if (formatDateToBrazilian(doc[key]) !== formatDateToBrazilian(initialObj[key])) {
              setChanged(doc.id);
              hasChanges = true;
            }
          } else {
            // comparar outros campos da nota
            if (doc[key] !== initialObj[key]) {
              setChanged(doc.id);
              hasChanges = true;
            }
          }
        }
      }

      if (!hasChanges) {
        //caso não haja diferenças, damos como nota não alterada
        setEqual(doc.id);
      }
    });
  };

  return {
    newItemsNFseObject,
    calculateAliquotIR,
    calculateAliquotCSRF,
    calculateAmountOperation,
    calculateAmoutCSRF,
    calculateNewBcINSS,
    calculateNewAmountINSS,
    getNewItemsNFse,
    handleChangedNfse,
  };
}

export default useHandleDocFiscais;
