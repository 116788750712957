import React from "react";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { Tabs as BaseTabs } from "@mui/base/Tabs";
import { styled } from "@mui/system";
import { useSize } from "~/presentation/hooks/useSize";

export const Tabs = ({ children, ...props }) => {
  const StyledBaseTabs = styled(BaseTabs, {
    shouldForwardProp: (prop) => prop !== "sx",
  })(({ sx }) => ({
    ...sx,
  }));
  return <StyledBaseTabs {...props}>{children}</StyledBaseTabs>;
};

export const TabPanel = ({ children, margin = "12px", marginXL = "24px", ...props }) => {
  const StyledTabPanel = styled(BaseTabPanel, {
    shouldForwardProp: (prop) => prop !== "sx",
  })(({ sx, error }) => ({
    margin: margin,
    padding: margin,
    backgroundColor: "#E6E6E6",
    borderRadius: "12px",
    border: error ? "1px solid #d32f2f" : "none",
    "@media (min-width: 900px)": { margin: marginXL },
    ...sx,
  }));
  return <StyledTabPanel {...props}>{children}</StyledTabPanel>;
};

export const TabsList = ({ children, borderRadius = "12px", margin = "24px", ...props }) => {
  const { isMobile } = useSize();

  const StyledTabList = styled(BaseTabsList, {
    shouldForwardProp: (prop) => prop !== "sx",
  })(({ sx }) => ({
    width: "fit-content",
    backgroundColor: sx?.bg || "#E6E6E6", //cinzento
    borderRadius: borderRadius,
    boxShadow: "0px 4px 30px #E6E6E6",
    marginLeft: isMobile ? "auto" : margin,
    marginRight: isMobile ? "auto" : margin,
    border: "none",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    ...sx,
  }));
  return <StyledTabList {...props}>{children}</StyledTabList>;
};

export const Tab = ({ children, ...props }) => {
  const { isMobile } = useSize();
  const br = props.borderRadius || "12px";
  const StyledTab = styled(BaseTab, {
    shouldForwardProp: (prop) => prop !== "sx",
  })(({ sx, error }) => ({
    fontFamily: "Montserrat",
    color: error ? "#FFF" : sx?.color || "#000", //preto
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: 600,
    backgroundColor: error ? "#d32f2f" : sx?.bg || "transparent",
    width: isMobile ? "100%" : "fit-content",
    padding: isMobile ? "12px 30px" : "8px 16px",
    border: "none",
    borderRadius: "0px",
    display: "flex",
    justifyContent: "center",
    transition: "all 0.3s ease-in-out",
    animation: error ? "pulse 2s infinite" : "none",

    "&:first-child": {
      borderRadius: isMobile ? `${br} ${br} 0px 0px` : `${br} 0px 0px ${br} `,
    },
    "&:last-child": {
      borderRadius: isMobile ? `0px 0px ${br} ${br}` : `0px ${br} ${br} 0px `,
    },
    "&:hover": {
      color: error ? "#FFF" : sx?.hoverColor || "#000", //preto
      backgroundColor: error ? "#d32f2f" : sx?.hoverBg || "#B1B1B1", //cinzento escuro
    },
    [`&.${tabClasses.selected}`]: {
      color: sx?.selectedColor || "#FFF", //branco
      backgroundColor: sx?.selectedBg || "#001A50", //azul admin
      border: sx?.selectBorder || "none",
    },
    "@keyframes pulse": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.02)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    ...sx,
  }));
  return <StyledTab {...props}>{children}</StyledTab>;
};
