import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { AnswerFilters } from "~/presentation/views/Admin/components/CustomToolbar/AnswerFilters";
//functions
//utils
import typeTax from "~/presentation/utils/typeTax";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import PageListHeader from "../components/PageListHeader";
import { adminPerms } from "~/presentation/security/SecurePaths";

const Answer = ({
  answerUseCases,
  itemAnswerUseCases,
  companyUseCases,
  cnaesUseCases,
  termUseCases,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar, showError } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //manter a página da tabela sempre atual
  const [page, setPage] = useState(0);

  //Data
  const [answers, setAnswers] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});

  //Data Select
  const [cnaes, setCnaes] = useState([]);
  const [itemsAnswer, setItemsAnswer] = useState([]);
  const [categories, setCategories] = useState([]);
  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null, isCopy = false) => {
    setOpenForm(true);
    if (isCopy) {
      setFormProps({ ...data, isCopy: true });
    } else {
      setFormProps(data);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async (filters) => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const { data = [] } = await answerUseCases.listAnswersSummary(filters);
      setAnswers(data);
    } catch (error) {
      showError(error, "Erro ao carregar respostas");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "gtAnswerName",
      headerName: "Nome",
      align: "center",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 4,
    },
    {
      field: "typeTax",
      headerName: "Taxa",
      align: "center",
      flex: 1,
      renderCell: (params) => typeTax?.find((item) => item.value === params.value).label,
    },
    {
      field: "isSimple",
      headerName: "Simples Nacional",
      renderCell: (params) => (params.value === true ? "Sim" : "Não"),
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editAnswers]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteAnswers]}
          handleCopy={() => handleOpenForm(params.row, true)}
          copySecurePath={[adminPerms.addAnswers]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo resposta");
      await answerUseCases.deleteAnswer(id);
      setAnswers((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar(error, "Erro ao excluir resposta");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData({ itemContent: "" });
  }, []);

  const fetchTerms = async () => {
    try {
      openBackdrop(true, "Carregando termos de pesquisa");
      const terms = await termUseCases.getAll({ checkPermission: false });
      setTerms(terms);
    } catch (error) {
      setFeedbackSnackbar(error, "Erro ao carregar termos de pesquisa.");
    } finally {
      openBackdrop(false);
    }
  };

  const fetchCNAE = async () => {
    try {
      openBackdrop(true, "Carregando cnaes");
      const { data } = await cnaesUseCases.listCnaes({ checkPermission: false });
      setCnaes(data);
    } catch (error) {
      setFeedbackSnackbar(error, "Erro ao carregar cnaes.");
    } finally {
      openBackdrop(false);
    }
  };

  const fetchItemAnswer = async () => {
    try {
      openBackdrop(true, "Carregando items de resposta");
      const apiTypeAnswers = await itemAnswerUseCases.getAll({ checkPermission: false });
      setItemsAnswer(apiTypeAnswers);
    } catch (error) {
      setFeedbackSnackbar(error, "Erro ao carregar itens de resposta.");
    } finally {
      openBackdrop(false);
    }
  };

  const fetchCategories = async () => {
    try {
      openBackdrop(true, "Carregando categorias");
      const categories = await companyUseCases.getCompanyCategories({ checkPermission: false });
      setCategories(categories);
    } catch (error) {
      setFeedbackSnackbar(error, "Erro ao carregar categorias.");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          fetchTerms={fetchTerms}
          fetchCNAE={fetchCNAE}
          fetchItemAnswer={fetchItemAnswer}
          fetchCategories={fetchCategories}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      ) : (
        <>
          <PageListHeader
            title="Resposta"
            handleAdd={() => handleOpenForm()}
            addSecurePath={[adminPerms.addAnswers]}
          />
          <Box sx={{ margin: 3 }}>
            <Table
              columns={columns}
              data={answers}
              customToolbarExtraComponents={[
                (index) => (
                  <AnswerFilters
                    key={index}
                    onFilter={fetchData}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                  />
                ),
              ]}
              page={page}
              setPage={setPage}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Answer;
