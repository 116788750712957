import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

function Form({ values, onChangeValue, handleSubmit, errors }) {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Dados da Categoria</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 2 }}>
            <Input
              type="text"
              title="Letra"
              name="letter"
              placeholder="Letra QSO"
              maxLength={1}
              onChange={(e) => onChangeValue("letter", e.target.value)}
              value={values?.letter}
              error={errors.letter}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 10 }}>
            <Input
              type="text"
              title="Nome"
              name="name"
              onChange={(e) => onChangeValue("name", e.target.value)}
              value={values?.name}
              error={errors.name}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
}

export default Form;
