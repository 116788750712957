import React, { useState, useContext, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import PaymentTable from "./PaymentTable";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import { EventSend } from "~/presentation/views/Reinf/SendReinf/components/EventSend/EventSend";
import { ApiPaymentRecordUseCases } from "~/data/usecases/paymentRecord/apiPaymentRecord";
import SentTablePayments from "./SentPayments";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";

export const PaymentSection = ({
  certificateData,
  sendFilter,
  isEventClosed,
  handleVerifyEventStatus,
}) => {
  const apiPayment = new ApiPaymentRecordUseCases(makeHttpClient());
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [currentPaymentData, setCurrentPaymentData] = useState([]);
  const [inaptPaymentData, setInaptPaymentData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableType, setTableType] = useState("pagamentos");
  const [openEventSendModal, setOpenEventSendModal] = useState(false);
  //states protocols events
  const [eventsData, setEventsData] = useState([]);
  // Função para buscar os dados de pagamento
  const fetchPaymentData = async () => {
    try {
      const { month, year } = sendFilter;
      const start = new Date(year, month - 1, 1); // Primeiro dia do mês
      const end = new Date(year, month, 0); // Último dia do mês

      const params = {
        start: start.toISOString(),
        end: end.toISOString(),
      };

      const payments = await apiPayment.getPaymentRecord(params);
      if (payments.status === 200) {
        classifyPayments(payments.data);
      }
    } catch (error) {
      console.error("Erro ao buscar dados de pagamentos", error);
    }
  };

  //carrega protocolos 4010
  const fetchEventsData = async () => {
    try {
      const { month, year } = sendFilter;
      const start = new Date(year, month - 1, 1); // Primeiro dia do mês
      const end = new Date(year, month, 0); // Último dia do mês

      const params = {
        start: start.toISOString(),
        end: end.toISOString(),
        reinfEvent: 9,
      };
      const events = await apiReinf.getReinfProtocol(params);
      if (events.status === 200) {
        const eventsData = events.data;
        setEventsData(eventsData);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos eventos, verifique sua conexão",
        type: "error",
      });
    }
  };

  // Função para abrir o modal de envio
  const handleOpenSendModal = () => setOpenEventSendModal(true);
  const handleCloseSendModal = () => setOpenEventSendModal(false);

  useEffect(() => {
    if (sendFilter?.month && sendFilter?.year) {
      fetchPaymentData();
      fetchEventsData();
    }
  }, [sendFilter]);

  //verifica aptidão dos dados
  const classifyPayments = (payments) => {
    const aptos = [];
    const inaptos = [];

    payments.forEach((payment) => {
      if (
        payment.dateRegister &&
        payment.datePayment &&
        payment.totalAmount &&
        payment.incomeNatureId &&
        payment.businessPartnerId
      ) {
        aptos.push(payment); // Se existirem, aptos
      } else {
        inaptos.push(payment); // Se falta, inaptos
      }
    });
    setCurrentPaymentData(aptos);
    setInaptPaymentData(inaptos);
  };

  // Lógica para habilitar o envio
  const handleSend = () => {
    // Verificar se os filtros estão preenchidos corretamente
    if (!sendFilter.month || !sendFilter.year || !sendFilter.event) {
      return alert("Selecione os filtros corretamente.");
    }

    if (isEventClosed) {
      return alert("O período está fechado. Não é possível enviar.");
    }

    handleOpenSendModal(); // Chama a função para abrir o modal
  };

  //callback do filho de edição / atualização
  const refreshDatePay = () => {
    fetchPaymentData();
  };

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid item size={{ xs: 12, sm: 4 }}>
          <RButton
            onClick={() => setTableType("pagamentos")}
            color={tableType === "pagamentos" ? "primary" : "secondary"}
            variant={tableType === "pagamentos" ? "contained" : "outlined"}
            sx={{
              p: 3,
              border: tableType === "pagamentos" ? "1px solid #142F68" : "1px solid #CCCCCC",
            }}
          >
            PAGAMENTOS ({currentPaymentData.length})
          </RButton>
        </Grid>
        <Grid item size={{ xs: 12, sm: 4 }}>
          <RButton
            onClick={() => setTableType("pagamentosInaptos")}
            color={tableType === "pagamentosInaptos" ? "primary" : "secondary"}
            variant={tableType === "pagamentosInaptos" ? "contained" : "outlined"}
            sx={{
              p: 3,
              border: tableType === "pagamentosInaptos" ? "1px solid #142F68" : "1px solid #CCCCCC",
            }}
          >
            PAGAMENTOS INAPTOS ({inaptPaymentData.length})
          </RButton>
        </Grid>
        <Grid item size={{ xs: 12, sm: 4 }}>
          <RButton
            onClick={() => setTableType("pagamentosEnviados")}
            color={tableType === "pagamentosEnviados" ? "primary" : "secondary"}
            variant={tableType === "pagamentosEnviados" ? "contained" : "outlined"}
            sx={{
              p: 3,
              border:
                tableType === "pagamentosEnviados" ? "1px solid #142F68" : "1px solid #CCCCCC",
            }}
          >
            PAGAMENTOS ENVIADOS ({eventsData?.length})
          </RButton>
        </Grid>
      </Grid>

      {/* Tabela de Pagamentos */}
      {tableType === "pagamentosEnviados" ? (
        <SentTablePayments
          sendFilter={sendFilter}
          eventsDataSend={eventsData}
          validPaymentsData={currentPaymentData}
          certificateData={certificateData}
        />
      ) : (
        <PaymentTable
          data={tableType === "pagamentos" ? currentPaymentData : inaptPaymentData} //se não = inaptos
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setData={tableType === "pagamentos" ? setCurrentPaymentData : setInaptPaymentData}
          refreshDatePay={refreshDatePay}
        />
      )}

      {tableType === "pagamentos" && (
        <Box sx={styles.buttonSend}>
          <RButton onClick={handleSend}>Enviar</RButton>
        </Box>
      )}
      {/* Modal para envio */}
      <ModalContainer open={openEventSendModal} onClose={handleCloseSendModal}>
        <EventSend
          data={selectedRows}
          filter={sendFilter}
          certificateData={certificateData}
          closeModal={handleCloseSendModal}
          refreshData={refreshDatePay}
          handleVerifyEventStatus={handleVerifyEventStatus}
        />
      </ModalContainer>
    </>
  );
};

const styles = {
  buttonSend: {
    width: "min(250px, 100%)",
    paddingInline: 3,
    paddingBottom: 2,
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
  },
};
