import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiValueVariableUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll() {
    if (!hasPermission([adminPerms.seeValueVariables])) {
      throw new Error(securityErrorMessage("visualizar variáveis de valor"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/systemvariable`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getById(id) {
    if (!hasPermission([adminPerms.seeValueVariables])) {
      throw new Error(securityErrorMessage("visualizar variáveis de valor"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/systemvariable/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async create(data) {
    if (!hasPermission([adminPerms.addValueVariables])) {
      throw new Error(securityErrorMessage("adicionar variáveis de valor"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/systemvariable`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getByCode(code) {
    // let response = {
    //   data: fakeValueVariablesList.find(
    //     (vv) =>
    //       vv.code.replaceAll(" ", "").toLowerCase() ==
    //       code.replaceAll(" ", "").toLowerCase(),
    //   ),
    // };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/systemvariable/${code}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
    // if (response.data)
    //   return `R$ ${response.data.value.toFixed(2).replace(".", ",")}`;
    // else return;
  }

  async update(data) {
    if (!hasPermission([adminPerms.editValueVariables])) {
      throw new Error(securityErrorMessage("editar variáveis de valor"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/systemvariable`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async delete(id) {
    if (!hasPermission([adminPerms.deleteValueVariables])) {
      throw new Error(securityErrorMessage("excluir variáveis de valor"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/systemvariable/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
