import React, { useState, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { Box, Typography as Text } from "@mui/material";
import { EventDetailsModal } from "./EventDetailsModal";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Close } from "@mui/icons-material";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import useCreateLotes from "../../../Hooks/useCreateLotes";
import Grid from "@mui/material/Grid2";

export const SendR4020 = ({
  nfseDate,
  DigitalCertificate,
  UserCompany,
  CompanyUserId,
  EventSend,
  competence,
  selectedYear,
  selectedMonth,
  closeModal,
  refreshData,
  operation,
  receiptNumber,
}) => {
  const apiSendReinf = new ApiReinfUseCases(makeHttpClient());
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSucess } = useContext(SnackbarContext);
  const { getLotes } = useCreateLotes();
  const [modalOpen, setModalOpen] = useState(false);
  const [responseDetails, setResponseDetails] = useState(null);
  const [eventData, setEventData] = useState({
    companyId: CompanyUserId,
    digitalCertId: DigitalCertificate?.id,
    isProduction: false, // por padrão inicia em
    operation: operation || 1, //por padrão inclusão deve ser a operação
    receiptNumber: receiptNumber || "",
    calculationDate: competence,
    docs: nfseDate.map((nfse) => ({
      id: nfse.id,
      serviceProviderCnpj: nfse.businessPartnerCNPJ,
    })),
  });

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : "";
    setEventData((prev) => ({ ...prev, [name]: value }));
  };

  const allLotes = getLotes(nfseDate, "businessPartnerCNPJ");

  const handleSubmit = async () => {
    let allSuccessful = true;
    let responseDetails = [];

    openBackdrop(true, "Enviando dados do Evento R-4020");

    for (const lote of allLotes) {
      const updatedEventData = {
        ...eventData,
        docs: lote.map((nfse) => ({
          id: nfse.id,
          serviceProviderCnpj: nfse.businessPartnerCNPJ,
        })),
      };

      try {
        const response = await apiSendReinf.sendEvent(updatedEventData, "r4020");
        responseDetails = [...responseDetails, ...response];
      } catch (error) {
        allSuccessful = false;
        showError(error, "Erro ao enviar evento 4020");
      }
    }

    setResponseDetails([...responseDetails]);

    if (allSuccessful) {
      showSucess("Evento registrado com sucesso");
    }

    if (responseDetails.length > 0) {
      setModalOpen(true);
    }

    openBackdrop(false);
  };

  const isOperationoptions = [
    { value: 1, label: "Inclusão" },
    { value: 2, label: "Alteração" },
    { value: 3, label: "Exclusão" },
  ];

  const isProductionoptions = [
    { value: false, label: "Produção Restrita" },
    { value: true, label: "Produção" },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid size={{ xs: 6, sm: 11 }}>
          <Text style={styles.Title}>DADOS DO EVENTO R-4020</Text>
        </Grid>
        <Grid
          size={{ xs: 6, sm: 1 }}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={closeModal} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Text>{EventSend}</Text>
          <Input
            type="text"
            title="Estabelecimento"
            name="companyName"
            value={UserCompany?.name || ""}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Certificado Digital"
            name="digitalCertId"
            value={DigitalCertificate?.name}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Tipo de ambiente"
            name="isProduction"
            options={isProductionoptions}
            onChange={(option) => handleSelectChange(option, { name: "isProduction" })}
            value={isProductionoptions.find((option) => option.value === eventData.isProduction)}
          />
        </Grid>
        {eventData.operation !== 1 && (
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              title="Tipo de Operação"
              name="operation"
              options={isOperationoptions}
              // onChange={(option) => handleSelectChange(option, { name: "operation" })}
              value={isOperationoptions.find((option) => option.value === eventData.operation)}
              disabled
            />
          </Grid>
        )}
        {eventData.receiptNumber && (
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="text"
              title="Número do Recibo"
              name="receiptNumber"
              value={eventData.receiptNumber}
              // onChange={(e) => handleChange(e.target.name, e.target.value)}
              disabled
            />
          </Grid>
        )}
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Data da competência"
            value={`${selectedMonth}/${selectedYear}`}
          />
        </Grid>

        <Box sx={{ paddingInline: 3, paddingBlock: 3 }}>
          <Text style={styles.subTitle}>
            Foram selecionados {nfseDate?.length} documento(s) fiscais para envio.
          </Text>
        </Box>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={closeModal}>
          Cancelar
        </RButton>
        <RButton sx={{ width: "150px" }} variant="contained" color="primary" onClick={handleSubmit}>
          Enviar R-4020
        </RButton>
      </Box>
      {responseDetails && (
        <ModalContainer scroll={true} open={modalOpen}>
          <EventDetailsModal
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
              closeModal();
              refreshData();
            }}
            eventDetails={responseDetails}
          />
        </ModalContainer>
      )}
    </>
  );
};

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
  subTitle: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
};
