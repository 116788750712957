import {useState, useEffect} from "react";
import useMobile from "~/presentation/hooks/useMobile";

export default function usePageTopOffset(scrollableElement = window) {
  const [mobile] = useMobile(960);
  const [pageTopOffset, setPageTopOffset] = useState(false);

  useEffect(() => {
    const refToCheckScroll = mobile ? scrollableElement.current : window;

    const handleScroll = () => {
      const {scrollTop, scrollY} = refToCheckScroll;
      setPageTopOffset(scrollTop || scrollY);
    };

    refToCheckScroll.addEventListener("scroll", handleScroll);
    return () => refToCheckScroll.removeEventListener("scroll", handleScroll);
  }, []);

  return [pageTopOffset];
}
