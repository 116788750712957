export const authUrl = "Authentication/authenticate";
import { apiURL } from "../../usecases/api";
export class ApiAuthentication {
  constructor(httpClient, cache) {
    this.httpClient = httpClient;
    this.cache = cache;
  }

  async auth({ email, password }) {
    try {
      const { data } = await this.httpClient.request({
        url: `${apiURL}/Client/${authUrl}`,
        data: {
          email,
          password,
        },
        method: "POST",
      });
      const { id, name, role, companyId, token, expires, permissions, profileId, companyInfor } =
        data;
      this.cache.setItem("GT_token", token);
      this.cache.setItem("GT_layoutQSO", JSON.stringify(companyInfor.letter));
      this.cache.setItem(
        "GT_user",
        JSON.stringify({ id, name, role, companyId, expires, permissions, profileId, companyInfor })
      );
      return data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      } else {
        throw new Error(error.message);
      }
    }
  }

  async recoverPassword(email) {
    const emailJson = JSON.stringify(email);
    const response = await this.httpClient.request({
      url: `${apiURL}/User/PasswordRecovery`,
      data: emailJson,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  }

  async logout() {
    this.cache.removeItem("GT_token");
    this.cache.removeItem("GT_user");
    this.cache.removeItem("GT_layoutQSO");
  }

  isLogged() {
    const token = localStorage.getItem("GT_token");
    const user = localStorage.getItem("GT_user");
    if (user && token) return true;
    return false;
  }
}
