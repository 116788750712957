import React, { useContext, useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import { AddNewCertificate } from "~/presentation/views/Reinf/DigitalCertificate/New/index";
import { Tooltip, Switch } from "@mui/material";
import { EditCertificate } from "./Edit/EditCertificate";
import TableActions from "~/presentation/components/TableActions";
import { formatDateToBrazilian } from "../Utils/utilsDate";
import Table from "../components/Table/Table";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { hasPermission } from "~/presentation/security/SecurityPath";

export const ViewDigitalCertificate = () => {
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //todos os certificados
  const [certInfo, setCertInfo] = useState([]);

  const handleCreate = () => {
    openDialogBox({
      customComponent: () => (
        <AddNewCertificate handleClose={closeDialog} refreshData={fetchCertInfo} />
      ),
      maxWidth: "md",
    });
  };

  const handleEdit = (editCertInfo) => {
    openDialogBox({
      customComponent: () => (
        <EditCertificate
          data={editCertInfo}
          handleClose={closeDialog}
          refreshData={fetchCertInfo}
          setCertInfo={setCertInfo}
        />
      ),
      maxWidth: "md",
    });
  };

  const fetchCertInfo = async () => {
    try {
      openBackdrop(true, "Carregando dados");
      const certResult = await apiDigitalCert.getDigitalCerts();
      setCertInfo(certResult);
      if (certResult.length === 1) {
        //se um certificado no array, então esse é marcado isDefault
        const singleCert = certResult[0];
        if (!singleCert.isDefault) {
          singleCert.isDefault = true;
          await apiDigitalCert.updateDigitalCert(singleCert);
          showSuccess("Certificado único configurado como padrão");
        }
      }
    } catch (error) {
      showError(error, "Erro ao buscar certificados");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchCertInfo();
  }, []);

  const handleChangeSwitch = async (certId, isDefault) => {
    const updatedCert = certInfo.find((cert) => cert.id === certId);
    if (!updatedCert) {
      showError({}, "Certificado não encontrado!");
      return;
    }

    const updatedCertInfo = certInfo.map((cert) =>
      cert.id === certId ? { ...cert, isDefault: isDefault } : cert
    );
    setCertInfo(updatedCertInfo);
    updatedCert.isDefault = isDefault;

    try {
      await apiDigitalCert.updateDigitalCert(updatedCert);
      showError(isDefault ? "Certificado Padrão configurado" : "Certificado Padrão retirado");
      await fetchCertInfo();
    } catch (error) {
      showError(error, "Erro ao atualizar certificado");
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      align: "center",
    },
    { field: "ownerName", headerName: "Proprietário", align: "center" },

    {
      field: "expirationDate",
      headerName: "Data de validade",
      align: "center",
      renderCell: ({ value }) => formatDateToBrazilian(value) || "-",
    },
    {
      field: "isDefault",
      headerName: "Certificado Padrão",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: ({ row, value }) => {
        const hasEditPerm = hasPermission([reinfPerms.editCertificate]);
        if (!hasEditPerm) return;
        return (
          <Tooltip title="Defina como um certificado principal para as ações do sistema.">
            <Switch
              disabled={certInfo?.length === 1 || value}
              checked={value}
              onChange={(e) => handleChangeSwitch(row.id, e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          editSecurePath={[reinfPerms.editCertificate]}
          handleEdit={() => handleEdit(row)}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderSectionPage
        title="Certificados digitais"
        handleAdd={handleCreate}
        addSecurePath={[reinfPerms.addCertificate]}
      />
      <Table data={certInfo} columns={columns} />
    </>
  );
};
