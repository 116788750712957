import React, { useEffect, useState } from "react";
import { Box, Button, Popover } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";

export const CompanyFilter = ({ onFilter, currentCompany, companyOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [companyId, setCompanyId] = useState(1);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  useEffect(() => {
    if (currentCompany) {
      setCompanyId(currentCompany);
    }
  }, [currentCompany]);

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleOpen}
        sx={{
          color: "#001A50",
          gap: "0.25rem",
          fontSize: "0.8125rem",
        }}
      >
        <FilterAlt sx={{ width: "1.3rem" }} titleAccess={"Filtros"} />
        Filtro Por Empresa
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            padding: 2,
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            width: "380px",
          }}
        >
          <h5>Procure por empresa</h5>
          <Box sx={{ width: "100%" }}>
            <Input
              type="select"
              title="Empresa"
              placeholder="Selecione a empresa"
              value={companyOptions?.find((i) => i.id === companyId)}
              onChange={(option) => setCompanyId(option.id)}
              options={companyOptions}
              maxMenuHeight={120}
            />
          </Box>
          <ButtonCommon
            style={{
              borderRadius: "12px",
              width: "100px",
              height: 40,
              alignSelf: "end",
              marginTop: 10,
            }}
            onClick={() => onFilter(companyId)}
          >
            Filtrar
          </ButtonCommon>
        </Box>
      </Popover>
    </>
  );
};
