import React, { useContext } from "react";
import Input from "~/presentation/components/Common/Input";
import { Box, Tooltip, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";
import CreateEditProfiles from "../../../UserProfiles/CreateEditProfiles/CreateEditProfiles";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { DialogContext } from "~/presentation/providers/DialogProvider";

const UserForm = ({
  handleSubmit,
  editing,
  companies,
  userProfiles,
  onChangeValue,
  values,
  errors,
  getProfiles,
}) => {
  const { id: userId, companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const { closeSecondDialog, openSecondDialog } = useContext(DialogContext);

  const handleUserProfileModal = () => {
    openSecondDialog({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <CreateEditProfiles
          data={{
            id: 0,
            name: "",
            isInternalUse: false,
            companyId: companyId,
            permission: {
              admins: null,
              clients: null,
              reinfs: null,
            },
          }}
          onCloseForm={closeSecondDialog}
          refreshData={getProfiles}
        />
      ),
    });
  };
  //Se é o usuário logado BLOQUEAMOS escolha de perfil
  const isLoggedUser = values.id === userId;

  return (
    <Box sx={formStyles.container}>
      <Grid container spacing={1}>
        <Grid size={12}>
          <Typography sx={formStyles.title}>Dados do Usuário</Typography>
        </Grid>

        <Grid size={12}>
          <Input
            type="select"
            title="Empresa"
            name="companyId"
            placeholder={
              companies && companies.length > 0
                ? "Selecione a empresa"
                : "Carregando dados das empresas..."
            }
            value={companies.find((item) => item.id === values?.companyId)}
            onChange={(option) => onChangeValue("companyId", option.id)}
            options={companies}
            disabled={editing}
            error={errors.companyId}
          />
        </Grid>
        {editing && (
          <Typography sx={{fontSize: 12}}>
            * Para garantir a integridade dos dados no sistema, o campo EMPRESA de um usuário já
            cadastrado não pode ser editado.
          </Typography>
        )}
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Nome"
            name="name"
            placeholder="Insira o nome..."
            value={values?.name}
            onChange={(e) => onChangeValue("name", e.target.value)}
            error={errors.name}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Email"
            name="email"
            placeholder="Insira o email..."
            value={values?.email}
            onChange={(e) => onChangeValue("email", e.target.value)}
            error={errors.email}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="mask"
            mask="(00) 0000-0000"
            title="Telefone"
            name="phone"
            placeholder="Insira o telefone..."
            value={values?.phone}
            onChange={(e) => onChangeValue("phone", e)}
            error={errors.phone}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="mask"
            mask="(00) 00000-0000"
            title="Celular"
            name="mobile_phone"
            placeholder="Insira o celular..."
            value={values?.mobile_phone}
            onChange={(e) => onChangeValue("mobile_phone", e)}
            error={errors.mobile_phone}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="password"
            title="Senha"
            name="password"
            placeholder="Insira a senha..."
            value={values?.password}
            onChange={(e) => onChangeValue("password", e.target.value)}
            error={errors.password}
            disabled={editing}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="password"
            title="Confirmar a Senha"
            name="confirm_password"
            placeholder="Confirme a senha..."
            value={values?.confirm_password}
            onChange={(e) => onChangeValue("confirm_password", e.target.value)}
            error={errors.confirm_password}
            disabled={editing}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Perfil de Usuário"
            name="userProfileId"
            placeholder={
              userProfiles && userProfiles.length > 0
                ? "Selecione o perfil..."
                : "Carregando perfis..."
            }
            value={userProfiles.find((item) => item.value === values.userProfileId)}
            onChange={(option) => onChangeValue("userProfileId", option.value)}
            options={userProfiles}
            error={errors.userProfileId}
            height={120}
            icon={{
              title: "Criar uma novo perfil",
              func: handleUserProfileModal,
              securePaths: [adminPerms.addUserProfile],
            }}
            disabled={isLoggedUser}
          />
        </Grid>
        <Box sx={formStyles.buttonContainer}>
          <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)} disabled={!values?.companyId}>
            Salvar
          </ButtonCommon>
        </Box>
      </Grid>
    </Box>
  );
};

export default UserForm;
