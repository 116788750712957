import React, { useState } from "react";
import { TableNfse } from "../Table";
import { useNavigate } from "react-router";
import { HeaderSectionPage } from "../../components/HeaderSectionPage";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { ModalImport } from "../../components/ModalImport/ModalImport";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { reinfPerms } from "~/presentation/security/SecurePaths";

export const ViewHomeNf = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setRefreshTable((prev) => !prev);
  };

  return (
    <>
      <HeaderSectionPage
        title="Painel de Documentos Fiscais"
        pageReturn="/reinf"
        buttonPrimary={
          <SecurityPath
            securePaths={[
              reinfPerms.importXml,
              reinfPerms.importSpreadsheet,
              reinfPerms.importSiafi,
            ]}
          >
            <RButton
              startIcon={<FolderCopyOutlinedIcon />}
              onClick={handleOpen}
              sx={{
                borderRadius: "20px",
                height: "40px",
                fontFamily: "Montserrat",
                width: { xs: "300px", sm: "260px" },
              }}
            >
              Importar arquivos
            </RButton>
          </SecurityPath>
        }
        buttonSecond={
          <SecurityPath securePaths={[reinfPerms.addFiscalDocument]}>
            <RButton
              onClick={() => navigate("/reinf/criar-doc-fiscal")}
              sx={{
                borderRadius: "20px",
                height: "40px",
                fontFamily: "Montserrat",
                width: "300px",
              }}
            >
              Adicionar Doc. Manualmente
            </RButton>
          </SecurityPath>
        }
      />
      <ModalContainer scroll={true} open={modalOpen} onClose={handleClose}>
        <ModalImport handleClose={handleClose} />
      </ModalContainer>

      <SecurityPath securePaths={[reinfPerms.seeFiscalDocument]}>
        <TableNfse importRefresh={refreshTable} />
      </SecurityPath>
    </>
  );
};
