import React from "react";
import { Box, Typography } from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

export const SecurityPermissionForm = ({
  values,
  handleSubmit,
  onChangeValue,
  errors,
  isCreate,
}) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Permissão de Segurança</Typography>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Input
              type="text"
              name="permission"
              title="Permissão"
              value={values?.permission}
              onChange={(e) => onChangeValue("permission", e.target.value)}
              error={errors.permission}
              disabled={!isCreate}
            />
          </Grid>
          <Grid size={12}>
            <Input
              type="text"
              name="description"
              title="Descrição"
              value={values?.description}
              onChange={(e) => onChangeValue("description", e.target.value)}
              error={errors.description}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};
