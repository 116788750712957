import React, { useContext, useEffect, useState } from "react";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import ModalContainer from "../components/ModalContainer/ModalContainer";
import TableActions from "~/presentation/components/TableActions";
import Table from "../components/Table/Table";
import { formatCpfCnpj } from "../Utils/utilsReinf";

function BusinessPartner() {
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { openDialogBox } = useContext(DialogContext);

  // Table Data
  const [partnersData, setPartnersData] = useState([]);

  // Modal
  const [open, setOpen] = useState(false);
  const [currentPartnerDataToEdit, setCurrentPartnerDataToEdit] = useState(null);

  const handleOpenCreate = () => {
    setCurrentPartnerDataToEdit([]);
    setOpen(true);
  };

  const handleOpenEdit = (item) => {
    setCurrentPartnerDataToEdit(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    openDialogBox({
      message: "Deseja excluir este Parceiro de Negócio?",
      callback: () => {
        apiBusinessPartner
          .deleteBusinessPartner(id)
          .then(() => {
            //eliminar da tabela
            setPartnersData((prev) => prev.filter((partner) => partner.id !== id));
            showSuccess("Parceiro de Negócio excluído com sucesso");
          })
          .catch((error) => {
            showError(error, `Erro ao excluir Parceiro de Negócio`);
          });
      },
    });
  };

  useEffect(() => {
    setDataIntoState();
  }, []);

  const getData = async () => {
    const events = await apiBusinessPartner.listBusinessPartner();
    if (events) {
      return events;
    } else {
      showError({}, "Erro no retorno dos eventos, verifique sua conexão");
    }
  };

  const setDataIntoState = async () => {
    try {
      openBackdrop(true, "Carregando informação");
      const response = await getData();
      const data = response?.reverse(); //para o mais recente ficar em cima na tabela
      setPartnersData(data);
    } catch (error) {
      showError(error, "Erro no retorno dos dados");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "cpfCnpj",
      headerName: "CPF/CNPJ do contratado",
      align: "center",
      renderCell: ({ value }) => formatCpfCnpj(value) || "-",
    },
    { field: "legalName", headerName: "Nome Empresarial" },

    {
      field: "tradeName",
      headerName: "Nome Fantasia",
    },
    {
      field: "ie",
      headerName: "Inscrição Estadual",
      align: "center",
      renderCell: ({ value }) => value || "-",
      sortable: false,
    },
    {
      field: "im",
      headerName: "Inscrição Municipal",
      align: "center",
      renderCell: ({ value }) => value || "-",
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenEdit(params.row)}
          handleDelete={() => handleDelete(params.row.id)}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderSectionPage
        title="Parceiros de Negócio"
        handleAdd={handleOpenCreate}
        titleAdd="Cadastrar Parceiro"
      />
      <Table data={partnersData} columns={columns} rowPadding="4px" />
      <ModalContainer open={open} onClose={handleClose}>
        <PartnerForm
          onClose={handleClose}
          partnerFormData={currentPartnerDataToEdit}
          refreshData={setDataIntoState}
        />
      </ModalContainer>
    </>
  );
}

export default BusinessPartner;
