import React, { useState, useEffect, useContext, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
// functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { CompanyFilter } from "./CompanyFilter/CompanyFilter";

const User = ({ userUseCases, companyUseCases, companyIdFromForms }) => {
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const [currentCompany, setCurrentCompany] = useState(companyIdFromForms ?? user?.companyId);

  //Data
  const [users, setUsers] = useState([]);

  //Select
  const [companies, setCompanies] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      customComponent: () => (
        <RegisterEdit
          data={data}
          onCloseForm={closeDialog}
          refreshData={fetchData}
          userUseCases={userUseCases}
          companies={companies}
          fetchCompanys={fetchCompanys}
        />
      ),
    });
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 4 },
    { field: "email", headerName: "Email", flex: 4 },
    {
      field: "userProfile",
      headerName: "Perfil de usuário",
      flex: 2,
      align: "center",
    },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: (params) => handleActions(params.row),
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
    },
  ];

  const fetchData = async (companyId) => {
    openBackdrop(true, `Carregando usuários da empresa`);
    try {
      setCurrentCompany(companyId);
      const users = await userUseCases.getUsers({ checkPermission: true, companyId: companyId });
      setUsers(users);
    } catch (error) {
      showError(error, "Erro ao carregar usuários");
    } finally {
      openBackdrop(false);
    }
  };

  const handleActions = (user) => {
    return (
      <TableActions
        handleDelete={() => deleteDialog(user.id)}
        deleteSecurePath={[adminPerms.deleteUsers]}
        handleEdit={() => handleOpenForm(user)}
        editSecurePath={[adminPerms.editUsers]}
      />
    );
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este usuário?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo usuário");
      await userUseCases.deleteUser(id);
      showSuccess("Usuário excluído!");
      setUsers((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir usuário.");
    } finally {
      openBackdrop(false);
    }
  };

  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) return;
    fetchData(currentCompany);
    if (!companyIdFromForms) {
      fetchCompanys();
    }
    firstRender.current = false;
  }, []);

  const fetchCompanys = async () => {
    try {
      openBackdrop(true, "Carregando dados do formulário");
      const apiCompanies = await companyUseCases.getCompanies({
        isSummary: false,
        checkPermission: false,
      });
      apiCompanies.forEach((company) => {
        company.value = company.id;
        company.label = company.name;
      });
      setCompanies(apiCompanies);
    } catch (error) {
      showError(error, "Erro ao carregar empresas");
      setCompanies([]);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        bg="white"
        title="Usuários"
        titleAccess="Novo usuário"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addUsers]}
      />
      <Box sx={{ margin: companyIdFromForms ? 0 : 3 }}>
        <Table
          columns={columns}
          data={users}
          customToolbarExtraComponents={[
            (index) => {
              if (companyIdFromForms) return;
              return (
                <CompanyFilter
                  onFilter={fetchData}
                  currentCompany={currentCompany}
                  companyOptions={companies}
                />
              );
            },
          ]}
        />
      </Box>
    </>
  );
};

export default User;
