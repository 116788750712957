import fakeCitiesList from "./fake-cities-list.json";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
import { hasPermission } from "~/presentation/security/SecurityPath";

export class ApiCityUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll({ isNotPD = true, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeCities])) {
        throw new Error(securityErrorMessage("visualizar municípios"));
      }
    }

    let response = {
      data: fakeCitiesList,
    };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/city?isNotPD=${isNotPD}`,
        method: "GET",
      });
    } catch (error) {
      throw new Error(error);
    }
    return response.data;
  }

  async getCityById({ id, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeCities])) {
        throw new Error(securityErrorMessage("visualizar municípios"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/city/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateCity(data) {
    if (!hasPermission([adminPerms.editCities])) {
      throw new Error(securityErrorMessage("editar municípios"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/city`,
        method: "PUT",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}
