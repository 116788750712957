import React, { useState, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiPaymentRecordUseCases } from "~/data/usecases/paymentRecord/apiPaymentRecord";
import { Container } from "@mui/material";
import { Payments } from "./sections/Payments";

export const CreatePayPerson = ({
  partners,
  getPartners,
  refreshData,
  handleReturn,
  incomeNatureOptions,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const apiPayRecord = new ApiPaymentRecordUseCases(makeHttpClient());
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

  const [payData, setPayData] = useState({
    id: 0,
    dateRegister: new Date(),
    datePayment: new Date(),
    competence: new Date(),
    is13: false,
    totalAmount: 0,
    taxedAmount: 0,
    amountIR: 0,
    isRRA: false,
    fciScp: 0,
    cnpjFciScp: "",
    percentSCP: 0,
    isJudicialDecision: false,
    countryCode: "",
    observation: "",
    businessPartnerId: 0,
    incomeNatureId: 0,
    companyId: companyId,

    //states valid tabs is not submit
  });

  const handleSubmit = async () => {
    openBackdrop(true, "Cadastrando Informações do pagamento...");
    try {
      await apiPayRecord.createPaymentRecord(payData);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Pagamento cadastrado com sucesso",
        type: "success",
      });
      handleReturn();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar pagamento.",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <Container maxWidth={false} sx={{ paddingBlock: 3, paddingInline: 3 }}>
        <Payments
          partners={partners}
          getPartners={getPartners}
          refreshData={refreshData}
          handleReturn={handleReturn}
          incomeNatureOptions={incomeNatureOptions}
          payData={payData}
          setPayData={setPayData}
          handleSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};
