import React from "react";
import { Box, CircularProgress, Skeleton } from "@mui/material";

export const LoadingTable = () => (
  <Skeleton
    width="100%"
    height="600px"
    variant="rounded"
    sx={{ marginBottom: 2, borderRadius: "8px" }}
  />
);
