import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiPaymentRecordUseCases } from "~/data/usecases/paymentRecord/apiPaymentRecord";
import { Container } from "@mui/material";
import { Payments } from "./sections/Payments";

export const EditPaymentPerson = ({ payIdData, partners, getPartners, incomeNatureOptions, refreshDatePay, disabledEdit }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const apiPayRecord = new ApiPaymentRecordUseCases(makeHttpClient());
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

  const [payData, setPayData] = useState({
    id: 0,
    dateRegister: new Date(),
    datePayment: new Date(),
    competence: new Date(),
    is13: false,
    totalAmount: 0,
    taxedAmount: 0,
    amountIR: 0,
    isRRA: false,
    fciScp: 0,
    cnpjFciScp: "",
    percentSCP: 0,
    isJudicialDecision: false,
    countryCode: "",
    observation: "",
    businessPartnerId: 0,
    incomeNatureId: 0,
    companyId: companyId,
  });

  // useEffect para inicializar payData com os valores de payIdData
  useEffect(() => {
    if (payIdData) {
      setPayData((prevData) => ({
        ...prevData,
        ...payIdData, // Sobrescreve os valores existentes com os valores de payIdData
      }));
    }
  }, [payIdData]);

  const handleSubmit = async () => {
    openBackdrop(true, "Cadastrando Informações do pagamento...");
    try {
      await apiPayRecord.updatePaymentRecord(payData);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Pagamento cadastrado com sucesso",
        type: "success",
      });
      if (refreshDatePay) {
        refreshDatePay(); // callback function atualiza array de payments
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar pagamento.",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Payments
          partners={partners}
          incomeNatureOptions={incomeNatureOptions}
          payData={payData} //dados do pagamento collapse
          getPartners={getPartners}
          setPayData={setPayData}
          handleSubmit={handleSubmit}
          disabledEdit={disabledEdit}
        />
      </Container>
    </>
  );
};
