import * as Yup from "yup";
import { validarEmail } from "~/presentation/functions/emailValidator";
import removeMask from "~/presentation/functions/removeMask";

const validation = {
  companyId: Yup.string().required("Campo Obrigatório!"),
  name: Yup.string().required("Campo Obrigatório!"),
  userProfileId: Yup.number().required("Campo Obrigatório!").typeError("Campo Obrigatório!"),
  email: Yup.string()
    .test("email", "Email inválido!", (value) => validarEmail(value))
    .required("Campo Obrigatório!"),

  phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(10, "Digite um número válido")
    .required("Campo Obrigatório!"),
  mobile_phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(11, "Digite um número válido")
    .required("Campo Obrigatório!"),
  role: Yup.string(),
};

export const validationCreate = Yup.object({
  ...validation,
  password: Yup.string()
    .required("Campo Obrigatório!")
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/,
      "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas devem ser iguais.")
    .required("Campo Obrigatório!"),
});

export const validationEdit = Yup.object({
  ...validation,
});
