import * as Yup from "yup";

export default Yup.object({
  permission: Yup.string()
    .min(3, "Digite uma permissão válida!")
    .required("Campo obrigatório!")
    .matches(
      /^(?![\\/])([^\\/]+\\)+[^\\/]+$/,
      'Permissão deve seguir o padrão "modulo\\item-pai\\item-filho\\ação"'
    ),
  description: Yup.string()
    .min(3, "Digite uma descrição válida!")
    .required("Campo obrigatório!"),
});
