import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Form from "./Form";
import PageListHeader from "../../components/PageListHeader";
import validationSchema from "./Form/validationSchema";

function CreateEdit({ data, onCloseForm, refreshData, valueVariableUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const isCreate = !data;

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    code: "",
    value: "",
    name: "",
    isHtml: true,
    isPopup: true,
  });

  useEffect(() => {
    if (data?.id) {
      setValues(data);
    }
  }, [data]);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} variável de valor`);
    try {
      if (isCreate) {
        await valueVariableUseCases.create(values);
      } else {
        await valueVariableUseCases.update(values);
      }
      showSuccess(`Variável de valor ${isCreate ? "criada" : "editada"}!`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "criar" : "editar"} variável`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Variável de Valor`}
        handleBack={onCloseForm}
        titleBack="Voltar para Variáveis de Valor"
      />
      {values && (
        <Form
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
        />
      )}
    </>
  );
}

export default CreateEdit;
