import { apiURL } from "../../usecases/api";
export class ApiTaxClassification {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getTaxClassification() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/TaxClassification`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
