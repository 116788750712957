import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { Box, Checkbox, FormControlLabel, Typography as Text } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Close } from "@mui/icons-material";
import { EventDetailsModal } from "../../../../SendReinf/components/EventSend/EventDetailsModal";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import DetailedError from "~/presentation/components/DetailedError";

export const SendR1000 = ({
  closeModal,
  operationNumber,
  ItemCompanyId,
  callBackFnc,
  refreshData,
}) => {
  const apiSendReinf = new ApiReinfUseCases(makeHttpClient());
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { openBackdrop } = useContext(BackdropContext);
  const { showSuccess, showError } = useContext(SnackbarContext);

  //dados empresa
  const { companyId, companyInfor } = JSON.parse(localStorage.getItem("GT_user"));

  //array de certificados
  const [certificate, setCertificate] = useState([]);

  //modal de resposta envio R1000
  const [modalOpen, setModalOpen] = useState(false);
  const [responseDetails, setResponseDetails] = useState(null);

  //messagem de erro detalhada
  const [detailedError, setDetailedError] = useState(null);

  //dados params para envio
  const [eventData, setEventData] = useState({
    companyId: companyId,
    digitalCertId: "",
    isProduction: false, // por padrão inicia em false
    operation: operationNumber,
    itemId: ItemCompanyId,
    isClean: false,
  });

  const TitleText =
    operationNumber === 1
      ? "INCLUSÃO"
      : operationNumber === 2
        ? "ALTERAÇÃO"
        : operationNumber === 3
          ? "EXCLUSÃO"
          : "";

  const getCertificates = async () => {
    openBackdrop(true, "Carregando dados");
    try {
      const CertsData = await apiDigitalCert.getDigitalCerts();
      if (CertsData) {
        setCertificate(CertsData);
        const defaultCert = CertsData.find((cert) => cert.isDefault);
        const cert = defaultCert.id || CertsData[0].id;
        handleOnChange("digitalCertId", cert);
      }
    } catch (error) {
      showError(error, `Erro ao carregar certificados`);
    }
    openBackdrop(false);
  };

  //1º render
  useEffect(() => {
    getCertificates();
  }, []);

  const handleOnChange = (field, value) => {
    setEventData((prev) => ({
      ...prev,
      [field]: value,
      // Se isProduction for true, isClean será forçado a false
      ...(field === "isProduction" && value === true ? { isClean: false } : {}),
    }));
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Enviando dados do Evento");
    try {
      const response = await apiSendReinf.sendEvent(eventData, "r1000");
      setResponseDetails(response);
      if (response) {
        setModalOpen(true);
      }
      showSuccess("Evento registrado com sucesso");
      if (callBackFnc && response.codeResponse === 2) {
        callBackFnc(ItemCompanyId);
      }
    } catch (error) {
      setDetailedError(error.data);
      showError(error, `Erro ao enviar evento`);
    } finally {
      openBackdrop(false);
      refreshData();
    }
  };

  const isProductionoptions = [
    { value: false, label: "Produção Restrita" },
    { value: true, label: "Produção" },
  ];

  return (
    <Box sx={styles.content}>
      <Grid container spacing={1}>
        {detailedError ? (
          <Grid size={12}>
            <DetailedError errorContent={detailedError} />
          </Grid>
        ) : (
          <></>
        )}
        <Grid size={{ xs: 6, sm: 11 }}>
          <Text style={styles.Title}>DADOS DO EVENTO {TitleText} R-1000</Text>
        </Grid>
        <Grid
          size={{ xs: 6, sm: 1 }}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={closeModal} />
        </Grid>
        <Grid size={12}>
          <Input
            type="text"
            title="Estabelecimento"
            name="companyName"
            value={companyInfor?.name || ""}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Selecionar Certificado"
            name="digitalCertId"
            placeholder="Selecione um certificado"
            options={certificate}
            getOptionLabel={(option) => option.name}
            onChange={(option) => handleOnChange("digitalCertId", option.id)}
            value={certificate.find((cert) => cert.id === eventData?.digitalCertId)}
            error={!eventData?.digitalCertId && "Campo Obrigatório"}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Tipo de Ambiente"
            name="isProduction"
            options={isProductionoptions}
            onChange={(option) => handleOnChange("isProduction", option.value)}
            value={isProductionoptions.find((option) => option.value === eventData?.isProduction)}
          />
        </Grid>
        {eventData?.isProduction === false && (
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={eventData?.isClean || false}
                  onChange={(e) => handleOnChange("isClean", e.target.checked)}
                />
              }
              label="Limpar base de dados"
            />
          </Grid>
        )}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={closeModal}>
          Cancelar
        </RButton>
        <RButton
          sx={{ width: "150px" }}
          variant="contained"
          color="primary"
          disabled={!eventData?.companyId || !eventData?.digitalCertId}
          onClick={handleSubmit}
        >
          Enviar R-1000
        </RButton>
      </Box>
      <ModalContainer open={modalOpen}>
        <EventDetailsModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            closeModal();
            refreshData();
          }}
          eventDetails={responseDetails}
        />
      </ModalContainer>
    </Box>
  );
};

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  content: {
    p: 4,
  },
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    width: "90%",
    maxHeight: "80%",
    borderRadius: "18px",
    bgcolor: "#E8E8E8",
    boxShadow: 24,
    overflow: "auto",
  },
};
