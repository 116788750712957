import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";

export class ApiAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAnswersGT() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/gt`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async listAnswers({ itemContent = "" }) {
    if (!hasPermission([adminPerms.seeAnswers])) {
      return new Error(securityErrorMessage("visualizar respostas."));
    }

    let queryParams = "isSummary=true";

    if (itemContent) {
      queryParams += `&itemContent=${itemContent}`;
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer?${queryParams}`,
        method: "GET",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async listAnswersSummary(filters) {
    if (!hasPermission([adminPerms.seeAnswers])) {
      return new Error(securityErrorMessage("visualizar respostas."));
    }

    let queryParams = "";

    if (filters) {
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          queryParams += `${key}=${filters[key]}&`;
        }
      });

      if (queryParams.endsWith("&")) {
        queryParams = queryParams.slice(0, -1);
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/AnswerSummary?${queryParams}`,
        method: "GET",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getById(id) {
    if (!hasPermission([adminPerms.seeAnswers])) {
      return new Error(securityErrorMessage("visualizar respostas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async createAnswer(data) {
    if (!hasPermission([adminPerms.addAnswers])) {
      return new Error(securityErrorMessage("adicionar respostas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async update(data) {
    if (!hasPermission([adminPerms.editAnswers])) {
      return new Error(securityErrorMessage("editar respostas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteAnswer(id) {
    if (!hasPermission([adminPerms.deleteAnswers])) {
      return new Error(securityErrorMessage("excluir respostas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
