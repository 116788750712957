import React, { useState, useEffect, useContext } from "react";
//components
import { makeAdditionalCompanyPlanList as MakeAdditionalCompanyPlanList } from "~/main/factories/pages/admin/additional-company-plan";
//functions
import cnpjSearch from "~/data/functions/cnpj-search";
import Input from "~/presentation/components/Common/Input";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import PageListHeader from "../../../components/PageListHeader";
import ButtonCommon from "~/presentation/components/Common/Button";
import { makeUserTable as MakeUserTable } from "~/main/factories/pages/admin/user";
import { Search } from "@mui/icons-material";
import RegisterEdit from "~/presentation/views/Admin/Plan/RegisterEdit/RegisterEdit";
import CreateEditCompanyCategory from "../../../CompanyCategory/CreateEdit/CreateEdit";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Box,
  FormLabel,
  Radio,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import Grid from "@mui/material/Grid2";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { textTransform } from "@mui/system";
import { DialogContext } from "~/presentation/providers/DialogProvider";

const CompanyForm = ({
  id,
  values,
  onChangeValue,
  errors,
  handleSubmit,
  plans,
  categories,
  states,
  legalNatureOptions,
  citiesCache,
  setValues,
  getPlans,
  getCategories,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const [tab, setTab] = useState(1);
  const [cities, setCities] = useState([]);

  const handleSubstituteList = () => {
    openDialogBox({
      customComponent: () => (
        <ListComponent
          data={citiesCache}
          checkedData={values?.totalSubstituteOrDismissed}
          title="Cidades"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("totalSubstituteOrDismissed", values)}
        />
      ),
    });
  };

  const handlePlanModal = () => {
    openDialogBox({
      fullWidth: true,
      maxWidth: "md",
      customComponent: () => (
        <RegisterEdit data={null} onCloseForm={closeDialog} isModal={true} refreshData={getPlans} />
      ),
    });
  };

  const handleCategoryModal = () => {
    openDialogBox({
      customComponent: () => (
        <CreateEditCompanyCategory
          data={null}
          onCloseForm={closeDialog}
          refreshData={getCategories}
        />
      ),
    });
  };

  const handlerCities = async (stateId) => {
    let tmp = citiesCache.filter((x) => x.stateId === stateId);
    setCities(tmp);
  };

  useEffect(() => {
    if (values?.state !== 0) {
      handlerCities(values.state);
    }
  }, []);

  const handleSearchCNPJ = async (cnpj) => {
    openBackdrop(true, "Buscando informações");
    let value = {};
    try {
      const data = await cnpjSearch(cnpj);
      value.name = data.razao_social;
      value.address = `${data.estabelecimento.tipo_logradouro} ${data.estabelecimento.logradouro}, ${data.estabelecimento.numero} - ${data.estabelecimento.bairro}`;
      value.complement = data.estabelecimento.complemento;
      value.cep = data.estabelecimento.cep;
      value.state = states.filter((x) => x.id === data.estabelecimento.estado.ibge_id);
      value.phone = `${data.estabelecimento.ddd1}${data.estabelecimento.telefone1}`;
      value.email = data.estabelecimento.email;
      value.state = data.estabelecimento.estado.ibge_id;
      if (data.estabelecimento.cidade) {
        handlerCities(data.estabelecimento.estado.ibge_id).then((_) => {
          let city = citiesCache.filter(
            (x) => x.codeIBGE === data.estabelecimento.cidade.ibge_id
          )[0];
          value.city = city.id;
        });
      }
      let code = `${data.natureza_juridica.id.substring(0, 3)}-${data.natureza_juridica.id[3]}`;
      let nature = legalNatureOptions.filter((x) => x.code === code)[0];
      if (nature) {
        value.legalNatureId = nature.id;
      }
      setValues((prev) => ({ ...prev, ...value }));
    } catch (error) {
      showError(error, "CNPJ não encontrado.");
    } finally {
      openBackdrop(false);
    }
  };

  const handleDeleteSubstitute = (params) => {
    if (values.totalSubstituteOrDismissed.length === 1) {
      onChangeValue("totalSubstituteOrDismissed", []);
    } else {
      const filtedValue = values?.totalSubstituteOrDismissed?.filter(
        (item) => item.id !== params.row.id
      );
      onChangeValue("totalSubstituteOrDismissed", filtedValue);
    }
  };

  const tabsObject = {
    1: (
      <SecurityPath securePaths={[adminPerms.seeUsers]}>
        <MakeUserTable companyIdFromForms={id} />
      </SecurityPath>
    ),
    2: (
      <MakeAdditionalCompanyPlanList
        company={{
          id: id,
          name: values.name,
        }}
      />
    ),
    3: (
      <Grid size={12}>
        <PageListHeader
          title="Substituto total ou dispensado"
          titleAdd="Adicionar substituto total ou dispensado"
          handleAdd={handleSubstituteList}
          bg="white"
        />
        <Table
          columns={[
            { field: "uf", headerName: "UF", flex: 1, align: "center" },
            { field: "name", headerName: "Nome", flex: 2, align: "center" },
            {
              field: "codeIBGE",
              headerName: "Código IBGE",
              flex: 1,
              align: "center",
            },
            {
              field: "actions",
              headerName: "Ações",
              sortable: false,
              filterable: false,
              align: "center",
              renderCell: (params) => (
                <TableActions handleDelete={() => handleDeleteSubstitute(params)} />
              ),
            },
          ]}
          data={values?.totalSubstituteOrDismissed}
        />
      </Grid>
    ),
  };

  const renderTab = () => {
    return <Box sx={styles.painel}>{id ? tabsObject[tab] : tabsObject[3]}</Box>;
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Dados da Empresa</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 4 }} sx={{ position: "relative" }}>
            <Input
              type="mask"
              mask="00.000.000/0000-00"
              title="CNPJ"
              name="cnpj"
              value={values?.cnpj}
              onChange={(e) => onChangeValue("cnpj", e)}
              error={errors.cnpj}
            />
            {values?.cnpj?.length === 14 && (
              <Box sx={{ position: "absolute", top: "20px", right: "3px" }}>
                <Tooltip title="Pesquisar CNPJ na RFB">
                  <IconButton sx={{ p: "10px" }} onClick={() => handleSearchCNPJ(values?.cnpj)}>
                    <Search style={{ color: "#7D7D7D" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Input
              type="text"
              title="Nome da empresa"
              name="name"
              value={values?.name}
              onChange={(e) => onChangeValue("name", e.target.value)}
              error={errors.name}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="select"
              title="Natureza Jurídica"
              name="legalNatureId"
              options={legalNatureOptions}
              value={legalNatureOptions.find((item) => item.id === values?.legalNatureId)}
              onChange={(e) => onChangeValue("legalNatureId", e.id)}
              getOptionLabel={(option) => `${option.code} - ${option.description}`}
              error={errors.legalNatureId}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="select"
              title="Categoria"
              name="categoryId"
              placeholder="Selecione a categoria..."
              value={categories.find((item) => item.id === values?.categoryId)}
              onChange={(e) => onChangeValue("categoryId", e.id)}
              options={categories}
              icon={{
                title: "Criar uma nova categoria",
                func: handleCategoryModal,
                securePaths: [adminPerms.addCompanyCategory],
              }}
              error={errors.categoryId}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 7, md: 8 }}>
            <Input
              type="text"
              title="Endereço"
              name="address"
              onChange={(e) => onChangeValue("address", e.target.value)}
              value={values?.address}
              error={errors.address}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 5, md: 4 }}>
            <Input
              type="mask"
              mask="00000-000"
              title="CEP *"
              name="cep"
              value={values?.cep}
              onChange={(e) => onChangeValue("cep", e)}
              error={errors.cep}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Input
              type="select"
              title="Estado"
              name="state"
              placeholder="Selecione o estado..."
              onChange={(e) => {
                onChangeValue("state", e.id);
                handlerCities(e.id);
              }}
              value={states.find((item) => item.id === values?.state)}
              options={states}
              error={errors.state}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Input
              type="select"
              title="Cidade"
              name="city"
              placeholder="Selecione a cidade..."
              value={cities.find((item) => item.id === values?.city)}
              onChange={(e) => onChangeValue("city", e.id)}
              options={cities}
              error={errors.city}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="text"
              title="Complemento"
              name="complement"
              onChange={(e) => onChangeValue("complement", e.target.value)}
              value={values?.complement}
              error={errors.complement}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="email"
              title="Email"
              name="email"
              value={values?.email}
              onChange={(e) => onChangeValue("email", e.target.value)}
              error={errors.email}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Input
              type="mask"
              mask="(00) 00000-0000"
              title="Telefone"
              name="phone"
              value={values?.phone}
              onChange={(e) => onChangeValue("phone", e)}
              error={errors.phone}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Input
              type="mask"
              mask="(00) 00000-0000"
              title="Celular"
              name="mobilePhone"
              value={values?.mobilePhone}
              onChange={(e) => onChangeValue("mobilePhone", e)}
              error={errors.mobilePhone}
            />
          </Grid>
          <Grid size={12} sx={{ marginTop: 2 }}>
            <Typography sx={{ ...formStyles.title, paddingBottom: 0 }}>Plano</Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <Input
              type="select"
              title="Plano"
              name="plan"
              placeholder="Selecione o plano..."
              value={plans.find((item) => item.id === values?.plan)}
              onChange={(e) => onChangeValue("plan", e.id)}
              options={plans}
              icon={{
                title: "Criar um novo plano",
                func: handlePlanModal,
                securePaths: [adminPerms.addPlans],
              }}
              error={errors.plan}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <Input
              type="date"
              title="Data de início"
              name="dateStart"
              value={values?.dateStart}
              onChange={(e) => onChangeValue("dateStart", e)}
              error={errors.dateStart}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <Input
              type="date"
              title="Data de término"
              name="dateEnd"
              value={values?.dateEnd}
              onChange={(e) => onChangeValue("dateEnd", e)}
              error={errors.dateEnd}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <FormControl sx={formStyles.radioContainer} component="fieldset">
              <FormLabel sx={formStyles.subtitle}>Status</FormLabel>
              <RadioGroup
                aria-label="active"
                row
                name="active"
                value={values.active}
                onChange={(event) => onChangeValue("active", event.target.value)}
                sx={{ paddingLeft: 2 }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Ativo"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Inativo"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ m: 3 }}>
        {id && (
          <Box sx={styles.tabsContainer}>
            <Button onClick={() => setTab(1)} sx={styles.tabButton(tab === 1)}>
              Usuários
            </Button>
            <Button onClick={() => setTab(2)} sx={styles.tabButton(tab === 2)}>
              Adicionais de Plano
            </Button>
            <Button onClick={() => setTab(3)} sx={styles.tabButton(tab === 3)}>
              Substituto total ou dispensado
            </Button>
          </Box>
        )}
        {renderTab()}
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default CompanyForm;

const styles = {
  tabsContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    mb: { xs: 2, md: 4 },
    gap: { xs: 1, md: 0 },
  },
  tabButton: (active) => ({
    padding: "6px 20px",
    " &:first-child": {
      borderRadius: { xs: "12px", md: "12px 0px 0px 12px" },
    },
    "&:last-child": {
      borderRadius: { xs: "12px", md: "0px 12px 12px 0px" },
    },
    " &:not(:first-child):not(:last-child)": {
      borderRadius: { xs: "12px", md: "0px" },
    },
    background: active ? "#001A50" : "#E6E6E6",
    color: active ? "#fff" : "#222",
    width: { xs: "100%", md: "auto" },
    fontFamily: "Montserrat",
    fontSize: "0.9rem",
    fontWeight: 700,
    textTransform: "none",
    "&:hover": {
      background: !active && "#B1B1B1",
    },
  }),
  painel: {
    background: "#E6E6E6",
    borderRadius: "12px",
    padding: "6px 24px 24px 24px",
  },
};
