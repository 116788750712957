import React, { useState, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { PersonAddAlt1 } from "@mui/icons-material";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import { DependentModal } from "./Dependent";
import { ApiDependentUseCases } from "~/data/usecases/dependent/apiDependent";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { DeductionModal } from "./Deduction";
import { ApiDeductionUseCases } from "~/data/usecases/deductions/apiDeductions";
import { formatNumberToRealString } from "~/presentation/views/Reinf/Utils/utilsCurrencyvalue";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import Grid from "@mui/material/Grid2";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import TableActions from "~/presentation/components/TableActions";
import Table from "~/presentation/views/Reinf/components/Table/Table";
import { IncomeExemptionModal } from "./IncomeExemption";
import {
  useDeductionsOptions,
  useDependentsOptions,
  useIncomeNatureOption,
} from "~/presentation/views/Reinf/Utils/utilsOptionsReinf";
import { useIncomeExOptions } from "~/presentation/views/Reinf/Utils/utilsOptionsReinf";
import { ApiIncomeExemptionUseCases } from "~/data/usecases/IncomeExemption/incomeExemption";

export const Payments = ({
  payData,
  setPayData,
  partners,
  getPartners,
  incomeNatureOptions,
  handleSubmit,
  disabledEdit,
}) => {
  const [open, setOpen] = useState(false);
  const [dedOpen, setDedOpen] = useState(false);
  const [depOpen, setDepOpen] = useState(false);
  const [exIncomeOpen, setExIncomeOpen] = useState(false); //estado de valores selecionaveis
  const apiDependent = new ApiDependentUseCases(makeHttpClient());
  const apiDeduction = new ApiDeductionUseCases(makeHttpClient());
  const apiIncomeExemption = new ApiIncomeExemptionUseCases(makeHttpClient());
  const incomeExOptions = useIncomeExOptions();
  const { getDescriptionDependent } = useDependentsOptions();
  const { getDescriptionDeduction } = useDeductionsOptions();
  const incomeNatureOption = useIncomeNatureOption(incomeNatureOptions, payData?.incomeNatureId);

  const [dependents, setDependents] = useState([]);
  const columnsDependents = [
    {
      field: "cpf",
      headerName: "CPF",
      align: "center",
      renderCell: ({ value }) => formatCpfCnpj(value),
    },
    {
      field: "dependentType",
      headerName: "Tipo de dependente",
      align: "center",
      renderCell: ({ value }) => {
        return getDescriptionDependent(value);
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => {
        if (disabledEdit) return;
        return (
          <TableActions
            handleEdit={() => handleEditDependent(row)}
            handleDelete={() => HandleDeleteDependent(row?.id)}
          />
        );
      },
    },
  ];
  const [deductions, setDeductions] = useState([]);
  const columnsDeductions = [
    {
      field: "type",
      headerName: "Tipo de dedução",
      align: "center",
      renderCell: ({ value }) => {
        return getDescriptionDeduction(value);
      },
    },
    {
      field: "amount",
      headerName: "Valor",
      align: "center",
      renderCell: ({ value }) => formatNumberToRealString(value),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => {
        if (disabledEdit) return;
        return (
          <TableActions
            handleEdit={() => handleEditDeduction(row)}
            handleDelete={() => handleDeleteDeduction(row?.id)}
          />
        );
      },
    },
  ];
  const [incomeExemption, setIncomeExemption] = useState([]);
  const columnsIncomeExemption = [
    {
      field: "exemptionType",
      headerName: "Tipo de Isenção",
      align: "center",
      renderCell: ({ value }) => {
        const option = incomeExOptions.find((opt) => opt.code === value);
        return option?.description;
      },
    },
    {
      field: "amount",
      headerName: "Valor",
      align: "center",
      renderCell: ({ value }) => formatNumberToRealString(value),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => {
        if (disabledEdit) return;
        return (
          <TableActions
            handleEdit={() => handleEditExIncome(row)}
            handleDelete={() => handleDeleteExIncome(row?.id)}
          />
        );
      },
    },
  ];

  const [selectedDeduction, setSelectedDeduction] = useState(null); // Estado para armazenar a dedução a ser editada
  const [selectedDependent, setSelectedDependent] = useState(null);
  const [selectedIncomeExemption, setSelectedIncomeExemption] = useState(null);

  const getDependents = async () => {
    const result = await apiDependent.getDependent(payData?.businessPartnerId);
    setDependents(result);
  };

  const getDeductions = async () => {
    const result = await apiDeduction.getDeduction(payData?.id);
    setDeductions(result);
  };

  const getIncomeExemption = async () => {
    const result = await apiIncomeExemption.getIncomeExemption(payData?.id);
    setIncomeExemption(result);
  };

  const HandleDeleteDependent = async (id) => {
    await apiDependent.deleteDependent(id);
    getDependents();
  };

  const handleDeleteDeduction = async (id) => {
    await apiDeduction.deleteDeduction(id);
    getDeductions();
  };

  const handleDeleteExIncome = async (id) => {
    await apiIncomeExemption.deleteIncomeExemption(id);
    getIncomeExemption();
  };

  const handleEditDeduction = (deduction) => {
    setSelectedDeduction(deduction); //recebe objeto clicado para edição de deduction
    setDedOpen(true);
  };

  const handleEditDependent = (dependent) => {
    setSelectedDependent(dependent);
    setDepOpen(true);
  };

  const handleEditExIncome = (exIncome) => {
    setSelectedIncomeExemption(exIncome);
    setExIncomeOpen(true);
  };

  //funtions utils
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //funtions utils
  const handleOpenDedModal = () => {
    if (disabledEdit) return;
    setDedOpen(true);
  };
  const handleCloseDedModal = () => {
    setDedOpen(false);
    setSelectedDeduction(null);
    getDeductions();
  };

  const handleOpenDepModal = () => {
    if (disabledEdit) return;
    setDepOpen(true);
  };
  const handleCloseDepModal = () => {
    setDepOpen(false);
    setSelectedDependent(null);
    getDependents();
  };

  const handleOpenExIncomeModal = () => setExIncomeOpen(true);
  const handleCloseExIncomeModal = () => {
    setExIncomeOpen(false);
    setSelectedIncomeExemption(null);
    getIncomeExemption();
  };

  const cpfPartnersFilter = partners.filter((partners) => partners.personType === 0);

  const fciScpOptions = [
    { id: 0, label: "Não se aplica" },
    { id: 1, label: "FCI - Fundo ou clube de investimento" },
    { id: 2, label: "SCP - Sociedade em conta de participação" },
  ];

  //changes values forms
  const handleDateChange = (name, date) => {
    setPayData((prev) => ({
      ...prev,
      [name]: date ? new Date(date) : "",
    }));
  };

  const handleChange = (name, value) => {
    setPayData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (payData?.id) {
      getDeductions();
      getDependents();
      getIncomeExemption();
    }
  }, [payData?.id]);

  return (
    <>
      <Box sx={{ backgroundColor: "#E6E6E6", borderRadius: 2 }}>
        <Grid container spacing={2} sx={{ paddingInline: 5, paddingBlock: 2 }}>
          <Grid size={{ xs: 12, sm: 2 }}>
            <Input
              title="Período de apuração"
              type="date"
              viewType="month-year"
              value={payData.dateRegister || ""}
              onChange={(date) => handleDateChange("dateRegister", date)}
              disabled={disabledEdit}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Input
              type="select"
              title="Beneficiário"
              options={cpfPartnersFilter}
              getOptionLabel={(option) => `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`}
              placeholder="Selecionar contratado"
              value={
                cpfPartnersFilter.find((item) => item.id === payData.businessPartnerId) || null
              }
              onChange={(value) => handleChange("businessPartnerId", value?.id)}
              icon={{
                title: "Adicionar beneficiário",
                func: () => {
                  if (disabledEdit) return;
                  handleOpen();
                },
                component: <PersonAddAlt1 sx={{ color: "#fff" }} />,
              }}
              disabled={disabledEdit}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((item) => item.id === payData.incomeNatureId) || null}
              onChange={(value) => handleChange("incomeNatureId", value?.id)}
              disabled={disabledEdit}
            />
          </Grid>
          <Grid size={{ xs: 4, sm: 2 }}>
            <Input
              title="Data do fato gerador"
              type="date"
              value={payData.datePayment || ""}
              onChange={(date) => handleDateChange("datePayment", date)}
              disabled={disabledEdit}
            />
          </Grid>
          <Tooltip title="Competência da folha de pagamento dos rendimentos decorrentes do trabalho">
            <Grid size={{ xs: 4, sm: 2 }}>
              <Input
                title="Competência"
                type="date"
                viewType="month-year"
                value={payData.competence || ""}
                onChange={(date) => handleDateChange("competence", date)}
                disabled={disabledEdit}
              />
            </Grid>
          </Tooltip>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do rendimento bruto"
              name="totalAmount"
              value={payData.totalAmount || 0}
              onChange={(e) => handleChange("totalAmount", e)}
              disabled={disabledEdit}
              error={
                !payData?.totalAmount
                  ? "Campo obrigatório."
                  : payData?.totalAmount < payData?.taxedAmount && payData.totalAmount
                    ? "Se informado, deve ser maior ou igual ao valor Rend. Tributável."
                    : null
              }
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do rendimento tributável"
              name="taxedAmount"
              value={payData.taxedAmount || 0}
              onChange={(e) => handleChange("taxedAmount", e)}
              disabled={disabledEdit}
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do IRRF"
              name="amountIR"
              value={payData.amountIR || 0}
              onChange={(e) => handleChange("amountIR", e)}
              disabled={disabledEdit}
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="text"
              title="Código do País"
              name="countryCode"
              value={payData.countryCode || ""}
              onChange={(e) => handleChange("countryCode", e.target.value)}
              disabled={disabledEdit}
            />
          </Grid>
          {/* FCI / SCP */}
          <Grid size={{ xs: 2, md: 4 }}>
            <Input
              type="select"
              title="Indicativo de FCI/SCP"
              options={fciScpOptions}
              placeholder="Indicativo de FCI/SCP"
              value={fciScpOptions.find((item) => item.id === payData.fciScp) || fciScpOptions[0]}
              getOptionLabel={(option) => option.label}
              onChange={(value) => handleChange("fciScp", value.id)}
              disabled={disabledEdit}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}>
            {payData?.fciScp != 0 && (
              <Input
                type="mask"
                mask={"00.000.000/0000-00"}
                title="CNPJ FciScp"
                name="cnpjFciScp"
                value={payData.cnpjFciScp || ""}
                onChange={(e) => handleChange("cnpjFciScp", e)}
                disabled={disabledEdit}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}>
            {payData?.fciScp === 2 && (
              <Input
                type="amount"
                prefix="%"
                title="Porcentagem SCP"
                name="percentSCP"
                value={payData.percentSCP || ""}
                onChange={(e) => handleChange("percentSCP", e)}
                disabled={disabledEdit}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}></Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Tooltip
              title={
                incomeNatureOption && !incomeNatureOption?.isRRA
                  ? "A natureza do rendimento selecionada não permite essa opção."
                  : ""
              }
            >
              <FormControlLabel
                disabled={!incomeNatureOption?.isRRA}
                control={
                  <Checkbox
                    disabled={disabledEdit}
                    checked={payData?.isRRA || false}
                    onChange={(e) => handleChange("isRRA", e.target.checked)}
                  />
                }
                label="Rendimento Recebido Acumuladamente - RRA"
              />
            </Tooltip>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabledEdit}
                  checked={payData?.isJudicialDecision || false}
                  onChange={(e) => handleChange("isJudicialDecision", e.target.checked)}
                />
              }
              label="Pagamento oriundo de decisão judicial"
            />
            <Tooltip
              title={
                incomeNatureOption && !incomeNatureOption?.isRRA
                  ? "A natureza do rendimento selecionada não permite essa opção."
                  : ""
              }
            >
              <FormControlLabel
                disabled={!incomeNatureOption?.is13}
                control={
                  <Checkbox
                    disabled={disabledEdit}
                    checked={payData?.is13 || false}
                    onChange={(e) => handleChange("is13", e.target.checked)}
                  />
                }
                label="Rendimento relativo a 13º salário ?"
              />
            </Tooltip>
          </Grid>
          <Grid size={8}>
            <Input
              title="Observação sobre o pagamento"
              name="observation"
              type="textarea"
              minRows={5}
              maxlength="500"
              value={payData.observation || ""}
              onChange={(e) => handleChange("observation", e.target.value)}
              disabled={disabledEdit}
            />
          </Grid>
        </Grid>
      </Box>
      <Tabs defaultValue={1}>
        <TabsList sx={{ backgroundColor: "#fff", marginTop: 2, padding: 0 }}>
          <Tab value={1} sx={{ selectedBg: "#1341A1" }}>
            DEPENDENTES
          </Tab>
          <Tab value={2} sx={{ selectedBg: "#1341A1" }}>
            DEDUÇÕES
          </Tab>
          <Tab value={3} sx={{ selectedBg: "#1341A1" }}>
            RENDIMENTOS
          </Tab>
        </TabsList>
        <TabPanel value={1}>
          {/* //dependentes */}
          <Table
            data={dependents}
            columns={columnsDependents}
            titleTable={"Dependentes"}
            handleAdd={handleOpenDepModal}
            searchInput={false}
            rowsPage={3}
            disabledEdit={disabledEdit}
          />
        </TabPanel>
        <TabPanel value={2}>
          <Table
            data={deductions}
            columns={columnsDeductions}
            titleTable={"Deduções"}
            handleAdd={handleOpenDedModal}
            searchInput={false}
            rowsPage={3}
          />
        </TabPanel>
        <TabPanel value={3}>
          <Table
            data={incomeExemption}
            columns={columnsIncomeExemption}
            titleTable={"Rendimentos isentos ou não tributáveis."}
            handleAdd={handleOpenExIncomeModal}
            searchInput={false}
            rowsPage={3}
          />
        </TabPanel>
      </Tabs>

      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, p: 4 }}>
        <RButton sx={{ width: "150px" }} disabled={disabledEdit} variant="outlined">
          Cancelar
        </RButton>
        <RButton sx={{ width: "150px" }} disabled={disabledEdit} onClick={handleSubmit}>
          Salvar
        </RButton>
      </Box>
      <ModalContainer scroll={true} open={open} onClose={handleClose}>
        <PartnerForm personType={0} onClose={handleClose} refreshData={getPartners} />
      </ModalContainer>
      <ModalContainer scroll={false} open={depOpen} onClose={handleCloseDepModal}>
        <DependentModal
          payData={payData}
          initialValue={selectedDependent}
          handleClose={handleCloseDepModal}
        />
      </ModalContainer>
      <ModalContainer scroll={false} open={dedOpen} onClose={handleCloseDedModal}>
        <DeductionModal
          payData={payData}
          initialValue={selectedDeduction} //se edição
          handleClose={handleCloseDedModal}
          dependents={dependents}
        />
      </ModalContainer>
      <ModalContainer scroll={false} open={exIncomeOpen} onClose={handleCloseExIncomeModal}>
        <IncomeExemptionModal
          payData={payData}
          initialValue={selectedIncomeExemption} //se edição
          handleClose={handleCloseExIncomeModal}
          incomeExOptions={incomeExOptions}
        />
      </ModalContainer>
    </>
  );
};
