import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import RButton from "../components/Common/Buttons/RButton";
import { Container, Stack } from "@mui/material";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { ConsultR1000 } from "./components/ConsultProtocol/ConsultR1000";
import MatrizCompany from "./components/InfoCompany/MatrizCompany/MatrizCompany";
import { ListItemsR1000 } from "./components/InfoCompany/ItemCompany/ListItemsR1000";
import Establishments from "./components/InfoCompany/Establishments/Establishments";
import { ApiEstablishmentUseCases } from "~/data/usecases/establishments/apiEstablishments";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useSize } from "~/presentation/hooks/useSize";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { reinfPerms } from "~/presentation/security/SecurePaths";

export const PanelR1000 = () => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { isMobile } = useSize();
  //show content
  const [content, setContent] = useState(0);
  //USUÁRIO
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const UserEstablishment = user?.companyId;
  //EMPRESA MATRIZ
  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());
  const [companyUser, setCompanyUser] = useState(null);
  //ESTABELECIMENTOS
  const establishmentsUseCases = new ApiEstablishmentUseCases(makeHttpClient());
  const [establishmentsData, setEstablishmentsData] = useState([]);
  //refresh de eventos
  const [refreshConsultR1000, setRefreshConsultR1000] = useState(false);

  const getCompanyUser = async () => {
    openBackdrop(true, "Carregando dados");
    const response = await companyUseCases.getCompanyById({
      id: UserEstablishment,
      checkPermission: true,
    });
    setCompanyUser(response);
    openBackdrop(false);
  };

  const getEstablishments = async () => {
    openBackdrop(true, "Carregando Estabelecimentos");
    try {
      const result = await establishmentsUseCases.getEstablishment(companyUser?.id);
      if (result) {
        setEstablishmentsData(result);
      }
    } catch (error) {
      showError(error, "Erro ao carregar estabelecimentos");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    if (companyUser?.id) {
      getEstablishments();
    }
  }, [companyUser?.id]);

  useEffect(() => {
    getCompanyUser();
  }, []);

  const refreshConsult = () => setRefreshConsultR1000((prev) => !prev);

  const renderContent = () => {
    switch (content) {
      case 0:
        return (
          <>
            <MatrizCompany companyUser={companyUser} />
            <Establishments
              matrizCompanyId={companyUser?.id}
              establishmentsData={establishmentsData}
              refreshData={getEstablishments}
              setEstablishmentsData={setEstablishmentsData}
              establishmentsUseCases={establishmentsUseCases}
            />
          </>
        );
      case 1:
        return (
          <>
            <ListItemsR1000 matrizCompany={companyUser} onGetItemCompanySuccess={refreshConsult} />
            <SecurityPath securePaths={[reinfPerms.sendR1000]}>
              <ConsultR1000 refresh={refreshConsultR1000} />
            </SecurityPath>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <HeaderSectionPage title="Estabelecimento" />
      <Container maxWidth={false} sx={{ paddingBlock: 2 }}>
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
          <RButton
            sx={{ width: "min(250px, 100%)", p: 3 }}
            variant={content === 0 ? "contained" : "outlined"}
            color={content === 0 ? "primary" : "secondary"}
            onClick={() => setContent(0)}
          >
            Informações da empresa
          </RButton>
          <RButton
            sx={{ width: "min(250px, 100%)", p: 3 }}
            variant={content === 1 ? "contained" : "outlined"}
            color={content === 1 ? "primary" : "secondary"}
            onClick={() => setContent(1)}
          >
            Evento R-1000
          </RButton>
        </Stack>
      </Container>
      {renderContent()}
    </>
  );
};
