import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { Info, Search as SearchIcon } from "@mui/icons-material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import OpenTooltip from "~/presentation/components/Tooltip";
import { Box, Button, Switch, Typography } from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import { validate } from "./validation-schema";
import Grid from "@mui/material/Grid2";

const initialValues = {
  categoryId: null,
  companyType: null,
  isSimples: false,
  isCity: false,
  cityContractorId: null,
  cityServiceId: null,
  cityHireId: null,
};

const InfoTooltip = ({ text }) => (
  <OpenTooltip itemID="info-tooltip" text={text} placement="right">
    <Info sx={{ marginLeft: 0.4 }} fontSize="small" />
  </OpenTooltip>
);

const contractorTooltipText =
  "A natureza jurídica do contratante já está pré-selecionada de acordo com seu cadastro no sistema. “Empresa Privada” pode ter perfis jurídicos diferentes e figurar no cartão do CNPJ com diversos códigos de natureza jurídica (sociedade simples, limitada, anônima, empresário individual, etc.) É possível alterar o perfil do contratante, mas as respostas apresentadas podem ser diferentes.";
const hiredTooltipText =
  "Selecione o perfil do contratado, se é pessoa física, jurídica, cooperativa de trabalho ou MEI. “Pessoa Jurídica” abrange as sociedades em geral, o empresário individual, o EIRELI, as associações e fundações privadas e os serviços sociais autônomos.";
const isSimplesTooltipText =
  "Caso o contratado seja “Pessoa Jurídica”, selecione se é optante do Simples Nacional. Nos demais casos não é necessário.";
const informCitiesTooltipText =
  "Caso o contratado seja “Pessoa Jurídica”, especifique o município da pesquisa. Disponível no plano Diamante.";

export const SearchForm = ({ companyUseCases, cityUseCases, onSubmit, getSearch }) => {
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const { openDialogBox } = useContext(DialogContext);
  const [cities, setCities] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [hiredTypes, setHiredTypes] = useState([]);
  const [currentCompany, setCurrentCompany] = useState({});
  const navigate = useNavigate();

  //campos e erros
  const [searchValues, setSearchValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const [search, setSearch] = useState();
  const loadSearch = async () => {
    setSearch(await getSearch());
  };

  const handleChangeValue = (field, newValue) => {
    setSearchValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const getCompanyCategories = async () => {
    const result = await companyUseCases.getCompanyCategories({ checkPermission: false });
    setCompanyCategories(dataToSelectMapper(result));
  };

  const getHiredTypes = async () => {
    const result = [
      { value: 0, label: "Pessoa Jurídica (PJ)" },
      { value: 1, label: "Microempreendedor Individual (MEI)" },
      { value: 2, label: "Pessoa Física (PF)" },
      { value: 3, label: "Cooperativa" },
    ];
    setHiredTypes(result);
  };

  const getUserCompany = async () => {
    const result = await companyUseCases.getCompanyById({ id: companyId, checkPermission: false });
    setCurrentCompany(result);
  };

  const getCities = async () => {
    const result = await cityUseCases.getAll({ checkPermission: false });
    setCities(result);
  };

  useEffect(() => {
    getCompanyCategories();
    getHiredTypes();
    getUserCompany();
    getCities();
    loadSearch();
  }, []);

  useEffect(() => {
    setSearchValues({
      ...initialValues,
      categoryId: currentCompany?.category?.id,
      companyType: 0,
    });
  }, [currentCompany]);

  const groupValidations = (group, data) => {
    let question = "";
    let city = "";
    const { contractorId, serviceId, hireId } = data;
    if (group === "RG" && hireId !== contractorId) {
      city = cities.find((c) => c.id === hireId);
      question = `O tomador possui unidade em ${city.name}?`;
      return question;
    } else if (group === "ERF" && /*hireId*/ contractorId !== serviceId) {
      city = cities.find((c) => c.id === serviceId);
      question = `O tomador possui unidade em ${city.name}?`;
      return question;
    }
    return "";
  };

  const handleValidation = () => {
    validate
      .validate(searchValues, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(searchValues);
      })
      .catch((error) => {
        setErrors(
          error.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const handleSubmit = (values) => {
    if (values.cityContractorId && values.cityServiceId && values.cityHireId) {
      const { group } = search;
      const data = {
        contractorId: values.cityContractorId,
        serviceId: values.cityServiceId,
        hireId: values.cityHireId,
      };
      const question = groupValidations(group, data);
      let submitValues = { ...values };
      if (!question) return onSubmit(submitValues);
      openDialogBox({
        message: question,
        callback: () => {
          submitValues = { ...values, isBranch: true };
          onSubmit(submitValues);
        },
        negativeCallback: () => {
          submitValues = { ...values, isBranch: false };
          onSubmit(submitValues);
        },
      });
    } else {
      onSubmit(values);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid size={12}>
          <Typography sx={styles.label}>
            Natureza Jurídica do Contratante:
            <InfoTooltip text={contractorTooltipText} />
          </Typography>
          <Input
            type="select"
            style={styles.selectInput}
            name="categoryId"
            options={companyCategories}
            placeholder={"Selecione..."}
            onChange={(option) => handleChangeValue("categoryId", option.value)}
            value={companyCategories.find((i) => i.value === searchValues.categoryId)}
          />
        </Grid>
        <Grid size={12}>
          <Typography sx={styles.label}>
            Tipo do Contratado:
            <InfoTooltip text={hiredTooltipText} />
          </Typography>
          <Input
            type="select"
            style={styles.selectInput}
            name="companyType"
            options={hiredTypes}
            placeholder={"Selecione..."}
            value={hiredTypes.find((i) => i.value === searchValues.companyType)}
            onChange={(option) => {
              handleChangeValue("companyType", option.value);
              handleChangeValue("isSimples", option.value === 1);
            }}
          />
        </Grid>
        <Grid size={12}>
          <Box sx={styles.switchWrapper(searchValues?.companyType !== 0)}>
            <Typography sx={styles.label}>Optante do Simples Nacional:</Typography>
            <Switch
              defaultChecked={false}
              disabled={searchValues?.companyType !== 0}
              name="isSimples"
              color="primary"
              checked={searchValues.isSimples}
              onChange={() => {
                handleChangeValue("isSimples", !searchValues.isSimples);
              }}
            />
            <InfoTooltip text={isSimplesTooltipText} />
          </Box>
        </Grid>
        <Grid size={12}>
          <Box sx={styles.switchWrapper(false)}>
            <Typography sx={styles.label}>
              Deseja informar os municípios envolvidos na operação?
            </Typography>
            <Switch
              name="isCity"
              color="primary"
              checked={searchValues.isCity}
              onChange={() => {
                handleChangeValue("isCity", !searchValues.isCity);
              }}
            />
            <InfoTooltip text={informCitiesTooltipText} />
          </Box>
        </Grid>
        {searchValues.isCity && (
          <>
            <Grid size={{ xs: 12, md: 4 }}>
              <Input
                type="select"
                title="Município do Contratante:"
                style={styles.selectInput}
                name="cityContractorId"
                options={cities}
                placeholder={"Digite o nome do Município"}
                onChange={(option) => {
                  handleChangeValue("cityContractorId", option.id);
                }}
                getOptionLabel={(option) => `${option.name} - ${option.uf}`}
                getOptionValue={(option) => option}
                value={cities.find((i) => i.id === searchValues.cityContractorId)}
                error={errors.cityContractorId}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Input
                type="select"
                title="Município do Contratado:"
                style={styles.selectInput}
                name="cityHireId"
                options={cities}
                placeholder={"Digite o nome do Município"}
                onChange={(option) => {
                  handleChangeValue("cityHireId", option.id);
                }}
                getOptionLabel={(option) => `${option.name} - ${option.uf}`}
                getOptionValue={(option) => option}
                value={cities.find((i) => i.id === searchValues.cityHireId)}
                error={errors.cityHireId}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Input
                type="select"
                title="Município da Prestação:"
                style={styles.selectInput}
                name="cityServiceId"
                options={cities}
                placeholder={"Digite o nome do Município"}
                onChange={(option) => {
                  handleChangeValue("cityServiceId", option.id);
                }}
                getOptionLabel={(option) => `${option.name} - ${option.uf}`}
                getOptionValue={(option) => option}
                value={cities.find((i) => i.id === searchValues.cityServiceId)}
                error={errors.cityServiceId}
              />
            </Grid>
          </>
        )}
        <Grid size={12} sx={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            sx={{
              backgroundColor: "#1341a1",
              fontFamily: "Montserrat, sans-serif;",
              color: "white",
              width: "min(400px, 100%)",
              margin: "32px 16px 0px 16px",
              "&:hover": {
                backgroundColor: "#1331a1",
                color: "white",
              },
            }}
            onClick={handleValidation}
          >
            <SearchIcon />
            PESQUISAR
          </PrimaryButton>
        </Grid>
        <Grid size={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/gt-facil");
            }}
          >
            <KeyboardReturnRoundedIcon />
            Reiniciar pesquisa
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const styles = {
  label: {
    fontSize: "max(16px, 0.7vw)",
    fontFamily: '"Montserrat", sans-serif',
    display: "flex",
    alignItems: "center",
  },
  selectInput: {
    height: "50px",
    borderRadius: "8px",
    scrollbar: {
      "::-webkit-scrollbar": {
        width: "20px",
      },
      "::-webkit-scrollbar-track": {
        background: "#c1c1c1",
        borderRadius: "8px",
        height: "50px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px",
        height: "50px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  },
  switchWrapper: (disabled) => ({
    opacity: disabled ? 0.4 : 1,
    display: "grid",
    gridTemplateColumns: "auto min-content",
    justifyItems: "center",
    alignItems: "center",
    gridTemplateAreas: `
      "A A"
      "B C"
    `,
    gap: "16px",
    "@media screen and (min-width: 600px)": {
      justifyItems: "flex-start",
      gridTemplateAreas: '"A B C"',
      gridTemplateColumns: "auto min-content min-content",
    },
    label: {
      gridArea: "A",
    },
    infoTooltip: {
      gridArea: "C",
    },
    switch: {
      gridArea: "B",
      minWidth: "140px",
      span: {
        fontSize: "max(16px, 1.3vw)",
      },
    },
  }),
};
