import * as Yup from "yup";
import removeMask from "~/presentation/functions/removeMask";

export default Yup.object({
  // obrigatórios
  // company: Yup.string().required("Campo Obrigatório!"),
  name: Yup.string().required("Campo Obrigatório!"),
  email: Yup.string().email("Digite um email válido").required("Campo Obrigatório!"),
  password: Yup.string()
    .required("Campo Obrigatório!")
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/,
      "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
    ),
  confirm_password: Yup.string().oneOf([Yup.ref("password"), null], "As senhas devem ser iguais."),
  // manager: Yup.string().required("Campo Obrigatório!"),
  // facultativos
  phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(10, "Digite um número válido"),
  mobile_phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(11, "Digite um número válido"),
});
