import { apiURL } from "../../usecases/api";

export class ApiBusinessPartnerUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async listBusinessPartner() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/BusinessPartner`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getBusinessPartnerById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/BusinessPartner/${id}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async createBusinessPartner(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/BusinessPartner`,
        method: "POST",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateBusinessPartner(data, id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/BusinessPartner/${id}`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteBusinessPartner(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/BusinessPartner/${id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}
