import React from "react";
import BlogPost from "~/presentation/views/Blog/Post";
import {makeBlogUseCase} from "~/main/factories/use-cases/blog";
import {makeBlogCommentUseCases} from "~/main/factories/use-cases/blog-comment";

export const makeBlogPost = (params) => {
  return (
    <BlogPost
      {...params}
      blogUseCases={makeBlogUseCase()}
      blogCommentUseCases={makeBlogCommentUseCases()}
    />
  );
};
