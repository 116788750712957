import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { reinfPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiDigitalCertUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  async registerDigitalCert(dataToSend) {
    if (!hasPermission([reinfPerms.addCertificate])) {
      throw new Error(securityErrorMessage("adicionar certificado digital"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert`,
        method: "POST",
        data: dataToSend,
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getDigitalCerts() {
    if (!hasPermission([reinfPerms.seeCertificate])) {
      throw new Error(securityErrorMessage("visualizar certificado digital"));
    }

    try {
      const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert`,
        method: "GET",
      });

      // Filtro de certs da companyId
      const filteredCerts = response.data.filter((cert) => cert.companyId === companyId);

      return filteredCerts;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateDigitalCert(data) {
    if (!hasPermission([reinfPerms.editCertificate])) {
      throw new Error(securityErrorMessage("editar certificado digital"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert`,
        method: "PUT",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getDigitalCertById(id) {
    if (!hasPermission([reinfPerms.seeCertificate])) {
      throw new Error(securityErrorMessage("visualizar certificado digital"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert/${id}`,
        method: "GET",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteDigitalCert(id) {
    if (!hasPermission([reinfPerms.deleteCertificate])) {
      throw new Error(securityErrorMessage("excluir certificado digital"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  async downloadDigitalCert(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert/download/${id}`,
        method: "GET",
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}
