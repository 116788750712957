import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";

export class ApiPlanUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getPlans({ ispublic = false, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seePlans])) {
        throw new Error(securityErrorMessage("visualizar planos"));
      }
    }

    try {
      let response = await this.httpClient.request({
        url: ispublic ? `${apiURL}/Plan/Public` : `${apiURL}/Plan/`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getPlan(id) {
    if (!hasPermission([adminPerms.seePlans])) {
      throw new Error(securityErrorMessage("visualizar plano"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updatePlan(plan) {
    if (!hasPermission([adminPerms.editPlans])) {
      throw new Error(securityErrorMessage("editar planos"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan`,
        method: "PUT",
        data: plan,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deletePlan(id) {
    if (!hasPermission([adminPerms.deletePlans])) {
      throw new Error(securityErrorMessage("excluir planos"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async createPlan(plan) {
    if (!hasPermission([adminPerms.addPlans])) {
      throw new Error(securityErrorMessage("criar planos"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan/`,
        method: "POST",
        data: plan,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
