import React, { useState, useEffect, useContext, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import removeMask from "~/presentation/functions/removeMask";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { Avatar, Box, Typography } from "@mui/material";
import validationSchema from "./validationSchema";
import Grid from "@mui/material/Grid2";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const Profile = ({ userUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [values, setValues] = useState();
  const [errors, setErrors] = useState({});

  const fetchLoggedUser = async () => {
    const { id, role } = JSON.parse(localStorage.getItem("GT_user"));
    const apiUser = await userUseCases.getUserById({ id: id, checkPermission: false });
    return {
      name: apiUser.name,
      email: apiUser.email,
      company: apiUser.companyId,
      password: "********************",
      confirm_password: "********************",
      manager: `${apiUser.isManager}`,
      phone: apiUser.homePhone,
      mobile_phone: apiUser.mobilePhone,
      role: role === "admin" ? "Administrador do Sistema" : "Super Admin",
    };
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados de perfil");
      const data = await fetchLoggedUser();
      setValues(data);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar informações. Porfavor, recarregue a página.",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleSubmit = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        const submitValues = {
          companyId: Number(values.company),
          name: values.name,
          email: values.email,
          password: values.password,
          isManager: values.manager === "true" ? true : false,
          homePhone: removeMask(values.phone),
          mobilePhone: removeMask(values.mobile_phone),
        };
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const userFirstLetter = values?.name?.charAt(0) || "";
  const { current: avatarColor } = useRef(`#${Math.floor(Math.random() * 16777215).toString(16)}`);

  return (
    <Box sx={styles.form}>
      {values && (
        <Box sx={styles.container}>
          <Box sx={styles.box}>
            {values.name ? (
              <Avatar sx={{ ...styles.avatar, background: avatarColor }}>{userFirstLetter}</Avatar>
            ) : (
              <Avatar sx={styles.avatar} src="/assets/default_avatar.png" alt="user avatar" />
            )}
            <Box sx={styles.textContainer}>
              <Typography sx={styles.text}>{values.name}</Typography>
              <Typography sx={styles.text}>{values.email}</Typography>
              <Typography sx={styles.text}>
                <em>{values.role}</em>
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.box}>
            <Grid container spacing={1} sx={styles.grid}>
              <Grid size={12}>
                <Typography sx={formStyles.title}>Meu Perfil</Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="text"
                  title="Nome"
                  name="name"
                  placeholder="Insira o nome..."
                  value={values?.name}
                  onChange={(e) => handleChangeValue("name", e.target.value)}
                  error={errors.name}
                  disabled
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="text"
                  title="Email"
                  name="email"
                  placeholder="Insira o email..."
                  value={values?.email}
                  onChange={(e) => handleChangeValue("email", e.target.value)}
                  error={errors.email}
                  disabled
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="mask"
                  mask="(00) 0000-0000"
                  title="Telefone"
                  name="phone"
                  placeholder="Insira o telefone..."
                  value={values?.phone}
                  onChange={(e) => handleChangeValue("phone", e)}
                  error={errors.phone}
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="mask"
                  mask="(00) 00000-0000"
                  title="Celular"
                  name="mobile_phone"
                  placeholder="Insira o celular..."
                  value={values?.mobile_phone}
                  onChange={(e) => handleChangeValue("mobile_phone", e)}
                  error={errors.mobile_phone}
                  disabled
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="password"
                  title="Senha"
                  name="password"
                  placeholder="Insira a senha..."
                  value={values?.password}
                  onChange={(e) => handleChangeValue("password", e.target.value)}
                  error={errors.password}
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="password"
                  title="Confirmar a Senha"
                  name="confirm_password"
                  placeholder="Confirme a senha..."
                  value={values?.confirm_password}
                  onChange={(e) => handleChangeValue("confirm_password", e.target.value)}
                  error={errors.confirm_password}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid
              size={12}
              sx={{
                width: "min(300px, 90%)",
                display: "flex",
                justifyContent: "center",
                marginBlock: 3,
              }}
            >
              <ButtonCommon onClick={() => handleSubmit(values)} disabled>
                Salvar
              </ButtonCommon>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Profile;

const styles = {
  form: {
    padding: 3,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1400,
  },
  container: {
    display: "flex",
    gap: 1,
    flexDirection: "column",
    "@media (min-width: 1280px)": {
      flexDirection: "row",
    },
  },
  box: {
    padding: 3,
    backgroundColor: "#E6E6E6",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  text: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: "Montserrat",
    "@media (min-width: 480px)": {
      fontSize: 22,
    },
  },
  avatar: {
    width: "150px",
    height: "150px",
    fontSize: 128,
    "@media (min-width: 1280px)": {
      width: "200px",
      height: "200px",
    },
  },
};
