import React from "react";
import {makeBlogUseCase} from "~/main/factories/use-cases/blog";
import {makeBlogCategoryUseCases} from "~/main/factories/use-cases/blog-category";
import BlogList from "~/presentation/views/Blog/List";

export const makeBlogList = (params) => {
  return (
    <BlogList
      {...params}
      blogUseCases={makeBlogUseCase()}
      blogCategoryUseCases={makeBlogCategoryUseCases()}
    />
  );
};
