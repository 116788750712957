import { addYears } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import removeMask from "~/presentation/functions/removeMask";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import PageListHeader from "../../components/PageListHeader";
import CompanyForm from "./Form";
import validationSchema from "./Form/validationSchema";

function RegisterEdit({
  data,
  onCloseForm,
  refreshData,
  onGetFormOptions,
  plans,
  categories,
  states,
  legalNatureOptions,
  citiesCache,
  getPlans,
  getCategories,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showSuccess, showError } = useContext(SnackbarContext);
  const companyUseCases = makeCompanyUseCases();

  //input value and errors
  const [values, setValues] = useState();
  const [errors, setErrors] = useState({});

  const isCreate = data === null;

  useEffect(() => {
    onGetFormOptions();
    if (plans.length === 0) {
      getPlans();
    }
    if (categories.length === 0) {
      getCategories();
    }
  }, []);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    setValues(() => {
      if (data) {
        return {
          id: data.id,
          address: data.address,
          complement: data.complement,
          name: data.name,
          cnpj: data.cnpj,
          cep: data.zipCode,
          categoryId: data.category.id,
          mobilePhone: data.mobilePhone,
          plan: data.plan.id, // id do plano
          state: data.state.id,
          city: data.city.id,
          phone: data.homePhone,
          email: data.email,
          dateStart: data.dateStart ? new Date(data.dateStart) : null,
          dateEnd: data.dateEnd ? new Date(data.dateEnd) : null,
          legalNatureId: data?.legalNature?.id,
          totalSubstituteOrDismissed: getDefaultValues(data.totalSubstituteOrDismissed),
          active: data.active, // (ativa, inativa)
        };
      }
      return {
        id: 0,
        address: "",
        complement: "",
        name: "",
        cnpj: "",
        cep: "",
        categoryId: "", // id da categoria
        mobilePhone: "",
        plan: "", // id do plano
        state: "",
        city: "",
        phone: "",
        email: "",
        dateStart: new Date(), // (automático para dia atual)
        dateEnd: addYears(new Date(), 1), // (automático para +1 ano)
        dateCompanyRegistered: new Date(),
        active: true, // (ativa, inativa),
        totalSubstituteOrDismissed: [],
      };
    });
  }, [data]);

  const getDefaultValues = (obj) => {
    //tratar das totalSubstituteOrDismissed que vem do back para não dar erro
    if (obj) {
      const ids = obj.map((item) => item.cityId);
      const result = citiesCache.filter((item) => ids.includes(item.id));
      return result;
    }
  };

  const handleValidation = (values) => {
    const validationValues = {
      ...values,
      dateStart: values.dateStart == "Invalid Date" ? null : values.dateStart,
      dateEnd: values.dateEnd == "Invalid Date" ? null : values.dateEnd,
    };
    validationSchema
      .validate(validationValues, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(validationValues);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    const submitValues = {
      id: values.id,
      categoryId: Number(values.categoryId),
      legalNatureId: Number(values.legalNatureId),
      name: values.name,
      cnpj: removeMask(values.cnpj),
      address: values.address,
      complement: values.complement,
      zipCode: removeMask(values.cep),
      cityId: values.city,
      email: values.email,
      homePhone: removeMask(values.phone),
      mobilePhone: removeMask(values.mobilePhone),
      planId: Number(values.plan),
      dateCompanyRegistered: !values.dateCompanyRegistered
        ? new Date()
        : values.dateCompanyRegistered,
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
      active:
        typeof values.active === "boolean" ? values.active : values.active.toLowerCase() === "true",
      totalSubstituteOrDismissed: values.totalSubstituteOrDismissed
        ? values.totalSubstituteOrDismissed.map((item) => ({
            cityId: item.id,
            name: item.name,
          }))
        : null,
    };

    try {
      openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} empresa`);
      if (isCreate) {
        await companyUseCases.register(submitValues);
      } else {
        await companyUseCases.update({ data: submitValues, checkPermission: true });
      }
      setErrors({});
      showSuccess(`Empresa ${isCreate ? "cadastrada" : "editada"} com sucesso!`);
      await refreshData();
      onCloseForm();
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "cadastrar" : "editar"} empresa`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Cadastrar" : "Editar"} Empresa`}
        handleBack={onCloseForm}
        titleBack="Voltar para Empresas"
      />

      {values && (
        <CompanyForm
          id={data?.id || null}
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          setValues={setValues}
          getPlans={getPlans}
          getCategories={getCategories}
          errors={errors}
          plans={plans}
          categories={categories}
          states={states}
          legalNatureOptions={legalNatureOptions}
          citiesCache={citiesCache}
        />
      )}
    </>
  );
}

export default RegisterEdit;
