import React from "react";
import {makeGTFacilUseCases} from "~/main/factories/use-cases/gt-facil";
import {makeCompanyUseCases} from "~/main/factories/use-cases/company";
import {Search} from "~/presentation/views/GTFacil/Search";
import {makeCityUseCases} from "~/main/factories/use-cases/city";
import {makeCnaesUseCases} from "~/main/factories/use-cases/cnaes";

export const makeGTFacilSearch = () => {
  return (
    <Search
      gtFacilUseCases={makeGTFacilUseCases()}
      companyUseCases={makeCompanyUseCases()}
      cityUseCases={makeCityUseCases()}
      cnaesUseCases={makeCnaesUseCases()}
    />
  );
};
