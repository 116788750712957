import React, { useContext, useEffect, useState } from "react";
import TableActions from "~/presentation/components/TableActions";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { Box } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import Table from "~/presentation/components/Table";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";

function Plan({ planUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [plans, setPlans] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <RegisterEdit data={data} onCloseForm={closeDialog} refreshData={fetchData} />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const response = await planUseCases.getPlans({ ispublic: false, checkPermission: true });
      setPlans(response);
    } catch (error) {
      showError(error, "Erro ao carregar planos");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 1 },
    { field: "numberOfUsers", headerName: "Usuários", align: "center", flex: 1 },
    {
      field: "valor",
      headerName: "Preço",
      renderCell: (params) => formatNumberToRealString(params.value),
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editPlans]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deletePlans]}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo plano");
    try {
      await planUseCases.deletePlan(id);
      showSuccess("Plano excluído!");
      setPlans((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir o plano.");
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este plano?`,
      callback: () => handleDelete(id),
    });
  };

  return (
    <>
      <PageListHeader
        title="Plano"
        titleAccess="Novo plano"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addPlans]}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={plans} />
      </Box>
    </>
  );
}

export default Plan;
