import styled from "@emotion/styled";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";

export const CustomTreeItem = styled(TreeItem)(({ sx }) => ({
  color: "#000000",
  [`& .${treeItemClasses.content}`]: {
    borderRadius: "10px",
    padding: "4px 10px",
    margin: 0,
    backgroundColor: "transparent !important",
    [`& .${treeItemClasses.label}`]: {
      fontSize: "16px",
      fontWeight: sx?.fontWeight || 600,
      fontFamily: "Montserrat",
    },
  },

  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 17,
    paddingLeft: 1,
    borderLeft: `1px dashed #b1b1b1`,
  },
}));
