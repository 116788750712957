import * as Yup from "yup";

export default Yup.object({
  subject: Yup.string().required("Campo Obrigatório!"),
  message: Yup.string().required("Campo Obrigatório!"),
  files: Yup.array().test({
    test: (arr) => arr.length <= 5,
    message: "Máximo de 5 arquivos permitidos para upload",
  }),
});
