import React from "react";

function useHandleDates() {
  //current time
  const currentDay = new Date();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  //valores padrão
  const sixMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 6));
  const sixYearsAgo = new Date(currentYear - 10, currentMonth, 1); //6 anos atrás

  const handleDateErrors = (start, end) => {
    //ERRO: Data inválida (FUTURO)
    const isStartDateInTheFuture = start > new Date();
    const isEndDateInTheFuture = end > new Date();
    if (isStartDateInTheFuture || isEndDateInTheFuture) return "Selecione uma data no presente.";
    //ERRO: Data inicial maior do que final
    const isStartDateHigherThanEnd = start > end;
    if (isStartDateHigherThanEnd) return "Data inicial superior à data final.";
    // ERRO: Data inválida (não totalmente preenchida)
    const isStartDateInvalid = !start || start == "Invalid Date";
    const isEndDateInvalid = !end || end == "Invalid Date";
    if (isStartDateInvalid || isEndDateInvalid) return "Ensira datas válidas";
    return false;
  };

  return { handleDateErrors, currentDay, currentYear, currentMonth, sixMonthsAgo, sixYearsAgo };
}

export default useHandleDates;
