import React, { useContext } from "react";
// controller
import MessagesController from "~/presentation/views/Admin/components/MessagesController";
// data
import filtersList from "~/presentation/views/Admin/BlogComment/List/FilterList.js";
// components
import SearchField from "~/presentation/components/SearchField";
import ListActions from "~/presentation/views/Admin/components/MessageListActions";
import MessageList from "~/presentation/views/Admin/components/MessageList";
import OpenPopover from "~/presentation/components/Popover";
// material ui
import { FilterList as FilterListIcon } from "@mui/icons-material";
//styles
import {
  Container,
  Wrapper,
  ListHeader,
  Pagination,
} from "~/presentation/views/Admin/BlogComment/List/styles";
import PageListHeader from "../../components/PageListHeader";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { adminPerms } from "~/presentation/security/SecurePaths";

const BlogComments = ({ commentUseCases }) => {
  const { showError } = useContext(SnackbarContext);

  const getComments = async () => {
    try {
      const comments = await commentUseCases.getAllComments();
      return comments;
    } catch (error) {
      showError(error);
    }
  };

  const {
    mobile,
    selectedMessages,
    messages,
    setSelectedMessages,
    handleDelete,
    handleRefresh,
    handleSearch,
    numberOfPages,
    page,
    setPage,
    fetchMessagesWithFilter,
    CustomFilterList,
    handleSelectMessage,
    searchValue,
    handleClickMessage,
    filter,
  } = MessagesController({
    getMessages: getComments,
    filtersList,
    replyMessagePath: "/admin/comentarios/responder/",
    commentUseCases,
  });

  const userHasPermissionToReply = hasPermission([adminPerms.addComments]) || false;

  const deleteFnc = async (id) => {
    await handleDelete(id);
    handleRefresh();
  };

  return (
    <>
      <PageListHeader title="Comentários do Blog" />
      {mobile && <SearchField value={searchValue} handleSearch={(value) => handleSearch(value)} />}

      <Container>
        {!mobile && (
          <Wrapper className="filters">
            <div>
              <CustomFilterList />
            </div>
          </Wrapper>
        )}

        <Wrapper className="messages">
          <ListHeader>
            <ListActions
              selectedItems={selectedMessages}
              items={messages}
              onChange={setSelectedMessages}
              handleDelete={deleteFnc}
              deleteSercurePath={[adminPerms.deleteComments]}
              handleRefresh={handleRefresh}
              renderAfter={
                mobile &&
                (() => (
                  /* mobile: button to open filters menu */
                  <OpenPopover icon={FilterListIcon} label={filter.alias}>
                    {() => (
                      <Wrapper className="filters">
                        <CustomFilterList />
                      </Wrapper>
                    )}
                  </OpenPopover>
                ))
              }
            />

            {!mobile && (
              <div>
                <SearchField value={searchValue} handleSearch={(value) => handleSearch(value)} />
              </div>
            )}
          </ListHeader>

          <MessageList
            messages={messages}
            selectedMessages={selectedMessages}
            handleSelectMessage={handleSelectMessage}
            disableGutters={mobile}
            onClick={(e) => {
              if (userHasPermissionToReply) handleClickMessage(e);
            }}
            title="title"
          />

          <Pagination
            size="small"
            count={numberOfPages}
            page={page}
            onChange={(event, selectedPage) => [
              setPage(page),
              fetchMessagesWithFilter({ page: selectedPage }),
            ]}
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default BlogComments;
