import React from "react";

import { SimulatorResultCard } from "~/presentation/views/GTFacil/Simulator/components";

export const IrPisPasepCofinsIssMei = () => {
  return (
    <SimulatorResultCard
      description="Independentemente da Natureza Jurídica do Contratante a retenção está dispensada."
      noTitleOrValue
    />
  );
};
