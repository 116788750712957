import React, { useState } from "react";
import { Box, Checkbox, Skeleton } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";
import { CustomTreeItem } from "./CostumTreeItem";
import Input from "../Common/Input";

const Tree = ({
  data = [],
  onPermissionChange = () => {},
  isLoading = false,
  arrayLoading = [],
  inputSearchStyle = {},
  iconColor = "#222222",
  treeStyles = {},
  error = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);

  const handleToggle = (itemId) => {
    setExpandedItems((prev) => {
      if (prev.includes(itemId)) {
        return prev.filter((id) => id !== itemId);
      } else {
        const itemLevel = itemId.split("-").length;
        const newExpandedItems = prev.filter((id) => {
          const level = id.split("-").length;
          return level !== itemLevel;
        });
        return [...newExpandedItems, itemId];
      }
    });
  };

  const renderSubTitle = (value) => {
    if (typeof value === "string") {
      if (value === "gtfacil") return "GT-Facil";
    }
    return value;
  };

  const normalizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .toUpperCase();
  };

  const handleSearch = () => {
    if (!searchQuery) return setExpandedItems([]);
    const expanded = searchForItem(data, searchQuery);
    if (expanded.length > 0) {
      setExpandedItems(expanded);
    }
  };

  const searchForItem = (data, query) => {
    let expanded = [];
    const normalizedQueryParts = normalizeText(query).split(/\s+/);
    Object.keys(data).forEach((categoryKey, index) => {
      const category = data[categoryKey];
      category.forEach((section, sectionIndex) => {
        const normalizedItems = section.items.map((item) => normalizeText(item.description));
        const foundItem = normalizedItems.find((normalizedItem) =>
          normalizedQueryParts.every((part) => normalizedItem.includes(part))
        );
        if (foundItem) {
          expanded.push(`${index}`, `${index}-${sectionIndex}`);
        }
      });
    });

    return expanded;
  };

  const renderLabel = (label) => {
    const newLabel = label.slice(0, -1).toUpperCase();
    if (newLabel === "CLIENT") return "CLIENTE";
    return newLabel;
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Input
          style={{ ...inputSearchStyle, width: "100%" }}
          type="search"
          placeholder="Pesquisar..."
          value={searchQuery}
          onChange={(e) => {
            const value = e.target.value;
            setSearchQuery(value);
          }}
          onIconClick={handleSearch}
        />
      </Box>
      <Box
        sx={{
          ...treeStyles,
          height: "600px",
          overflowY: "scroll",
          mt: 1,
          borderColor: error && "#d32f2f",
        }}
      >
        {isLoading ? (
          <>
            {arrayLoading?.map((key, index) => (
              <Box
                key={`${key}-${index}`}
                sx={{
                  display: "flex",
                  alignItens: "end",
                  gap: 0.9,
                  padding: "6px 10px",
                }}
              >
                <Skeleton width="100px" height="20px" variant="rounded" />
              </Box>
            ))}
          </>
        ) : (
          <>
            {Object.keys(data).map((categoryKey, index) => {
              if (data[categoryKey].length === 0) return;
              return (
                <Box
                  key={categoryKey}
                  sx={{
                    maxHeight: "600px",
                    overflowY: "scroll",
                  }}
                >
                  <SimpleTreeView
                    expandedItems={expandedItems}
                    onItemExpansionToggle={(event, itemId) => handleToggle(itemId)}
                    slots={{
                      expandIcon: () => <AddBox sx={styles.checkbox(iconColor)} />,
                      collapseIcon: () => <IndeterminateCheckBox sx={styles.checkbox(iconColor)} />,
                    }}
                  >
                    <CustomTreeItem
                      key={`${index}`}
                      itemId={`${index}`}
                      label={renderLabel(categoryKey)}
                    >
                      {data[categoryKey].map((category, sectionIndex) => (
                        <CustomTreeItem
                          sx={{ textTransform: "capitalize", fontWeight: 500 }}
                          key={`${index}-${sectionIndex}`}
                          itemId={`${index}-${sectionIndex}`}
                          label={renderSubTitle(category.description)}
                        >
                          <Box sx={{ height: "100%", overflowY: "scroll" }}>
                            {category.items.map((item) => (
                              <CustomTreeItem
                                key={`${item.id}`}
                                itemId={`${item.id}`}
                                sx={{ fontWeight: 500 }}
                                slots={{
                                  icon: () => (
                                    <Checkbox
                                      sx={styles.checkbox(iconColor)}
                                      checked={item.isAllow}
                                      onChange={() => {
                                        onPermissionChange(categoryKey, item.id);
                                      }}
                                    />
                                  ),
                                }}
                                label={item.description}
                              />
                            ))}
                          </Box>
                        </CustomTreeItem>
                      ))}
                    </CustomTreeItem>
                  </SimpleTreeView>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </>
  );
};

export default Tree;

const styles = {
  checkbox: (iconColor) => ({
    padding: "0px",
    color: iconColor, // Cor padrão do checkbox
    "&.Mui-checked": {
      color: iconColor, // Cor do checkbox quando está marcado
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px", // Tamanho do ícone do checkbox
    },
  }),
};
