import React from "react";
import {makeTaxInformationUseCases} from "~/main/factories/use-cases/tax-information";
import ReplyTaxInformation from "~/presentation/views/Admin/TaxInformation/Reply";

export const makeReplyTaxInformation = (params) => {
  return (
    <ReplyTaxInformation
      {...params}
      taxInformationUseCases={makeTaxInformationUseCases()}
    />
  );
};
