import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { adminPerms } from "~/presentation/security/SecurePaths";

const IssAnswer = ({ makeIssAnswerUseCases, typesAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);

  //manter a página da tabela sempre atual
  const [page, setPage] = useState(0);

  //data
  const [issAnswers, setIssAnswers] = useState([]);

  //select data options
  const [groups, setGroups] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const { data = [] } = await makeIssAnswerUseCases.getIssAnswers();
      setIssAnswers(data);
    } catch (error) {
      showError(error, "Erro ao carregar Respostas ISS");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "cityName",
      headerName: "Nome da Cidade",
      flex: 1,
    },
    {
      field: "isLegislation",
      headerName: "Legislaçao",
      filterable: false,
      flex: 1,
      align: "center",
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "isSpecificSubstituition",
      headerName: "Substituição Específica",
      filterable: false,
      flex: 1,
      align: "center",
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "isDispensedEROS",
      headerName: "ERO Dispensado",
      filterable: false,
      flex: 1,
      align: "center",
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "isDispensedCPOM",
      headerName: "CPOM Dispensado",
      filterable: false,
      flex: 1,
      align: "center",
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "CPOM",
      headerName: "CPOM",
      flex: 1,
      align: "center",
      filterable: false,
      renderCell: ({ value }) => (value === 0 ? "Nenhum" : value === 1 ? "CPOM 1" : "CPOM 2"),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          editSecurePath={[adminPerms.editIssAnswers]}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchGroups = async () => {
    try {
      const groupsResponse = await makeIssAnswerUseCases.getGroups({ checkPermission: false });
      setGroups(groupsResponse.data);
    } catch (error) {
      showError("Erro ao carregar grupos");
    }
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          makeIssAnswerUseCases={makeIssAnswerUseCases}
          fetchGroups={fetchGroups}
          groups={groups}
          typesAnswerUseCases={typesAnswerUseCases}
          allIssAnswers={issAnswers}
        />
      ) : (
        <>
          <PageListHeader title="Resposta do ISS" />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={issAnswers} page={page} setPage={setPage} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default IssAnswer;
