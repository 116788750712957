import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiTypeAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getTypesAnswer({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeAnswerTypes])) {
        return new Error(securityErrorMessage("visualizar tipos de resposta."));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTypeAnswer(id) {
    if (!hasPermission([adminPerms.seeAnswerTypes])) {
      return new Error(securityErrorMessage("visualizar tipo de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async createTypeAnswer(data) {
    if (!hasPermission([adminPerms.addAnswerTypes])) {
      return new Error(securityErrorMessage("adicionar tipo de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateTypeAnswer(data) {
    if (!hasPermission([adminPerms.editAnswerTypes])) {
      return new Error(securityErrorMessage("editar tipo de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer`,
        method: "PUT",
        data,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteTypeAnswer(id) {
    if (!hasPermission([adminPerms.deleteAnswerTypes])) {
      return new Error(securityErrorMessage("excluir tipo de resposta."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
