import { apiURL } from "../../usecases/api";
export class ApiItemCompany {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async listItemCompany() {
    try {
      const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
      const response = await this.httpClient.request({
        url: `${apiURL}/ItemCompany`,
        method: "GET",
      });
      const filteredItems = response.data.filter((item) => item.companyId === companyId);
      return filteredItems;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getItemCompanyById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ItemCompany/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async createItemCompany(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ItemCompany`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateItemCompany(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ItemCompany`,
        method: "PUT",
        data,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteItemCompany(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ItemCompany/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
