import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import Input from "~/presentation/components/Common/Input";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Typography as Text,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  Stack,
} from "@mui/material";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import ProfileForms from "./Forms/ProfileForms";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { useTableFilters } from "~/presentation/hooks/useTableFilters";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { LoadingTable } from "../../../../components/LoadingTable";

export const UserProfile = ({
  companyId,
  profileId,
  userProfiles,
  refreshData,
  userProfilesUseCases,
  loading,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { handleSearchTable } = useTableFilters();

  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(null);
  const [currentProfiles, setCurrentProfiles] = useState([]);

  useEffect(() => {
    if (userProfiles.length === 0) {
      refreshData();
    }
  }, []);

  useEffect(() => {
    if (userProfiles.length > 0) {
      setCurrentProfiles(userProfiles);
    }
  }, [userProfiles]);

  const handleSearchProfiles = (searchValue) => {
    if (searchValue === null || searchValue === undefined || searchValue.trim() === "") {
      setCurrentProfiles(userProfiles);
    } else {
      handleSearchTable(searchValue, userProfiles, setCurrentProfiles);
    }
  };

  const handleOpenForm = (data = null) => {
    const obj = data || {
      id: 0,
      name: "",
      isInternalUse: false,
      companyId: companyId,
      permission: {
        admins: null,
        clients: null,
        reinfs: null,
      },
    };
    setOpenForm(true);
    setFormProps(obj);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo perfil");
    try {
      await userProfilesUseCases.deleteProfile(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Perfil excluído!",
        type: "success",
      });
      handleCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o perfil. " + error?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este perfil?`,
      callback: () => handleDelete(id),
    });
  };

  const renderLabel = (label) => {
    const newLabel = label.slice(0, -1).toUpperCase();
    if (newLabel === "CLIENT") return "CLIENTE";
    return newLabel;
  };

  const verificarValores = (data) => {
    let result = [];
    for (let key in data) {
      if (data[key] && data[key].some((item) => item.items && item.items.length > 0)) {
        result.push(renderLabel(key));
      }
    }
    if (result.length === 0) {
      return "Sem permissões";
    }
    return result.join(" + ");
  };

  const blockActions = (perfilId) => {
    //Se for um dos perfis GERAIS e não for um perfil da Open, nao pode editar/eliminar
    const isGeralPerfil = [1, 2, 3, 4].includes(perfilId);
    const isOpenUser = companyId === 1;
    return isGeralPerfil && !isOpenUser;
  };

  //Se é o mesmo perfil do usuário, não pode editar/eliminar
  const isUserProfile = (perfilId) => profileId === perfilId;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 4,
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Text sx={styles.title}>Perfil de Usuário</Text>
        <SecurityPath securePaths={[clientPerms.addUserProfile]}>
          <Button sx={styles.ButtonAdd} onClick={() => handleOpenForm()}>
            Adicionar novo perfil <AddIcon />
          </Button>
        </SecurityPath>
      </Box>
      {loading ? (
        <LoadingTable />
      ) : (
        <Paper elevation={2} sx={{ width: "100%", overflowX: "auto" }}>
          <Input
            styles={styles.searchField}
            type="text"
            title=""
            placeholder="Buscar por nome"
            onChange={(e) => handleSearchProfiles(e.target.value)}
          />
          <Table>
            <TableHead sx={styles.TableHead}>
              <TableRow sx={styles.TableRow}>
                <TableCell
                  sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]}
                  align="center"
                >
                  Nome
                </TableCell>
                <TableCell
                  sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]}
                  align="center"
                >
                  Permissões
                </TableCell>
                <SecurityPath
                  securePaths={[clientPerms.editUserProfile, clientPerms.deleteUserProfile]}
                >
                  <TableCell
                    sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]}
                    align="center"
                  >
                    Açoes
                  </TableCell>
                </SecurityPath>
              </TableRow>
            </TableHead>
            {currentProfiles
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((u, index) => {
                const disabledBtn = blockActions(u.id) || isUserProfile(u.id);
                return (
                  <TableBody key={index}>
                    <TableCell sx={styles.TableCell}>{u?.name}</TableCell>
                    <TableCell sx={styles.TableCell}>{verificarValores(u?.permission)}</TableCell>
                    <TableCell sx={{ ...styles.TableCell, width: "140px" }}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <SecurityPath securePaths={[clientPerms.deleteUserProfile]}>
                          <Button
                            sx={styles.DeleteButton}
                            onClick={() => deleteDialog(u.id)}
                            disabled={disabledBtn}
                          >
                            Deletar
                          </Button>
                        </SecurityPath>
                        <SecurityPath securePaths={[clientPerms.editUserProfile]}>
                          <Button
                            sx={{ ...styles.Button, mr: 1 }}
                            name="edit"
                            onClick={() => handleOpenForm(u)}
                            disabled={disabledBtn}
                          >
                            Editar
                          </Button>
                        </SecurityPath>
                      </Box>
                    </TableCell>
                  </TableBody>
                );
              })}
          </Table>
          <Stack spacing={1} sx={{ width: "100%", mt: 2 }}>
            <TablePagination
              component="div"
              count={currentProfiles?.length || 0}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
              labelRowsPerPage="Usuários por página:"
              rowsPerPageOptions={[10, 15, 20]}
            />
          </Stack>
        </Paper>
      )}
      <ModalBox open={openForm} onClose={handleCloseForm}>
        <ProfileForms
          data={formProps}
          userProfilesUseCases={userProfilesUseCases}
          companyId={companyId}
          refreshData={refreshData}
          onCloseForm={handleCloseForm}
          deleteDialog={deleteDialog}
        />
      </ModalBox>
    </Box>
  );
};
const styles = {
  TableHead: {
    justifyContent: "flex-start",
    fontWeight: 500,
    backgroundColor: "#EFF2F4",
  },
  TableCell: {
    fontSize: "0.9rem",
    backgroundColor: "#EFF2F4",
    color: "#021148",
    fontFamily: "Montserrat",
  },
  TableRow: {
    backgroundColor: "#EFF2F4",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  ButtonAdd: {
    background: "#1341a1",
    padding: 0,
    color: "#FFF",
    paddingInline: 4,
    borderRadius: 20,
    height: 48,
    "&:hover": {
      backgroundColor: "#1341a1",
    },
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    textAlign: { xs: "center", md: "left" },
  },

  searchField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    borderRadius: "3px",
    height: "54px",
    border: "1px solid #ccc",
  },
  Button: {
    background: "#081445",
    border: "1px solid #081445",
    paddingInline: { xs: 2, md: 4 },
    color: "#FFF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#081445",
    },
    "&:disabled": {
      border: "1px solid #999",
      background: "transparent",
    },
  },
  DeleteButton: {
    border: "1px solid red",
    paddingInline: { xs: 1, md: 2 },
    color: "red",
    "&:hover": {
      backgroundColor: "red",
      color: "#fff",
    },
    "&:disabled": {
      border: "1px solid #999",
      background: "transparent",
    },
  },
};
