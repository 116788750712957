import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";

// layout
import DashboardLayout from "~/presentation/layouts/DashboardLayout";
// factories
import { makeUserTable } from "~/main/factories/pages/admin/user";
import { makeProfile } from "~/main/factories/pages/admin/profile/makeProfile";
import { makeCompanyTable } from "~/main/factories/pages/admin/company";

import { makeContentTable } from "~/main/factories/pages/admin/content";
import { makeBlogTable } from "~/main/factories/pages/admin/blog";
import { makeBlogCategoryTable } from "~/main/factories/pages/admin/blog-category";
import {
  makeBlogCommentList,
  makeBlogCommentReply,
} from "~/main/factories/pages/admin/blog-comment";
import { makeSurveyTable } from "~/main/factories/pages/admin/survey";
import {
  makeGTFacilAnswers,
  makeGTFacilCNAE,
  makeGTFacilFreeSearch,
} from "~/main/factories/pages/admin/gt-facil";
import { makeDialogTable } from "~/main/factories/pages/admin/gt-facil-dialog";
import {
  makeISSAnswers,
  makeISSCities,
  makeISSStandardAnswers,
  makeUpdateCities,
} from "~/main/factories/pages/admin/iss";
import { makeValueVariableTable } from "~/main/factories/pages/admin/value-variable";
import {
  makeReplyTaxInformation,
  makeTaxInformationList,
} from "~/main/factories/pages/admin/tax-information";
import { makeAdminHome } from "~/main/factories/pages/admin/home/makeAdminHome";
import { makeExplanatoryVideoTable } from "~/main/factories/pages/admin/explanatory-video";
import { makePlanList } from "~/main/factories/pages/admin/plan";
import { makeServiceList } from "~/main/factories/pages/admin/service-code";
import { makeCnaeList } from "~/main/factories/pages/admin/cnae";
import { makeAnswerTable } from "~/main/factories/pages/admin/answers";
import { makeTypesAnswerTable } from "~/main/factories/pages/admin/types-answer";
import { makeItemAnswerTable } from "~/main/factories/pages/admin/item-answers";
import { makeIssAnswerTable } from "~/main/factories/pages/admin/iss-answers";
import { makeAnnotationTable } from "~/main/factories/pages/admin/annotation";
import { makeListTerms } from "~/main/factories/pages/admin/search-term";
import { makeStats } from "~/main/factories/pages/admin/stats/makeStats";
import { makeCompanyCategory } from "~/main/factories/pages/admin/company-category";
import PageListHeader from "../views/Admin/components/PageListHeader";
import makeExplanatoryCategory from "~/main/factories/pages/admin/explanatory-category/makeExplanatoryCategory";
import { makeSecurityPermissionList } from "~/main/factories/pages/admin/security-permission";
import { makeUserProfiles } from "~/main/factories/pages/admin/user-profiles/makeProfiles";
import { SecurityRoute } from "../security/SecurityPath";
import { adminPerms } from "../security/SecurePaths";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/*" element={makeAdminHome()} />
        {/* empresa */}
        <Route
          path="/empresa"
          element={
            <SecurityRoute securePaths={[adminPerms.seeCompanies]} returnTo="/admin">
              {makeCompanyTable()}
            </SecurityRoute>
          }
        />
        {/* plans */}
        <Route
          path="/plano"
          element={
            <SecurityRoute securePaths={[adminPerms.seePlans]} returnTo="/admin">
              {makePlanList()}
            </SecurityRoute>
          }
        />
        {/* usuario */}
        <Route
          path="/usuario"
          element={
            <SecurityRoute securePaths={[adminPerms.seeUsers]} returnTo="/admin">
              {makeUserTable()}
            </SecurityRoute>
          }
        />
        {/* perfis  */}
        <Route
          path="/perfis-de-usuário"
          element={
            <SecurityRoute securePaths={[adminPerms.seeUserProfile]} returnTo="/admin">
              {makeUserProfiles()}
            </SecurityRoute>
          }
        />
        {/* SecurityPermission */}
        <Route path="/permissoes-de-seguranca" element={makeSecurityPermissionList()} />
        {/* empresa categoria  */}
        <Route
          path="/empresa/categoria"
          element={
            <SecurityRoute securePaths={[adminPerms.seeCompanyCategory]} returnTo="/admin">
              {makeCompanyCategory()}
            </SecurityRoute>
          }
        />
        {/* meu perfil */}
        <Route path="/meu-perfil" element={makeProfile()} />
        {/* estatística */}
        <Route
          path="/estatistica"
          element={<PageListHeader title="Mostra estatísticas do site" />}
        />
        <Route path="/estatistica/contagem-de-visualizacoes" element={makeStats()} />
        {/* conteúdo */}
        <Route
          path="/conteudo"
          element={
            <SecurityRoute securePaths={[adminPerms.seeContent]} returnTo="/admin">
              {makeContentTable()}
            </SecurityRoute>
          }
        />
        {/* blog */}
        <Route
          path="/blog"
          element={
            <SecurityRoute securePaths={[adminPerms.seeBlog]} returnTo="/admin">
              {makeBlogTable()}
            </SecurityRoute>
          }
        />
        {/* blog/categoria */}
        <Route
          path="/blog/categoria"
          element={
            <SecurityRoute securePaths={[adminPerms.seeBlogCategory]} returnTo="/admin">
              {makeBlogCategoryTable()}
            </SecurityRoute>
          }
        />
        {/* comentarios */}
        <Route
          path="/comentarios"
          element={
            <SecurityRoute securePaths={[adminPerms.seeComments]} returnTo="/admin">
              {makeBlogCommentList()}
            </SecurityRoute>
          }
        />
        <Route
          path="/comentarios/responder/:id"
          element={
            <SecurityRoute securePaths={[adminPerms.seeComments]} returnTo="/admin">
              {makeBlogCommentReply()}
            </SecurityRoute>
          }
        />
        {/* enquete */}
        <Route
          path="/enquetes"
          element={
            <SecurityRoute securePaths={[adminPerms.seeSurvey]} returnTo="/admin">
              {makeSurveyTable()}
            </SecurityRoute>
          }
        />
        {/* GT fácil */}
        <Route path="/gt-facil/importar/pesquisa-livre" element={makeGTFacilFreeSearch()} />
        <Route path="/gt-facil/importar/cnae" element={makeGTFacilCNAE()} />

        <Route
          path="/gt-facil/importar/respostas"
          element={
            <SecurityRoute securePaths={[adminPerms.seeAnswerImport]} returnTo="/admin">
              {makeGTFacilAnswers()}
            </SecurityRoute>
          }
        />

        {/* GT fácil/diálogo */}
        <Route path="/gt-facil/dialogo" element={makeDialogTable()} />
        {/* Consulta ISS */}
        <Route path="/consulta-iss/importar/respostas-cadastradas" element={makeISSAnswers()} />
        <Route path="/consulta-iss/importar/respostas-padrao" element={makeISSStandardAnswers} />
        <Route path="/consulta-iss/importar/cidade" element={makeISSCities()} />
        <Route
          path="/consulta-iss/atualizar-municipios"
          element={
            <SecurityRoute securePaths={[adminPerms.seeCities]} returnTo="/admin">
              {makeUpdateCities()}
            </SecurityRoute>
          }
        />
        {/* variáveis de valor */}
        <Route
          path="/variaveis-de-valor"
          element={
            <SecurityRoute securePaths={[adminPerms.seeValueVariables]} returnTo="/admin">
              {makeValueVariableTable()}
            </SecurityRoute>
          }
        />
        {/* informações fiscais */}
        <Route
          path="/informacoes-fiscais"
          element={
            <SecurityRoute securePaths={[adminPerms.seeFiscalInformation]} returnTo="/admin">
              {makeTaxInformationList()}
            </SecurityRoute>
          }
        />
        <Route
          path="/informacoes-fiscais/responder/:id"
          element={
            <SecurityRoute securePaths={[adminPerms.seeFiscalInformation]} returnTo="/admin">
              {makeReplyTaxInformation()}
            </SecurityRoute>
          }
        />
        {/* FAQ Explanatory Video */}
        <Route
          path="/ajuda"
          element={
            <SecurityRoute securePaths={[adminPerms.seeVideo]} returnTo="/admin">
              {makeExplanatoryVideoTable()}
            </SecurityRoute>
          }
        />
        {/* FAQ Explanatory Video Category */}
        <Route
          path="/ajuda/categoria"
          element={
            <SecurityRoute securePaths={[adminPerms.seeVideoCategory]} returnTo="/admin">
              {makeExplanatoryCategory()}
            </SecurityRoute>
          }
        />
        {/* Serviços */}
        <Route
          path="/servicos"
          element={
            <SecurityRoute securePaths={[adminPerms.seeServiceCodes]} returnTo="/admin">
              {makeServiceList()}
            </SecurityRoute>
          }
        />
        {/* Cnae */}
        <Route
          path="/cnaes"
          element={
            <SecurityRoute securePaths={[adminPerms.seeCnaes]} returnTo="/admin">
              {makeCnaeList()}
            </SecurityRoute>
          }
        />
        {/* Respostas-ISS*/}
        <Route
          path="/respostas/respostas-iss"
          element={
            <SecurityRoute securePaths={[adminPerms.seeIssAnswers]} returnTo="/admin">
              {makeIssAnswerTable()}
            </SecurityRoute>
          }
        />
        {/* Respostas */}
        <Route
          path="/respostas"
          element={
            <SecurityRoute securePaths={[adminPerms.seeAnswers]} returnTo="/admin">
              {makeAnswerTable()}
            </SecurityRoute>
          }
        />
        {/* Tipos de resposta */}
        <Route
          path="/tipos-de-resposta"
          element={
            <SecurityRoute securePaths={[adminPerms.seeAnswerTypes]} returnTo="/admin">
              {makeTypesAnswerTable()}
            </SecurityRoute>
          }
        />
        {/* Itens de resposta */}
        <Route
          path="/itens-de-resposta"
          element={
            <SecurityRoute securePaths={[adminPerms.seeAnswerItems]} returnTo="/admin">
              {makeItemAnswerTable()}
            </SecurityRoute>
          }
        />
        {/* Anotaçôes */}
        <Route
          path="/anotacoes"
          element={
            <SecurityRoute securePaths={[adminPerms.seeAnnotations]} returnTo="/admin">
              {makeAnnotationTable()}
            </SecurityRoute>
          }
        />

        {/* SearchTerm */}
        <Route
          path="/termos-de-pesquisa"
          element={
            <SecurityRoute securePaths={[adminPerms.seeSearchTerms]} returnTo="/admin">
              {makeListTerms()}
            </SecurityRoute>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AdminRoutes;
