import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Typography as Text,
  Button,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/components/Common/Input";
import ProfileForms from "../../UserProfile/Forms/ProfileForms";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { clientPerms } from "~/presentation/security/SecurePaths";

function UserForms({
  user,
  handleChangeInput,
  errors,
  handleClose,
  handleSubmit,
  userProfiles,
  refreshProfiles,
  userProfilesUseCases,
}) {
  const { id: userId, companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const handleOpenProfileModal = () => setOpenProfileModal(true);
  const handleCloseProfileModal = () => setOpenProfileModal(false);
  const isCreate = user.id === 0;

  const isLoggedUser = user.id === userId;

  const userProfileOptions = userProfiles?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (userProfiles.length === 0) {
      refreshProfiles();
    }
  }, []);

  return (
    <Box sx={{ padding: 3, background: "#fff" }}>
      <Text
        variant="h6"
        component="h2"
        sx={{ marginBlock: 2, fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}
      >
        {isCreate ? "Criar" : "Editar"} Usuário
      </Text>

      <FormControl>
        {user && (
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                title="Nome"
                styles={styles.field}
                name="name"
                type="text"
                value={user.name}
                onChange={(e) => handleChangeInput("name", e.target.value)}
                error={errors.name}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                title="Email"
                styles={styles.field}
                name="email"
                type="email"
                value={user.email}
                onChange={(e) => handleChangeInput("email", e.target.value)}
                error={errors.email}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="mask"
                title="Telefone"
                mask="(00) 0000-0000"
                name="homePhone"
                value={user?.homePhone || ""}
                onChange={(e) => handleChangeInput("homePhone", e)}
                error={errors.homePhone}
                styles={styles.field}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="mask"
                title="Celular"
                mask="(00) 00000-0000"
                name="mobilePhone"
                value={user?.mobilePhone || ""}
                onChange={(e) => handleChangeInput("mobilePhone", e)}
                error={errors.mobilePhone}
                styles={styles.field}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                name="password"
                title="Senha *"
                type="password"
                value={user?.password || ""}
                onChange={(e) => handleChangeInput("password", e.target.value)}
                error={errors.password}
                styles={styles.field}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                name="confirmPassword"
                title="Confirmar Senha *"
                type="password"
                value={user?.confirmPassword || ""}
                onChange={(e) => handleChangeInput("confirmPassword", e.target.value)}
                error={errors.confirmPassword}
                styles={styles.field}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="select"
                title="Perfil de Usuário"
                name="userProfileId"
                placeholder="Selecione o perfil..."
                value={userProfileOptions.find((item) => item.value === user.userProfileId)}
                onChange={(e) => handleChangeInput("userProfileId", e.value)}
                options={userProfileOptions}
                error={errors.userProfileId}
                style={styles.field}
                height={120}
                disabled={isLoggedUser}
                icon={{
                  title: "Criar uma novo perfil",
                  func: () => handleOpenProfileModal(),
                  securePaths: [clientPerms.addUserProfile],
                }}
              />
              <ModalBox open={openProfileModal} onClose={handleCloseProfileModal} bg="white">
                <ProfileForms
                  data={{
                    id: 0,
                    name: "",
                    isInternalUse: false,
                    companyId: companyId,
                    permission: {
                      admins: null,
                      clients: null,
                      reinfs: null,
                    },
                  }}
                  userProfilesUseCases={userProfilesUseCases}
                  companyId={companyId}
                  refreshData={refreshProfiles}
                  onCloseForm={handleCloseProfileModal}
                />
              </ModalBox>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControlLabel
                sx={{ ...formStyles.controlLabel, pt: 2.5, pl: 1 }}
                required
                control={
                  <Switch
                    checked={user.active}
                    onChange={(e) => handleChangeInput("active", e.target.checked)}
                  />
                }
                label="Usuário ativo?"
              />
            </Grid>
          </Grid>
        )}
      </FormControl>
      <Box sx={styles.ButtonGroup}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button sx={styles.CancelButton} variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button sx={styles.Button} variant="contained" onClick={(e) => handleSubmit(e, user)}>
            Salvar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default UserForms;

const styles = {
  Button: {
    background: "#081445",
    paddingInline: { xs: 2, md: 4 },
    paddingBlock: 1,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0e3279",
    },
  },
  ButtonGroup: { display: "flex", gap: 1, marginTop: 2, justifyContent: "end" },
  ButtonAdd: {
    background: "#1341a1",
    padding: 0,
    color: "#FFF",
    paddingInline: 4,
    borderRadius: 20,
    height: 48,
    "&:hover": {
      backgroundColor: "#1341a1",
    },
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    textAlign: { xs: "center", md: "left" },
  },

  switch: {
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      "&.Mui-checked": {
        transform: "translateX(14px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#0e4292",
          opacity: 1,
          border: "none",
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#0e4292",
        border: "6px solid #fff",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 20,
      height: 20,
      margin: 0,
      backgroundColor: "#fff",
      border: "1px solid #E9E9EA",
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: "#E9E9EA",
      boxSizing: "border-box",
    },
  },
  field: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    borderRadius: "3px",
    borderWidth: "1px",
  },

  CancelButton: {
    backgroundColor: "#EFF2F4",
    color: "black",
    "&:hover": {
      color: "#fff",
    },
  },
};
