import React, { useContext, useEffect, useRef, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import TableActions from "~/presentation/components/TableActions";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import PageListHeader from "../components/PageListHeader";
import { Box } from "@mui/material";
import Table from "~/presentation/components/Table";
import { adminPerms } from "~/presentation/security/SecurePaths";

function Company({
  companyUseCases,
  planUseCases,
  stateUseCases,
  cityUseCases,
  legalNatureUseCases,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //manter a página da tabela sempre atual
  const [page, setPage] = useState(0);

  //todas as empresas
  const [companies, setCompanies] = useState([]);

  //Select options
  const [plans, setPlans] = useState([]);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [legalNatureOptions, setLegalNatureOptions] = useState([]);
  const [citiesCache, setCitiesCache] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const response = await companyUseCases.getCompanies({
        isSummary: false,
        checkPermission: true,
      });
      setCompanies(response);
    } catch (error) {
      showError(error, "Erro ao carregar empresas");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 2 },
    { field: "email", headerName: "Email", flex: 2 },
    { field: "planName", headerName: "Plano", flex: 1 },
    {
      field: "active",
      headerName: "Ativo",
      align: "center",

      renderCell: (params) => (params.value ? "Sim" : "Não"),
    },
    { field: "categoryName", headerName: "Categoria", flex: 1 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editCompanies]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteCompanies]}
        />
      ),
    },
  ];

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo empresa");
    try {
      await companyUseCases.deleteCompany(id);
      showSuccess("Empresa excluída!");
      setCompanies((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir empresa");
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta empresa?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Só queremos que faça o fetch quando o usuário clicar pela 1º vez para editar
  //Depois disso, queremos que não haja fetch
  const handleGetFormOptions = async () => {
    if (states.length === 0) loadStates();
    if (legalNatureOptions.length === 0) loadLegalNatureOptions();
    if (citiesCache.length === 0) loadCities();
  };

  const getPlans = async () => {
    try {
      openBackdrop(true, "Carregando planos");
      const plans = await planUseCases.getPlans({ ispublic: false, checkPermission: false });
      const result = dataToSelectMapper(plans);
      setPlans(result);
    } catch (error) {
      showError(error, "Erro ao carregar planos");
    } finally {
      openBackdrop(false);
    }
  };

  const getCategories = async () => {
    try {
      openBackdrop(true, "Carregando categorias");
      const categories = await companyUseCases.getCompanyCategories({ checkPermission: false });
      const result = dataToSelectMapper(categories);
      setCategories(result);
    } catch (error) {
      showError(error, "Erro ao carregar categorias");
    } finally {
      openBackdrop(false);
    }
  };

  const loadStates = async () => {
    try {
      const states = await stateUseCases.getStates();
      const result = dataToSelectMapper(states);
      setStates(result);
    } catch (error) {
      showError(error, "Erro ao carregar estados");
    }
  };

  const loadCities = async () => {
    try {
      const cities = await cityUseCases.getAll({ checkPermission: false });
      const result = dataToSelectMapper(cities);
      setCitiesCache(result);
    } catch (error) {
      showError(error, "Erro ao carregar cidades");
    }
  };

  const loadLegalNatureOptions = async () => {
    try {
      const options = await legalNatureUseCases.list();
      const result = dataToSelectMapper(options);
      setLegalNatureOptions(result);
    } catch (error) {
      showError(error, "Erro ao carregar natureza de rendimento");
    }
  };

  return (
    <Box>
      {openForm ? (
        <RegisterEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          onGetFormOptions={handleGetFormOptions}
          plans={plans}
          categories={categories}
          states={states}
          legalNatureOptions={legalNatureOptions}
          citiesCache={citiesCache}
          getPlans={getPlans}
          getCategories={getCategories}
        />
      ) : (
        <>
          <PageListHeader
            title="Empresa"
            handleAdd={() => handleOpenForm()}
            addSecurePath={[adminPerms.addCompanies]}
          />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={companies} page={page} setPage={setPage} />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Company;
