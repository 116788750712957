import { useLocation } from "react-router-dom";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { hasAdminPermission, hasReinfPermission } from "~/presentation/security/SecurityPath";

export default () => {
  const { pathname } = useLocation();

  const user = JSON.parse(localStorage.getItem("GT_user"));
  const clientNavbarItems = [
    {
      id: 1,
      label: "Início",
      name: "cliente",
      link: "/cliente",
    },
    {
      id: 2,
      label: "GT-Fácil",
      name: "gt-facil",
      link: "/gt-facil",
      securePaths: [
        clientPerms.seeCnaeResearch,
        clientPerms.seeLcResearch,
        clientPerms.seeFreeSearch,
      ],
    },
    ...(hasReinfPermission()
      ? [
          {
            id: 3,
            label: "GT-Reinf",
            name: "gt-reinf",
            link: "/reinf",
          },
        ]
      : []),

    {
      id: 4,
      label: "Conteúdo Selecionado",
      name: "conteudo-selecionado",
      link: pathname,
      securePaths: [clientPerms.seeLegislation, clientPerms.seeArticles, clientPerms.seeMultimedia],
      subLinks: [
        {
          id: 1,
          link: "/cliente/conteudo-selecionado/legislacao",
          label: "Legislação",
          securePaths: [clientPerms.seeLegislation],
        },
        {
          id: 2,
          link: "/cliente/conteudo-selecionado/artigo",
          label: "Artigos",
          securePaths: [clientPerms.seeArticles],
        },
        {
          id: 3,
          link: "/cliente/conteudo-selecionado/multimidia",
          label: "Multimídia",
          securePaths: [clientPerms.seeMultimedia],
        },
      ],
    },
    {
      id: 5,
      label: "Blog",
      name: "blog",
      link: "/blog",
      securePaths: [clientPerms.seeBlog],
    },
    {
      id: 6,
      label: "Outros",
      name: "outros",
      link: pathname,
      subLinks: user && [
        {
          id: 1,
          link: "/cliente/ajuda",
          label: "Ajuda",
        },

        {
          id: 2,
          link: "/cliente/qso",
          label: "Quadro QSO",
        },
        {
          id: 4,
          link: "/cliente/meu-painel?page=1",
          label: "Meu Painel",
        },
        ...(hasAdminPermission()
          ? [
              {
                id: 3,
                link: "/admin",
                label: "Admin",
              },
            ]
          : []),
      ],
    },
  ];

  return clientNavbarItems;
};
