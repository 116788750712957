import React from "react";
import {makeTaxInformationUseCases} from "~/main/factories/use-cases/tax-information";
import TaxInformationList from "~/presentation/views/Admin/TaxInformation/List";

export const makeTaxInformationList = (params) => {
  return (
    <TaxInformationList
      {...params}
      taxInformationUseCases={makeTaxInformationUseCases()}
    />
  );
};
