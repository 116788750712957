import React, { useContext, useState } from "react";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import PageListHeader from "../../../components/PageListHeader";
import Item from "./Item/Item";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import CopyItems from "./CopyItems";

const cpomList = [
  {
    id: 0,
    title: "Nenhum",
  },
  {
    id: 1,
    title: "CPOM",
  },
  {
    id: 2,
    title: "CM",
  },
];

const IssAnswerForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  itemsAnswer,
  groups,
  fetchTypeAnswer,
  allIssAnswers,
  loadDataById,
}) => {
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const [allowCopyItems, setAllowCopyItems] = useState(true);

  const handleOpenFormItem = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <Item
          onClose={closeDialog}
          item={data}
          itemAnswers={itemsAnswer}
          handleAddNewItem={handleAddNewItem}
          groups={groups}
          fetchTypeAnswer={fetchTypeAnswer}
        />
      ),
    });
  };

  const handleDelete = (id) => {
    if (values?.items?.length === 1) {
      onChangeValue("items", []);
    } else {
      const filtedValue = values?.items?.filter((item) => item.id !== id);
      onChangeValue("items", filtedValue);
    }
  };

  const handleAddNewItem = (item, isCreate) => {
    // cópia do array atual
    const updatedItems = values?.items ? [...values.items] : [];

    if (isCreate) {
      // Adiciona o novo item ao final
      updatedItems.push(item);
    } else {
      // Encontra o índice do item pelo 'id'
      const index = updatedItems.findIndex((existingItem) => existingItem.id === item.id);

      if (index !== -1) {
        // Substitui o item no índice encontrado
        updatedItems[index] = { ...item };
      }
    }
    // Atualiza o estado com o novo array
    onChangeValue("items", updatedItems);
  };

  const handleOpenCopyItemsModal = () => {
    if (allowCopyItems)
      openDialogBox({
        maxWidth: "xs",
        fullWidth: true,
        customComponent: () => (
          <CopyItems
            onClose={closeDialog}
            allIssAnswers={allIssAnswers}
            onCopy={handleCopyItemsFromAnswer}
            loadDataById={loadDataById}
            currentId={values.id}
          />
        ),
      });
  };

  const handleCopyItemsFromAnswer = async (city) => {
    const fetchCity = await loadDataById(city.id);
    const copyedItems = fetchCity ? [...fetchCity.items] : [];
    if (copyedItems.length > 0) {
      setAllowCopyItems(false);
      const oldItems = [...values.items];
      const newItems = [...oldItems, ...copyedItems];
      onChangeValue("items", newItems);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: 1,
          paddingTop: 1,
          paddingInline: 3,
          backgroundColor: "#E6E6E6",
          borderRadius: "12px",
          "@media (min-width: 900px)": {
            margin: 3,
          },
        }}
      >
        <Typography sx={formStyles.title}>
          Cidade selecionada: <em>{values.cityName}</em>
        </Typography>
      </Box>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Resposta</Typography>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Input
              type="select"
              name="cpom"
              title="Tipo de Resposta"
              value={cpomList.find((item) => item.id === values.cpom)}
              onChange={(item) => onChangeValue("cpom", item.id)}
              error={errors.cpom}
              options={cpomList}
              getOptionLabel={(option) => option.title}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>CPOM Dispensado</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                name="isDispensedCPOM"
                value={values.isDispensedCPOM}
                row
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isDispensedCPOM", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>Legislação</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                name="isLegislation"
                aria-label="isLegislation"
                row
                value={values.isLegislation}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isLegislation", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>ERO Dispensado</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                value={values.isDispensedEROS}
                name="isDispensedEROS"
                row
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isDispensedEROS", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>Substituição específica</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                name="isSpecificSubstituition"
                row
                value={values.isSpecificSubstituition}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isSpecificSubstituition", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ ...formStyles.container, border: errors.items && "1px solid #d32f2f" }}>
        <PageListHeader
          title="Itens de resposta"
          titleAdd="Criar nova resposta"
          handleAdd={() => handleOpenFormItem()}
          handleCopyModal={{
            title: "Copiar itens de outra resposta ISS",
            command: handleOpenCopyItemsModal,
            disabled: !allowCopyItems,
          }}
          error={errors.items}
        />
        <Table
          columns={[
            {
              field: "itemTypeName",
              headerName: "Tipo",
              align: "center",
              flex: 2,
              renderCell: ({ row }) => row.itemTypeName || "-",
            },
            {
              field: "blockCode",
              headerName: "Bloco",
              align: "center",
              flex: 1,
              renderCell: ({ value }) => value || "-",
            },
            {
              field: "servicesCode",
              headerName: "Código de Serviço",
              align: "center",
              flex: 1,
              renderCell: ({ value }) => value || "-",
            },
            {
              field: "isDefault",
              headerName: "Padrão",
              align: "center",
              flex: 1,
              renderCell: ({ value }) => (value ? "Sim" : "Não"),
            },
            {
              field: "actions",
              headerName: "Ações",
              sortable: false,
              filterable: false,
              align: "center",
              flex: 1,
              renderCell: ({ row }) => (
                <TableActions
                  handleEdit={() => handleOpenFormItem(row)}
                  handleDelete={() => handleDelete(row.id)}
                />
              ),
            },
          ]}
          data={values?.items?.length > 0 ? values?.items : []}
        />

        {errors.items && <Typography sx={formStyles.errorMsg}>{errors.items}</Typography>}
      </Box>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default IssAnswerForm;
