import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
// functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box, Tooltip } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import CreateEditItemAnswer from "./CreateEdit/CreateEdit";
import { ChatOutlined } from "@mui/icons-material";
import { adminPerms } from "~/presentation/security/SecurePaths";

const ItemAnswer = ({ itemAnswerUseCases, typesAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //data
  const [itemsAnswer, setItemsAnswer] = useState([]);

  //options
  const [typesAnswer, setTypesAnswer] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "lg",
      fullWidth: true,
      customComponent: () => (
        <CreateEditItemAnswer
          data={data}
          onCloseForm={closeDialog}
          refreshData={fetchData}
          fetchOptionsData={fetchOptionsData}
          typesAnswer={typesAnswer}
        />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const apiItemsAnswer = await itemAnswerUseCases.getAll({ checkPermission: true });
      setItemsAnswer(apiItemsAnswer);
    } catch (error) {
      showError(error, "Erro ao carregar itens de resposta");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "gtAnswerItemType",
      headerName: "Tipo",
      renderCell: (params) => params?.value?.title || "-",
      align: "left",
      flex: 1,
    },
    { field: "title", headerName: "Título", flex: 5 },
    {
      field: "text",
      headerName: "Texto",
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip
          title={<Box sx={{ maxWidth: 300 }} dangerouslySetInnerHTML={{ __html: params?.value }} />}
        >
          <ChatOutlined />
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editAnswerItems]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteAnswerItems]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo item de resposta");
      await itemAnswerUseCases.delete(id);
      setItemsAnswer((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir item de resposta");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este item de resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchOptionsData = async () => {
    try {
      openBackdrop(true, "Carregando Tipos de Resposta");
      const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer({ checkPermission: false });
      setTypesAnswer(apiTypesAnswer);
    } catch (error) {
      showError(error, "Erro ao carregar tipos de resposta");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title="Item de resposta"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addAnswerItems]}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={itemsAnswer} />
      </Box>
    </>
  );
};

export default ItemAnswer;
