import React, { useRef, useContext } from "react";
import { PowerSettingsNew as PowerSettingsNewIcon } from "@mui/icons-material";
import {
  IconButton,
  MenuList,
  Avatar,
  Box,
  MenuItem,
  Typography as Text,
  Link,
} from "@mui/material";
import Menu from "~/presentation/components/Menu";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import AppContext from "~/presentation/AppContext";
import CountDownToken from "../CountDownToken/CountDownToken";
import { useNavigate } from "react-router-dom";

const ReinfToolbar = () => {
  const { handleLogout } = useContext(AppContext);
  const { name, companyInfor } = JSON.parse(localStorage.getItem("GT_user"));
  const profileBtnRef = useRef(null);
  const navigate = useNavigate();
  const userFirstLetter = name.charAt(0) || "";

  return (
    <Box sx={styles.container}>
      <Box sx={styles.textContainar}>
        <Text sx={styles.text}>Usuário | {name}</Text>
        <Text sx={styles.text}>
          {companyInfor?.name} | {companyInfor?.cnpj ? formatCpfCnpj(companyInfor.cnpj) : ""}
        </Text>
      </Box>
      <Box sx={styles.iconsContainer}>
        <Link
          underline="none"
          sx={{ ...styles.text, ...styles.link }}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/cliente/ajuda")}
        >
          Instruções de uso do módulo
        </Link>
        <CountDownToken />
        <Menu
          anchorRef={profileBtnRef}
          anchorButton={(props) => (
            <IconButton {...props}>
              <Avatar color={"#254C9F"} sx={styles.avatar}>
                {userFirstLetter}
              </Avatar>
            </IconButton>
          )}
        >
          {(props) => (
            <MenuList id="profile-options-list" {...props}>
              <MenuItem onClick={() => navigate("/cliente")}>Ir para Início</MenuItem>
              <MenuItem onClick={() => navigate("/reinf/configuracoes")}>Configurações</MenuItem>
              <MenuItem onClick={handleLogout}>
                Sair
                <PowerSettingsNewIcon fontSize="small" />
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default ReinfToolbar;

const styles = {
  container: {
    height: "100%",
    width: "100%",
    maxHeight: "50px",
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gap: "6px",
  },
  iconsContainer: {
    display: "flex",
    justifySelf: " flex-end",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  avatar: {
    height: "23px",
    width: "23px",
  },
  textContainar: {
    width: "150px",
    "@media (min-width: 400px)": {
      width: "100%",
    },
    "@media (min-width: 600px)": {
      fontSize: 12,
      width: "150px",
    },
    "@media (min-width: 800px)": {
      width: "300px",
    },
    "@media (min-width: 900px)": {
      width: "100%",
    },
  },
  text: {
    fontFamily: "Montserrat",
    color: "#fff",
    fontSize: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    "@media (min-width: 600px)": {
      fontSize: 12,
    },
  },
  link: {
    textAlign: "right",
    width: "10ch",
    "@media (min-width: 600px)": {
      fontSize: 12,
    },
    "@media (min-width: 900px)": {
      width: "100%",
    },
  },
};
