import React, { useEffect, useContext, useState } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatDateToBrazilian } from "../../../Utils/utilsDate";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { ConsultSituation } from "./ConsultSituation";
import { HeaderSectionPage } from "../../../components/HeaderSectionPage";
import Table from "../../../components/Table/Table";

export const ConsultR1000 = ({ refresh }) => {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { showError } = useContext(SnackbarContext);

  //loading
  const [loading, setLoading] = useState(false);
  //dados eventos
  const [eventsData, setEventsData] = useState([]);

  //dados da situação dos eventos
  const [protocolDetails, setProtocolDetails] = useState({});

  useEffect(() => {
    handleRenderAllInfo(); //refresh callback info result sucess send
  }, [refresh]);

  //1º FETCH CERTIFICADO -> 2º FETCH EVENTOS -> 3º FAZER CONSULTA
  const handleRenderAllInfo = async () => {
    try {
      setLoading(true);
      //1º FETCH CERTIFICADO
      const cert = await fetchCertificates();
      //2º FETCH EVENTOS
      const events = await fetchEventsData();
      if (events.status === 200) {
        setEventsData(events.data);
        //3º FAZER CONSULTA
        if (cert) {
          await handleConsult(events.data, cert);
        } else {
          return showError({}, "Erro ao carregar certificados");
        }
      } else {
        showError({}, "Erro no retorno dos dados, verifique sua conexão");
      }
    } catch (error) {
      showError(error, "Erro no retorno dos dados");
    } finally {
      setLoading(false);
    }
  };

  const fetchCertificates = async () => {
    try {
      const CertsData = await apiDigitalCert.getDigitalCerts();
      const defaultCert = CertsData?.find((cert) => cert.isDefault);
      //usando o certificado default ou o primeiro da lista
      return defaultCert ? defaultCert?.id : CertsData[0]?.id;
    } catch (error) {
      showError(error, "Erro ao buscar certificados");
    }
  };

  const fetchEventsData = async () => {
    try {
      const events = await apiReinf.getReinfProtocol({ reinfEvent: 0 });
      return events;
    } catch (error) {
      showError(error, "Erro ao consultar protocolos");
    }
  };

  const handleConsult = async (events, certificate) => {
    const promises = events.map(async (event) => {
      if (event.receiptNumber) return event;

      const params = {
        DigitalCertId: certificate,
        ProtocolNumber: event.protocol,
        IsProduction: false,
      };
      return apiReinf
        .getReinfConsult(params)
        .then((updatedItem) => {
          return { protocol: event.protocol, data: updatedItem };
        })
        .catch((error) => {
          throw error;
        });
    });

    try {
      const response = await Promise.all(promises);
      const newProtocolResponseMap = {};
      response.forEach(({ protocol, data }) => {
        newProtocolResponseMap[protocol] = data;
      });
      setProtocolDetails(newProtocolResponseMap);
    } catch (error) {
      showError(error, "Erro ao consultar protocolos");
    }
  };

  const columns = [
    {
      field: "protocol",
      headerName: "Protocolo",
      align: "center",
    },
    {
      field: "receiptNumber",
      headerName: "Nº do recibo",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },
    {
      field: "sendingDate",
      headerName: "Data de Envio",
      renderCell: ({ value }) => formatDateToBrazilian(value) || "-",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row, value }) =>
        protocolDetails[row.protocol]?.descriptionResponse || value || "Não definida",
      align: "center",
    },
  ];

  return (
    <>
      <HeaderSectionPage
        title="R-1000 Enviados"
        sx={{ backgroundColor: "transparent", paddingInLine: 3, paddingTop: 3, paddingBottom: 0 }}
      />
      <Table
        columns={columns}
        data={eventsData}
        collapse={(props) => (
          <ConsultSituation
            protocol={props.protocol}
            responseDetails={protocolDetails[props.protocol]}
          />
        )}
        loading={loading}
        rowPadding="12px"
        blockCollapse={(props) => props.receiptNumber}
      />
    </>
  );
};
