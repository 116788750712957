import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { reinfPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";

export class ApiEstablishmentUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getEstablishment(companyId) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment?companyId=${companyId}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getEstablishmentById(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async registEstablishment(data) {
    if (!hasPermission([reinfPerms.addEstablishment])) {
      throw new Error(securityErrorMessage("adicionar estabelecimentos"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateEstablishment(data) {
    if (!hasPermission([reinfPerms.editEstablishment])) {
      throw new Error(securityErrorMessage("editar estabelecimentos"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteEstablishmentById(id) {
    if (!hasPermission([reinfPerms.deleteEstablishment])) {
      throw new Error(securityErrorMessage("excluir estabelecimentos"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data);
    }
  }
}
