import React from "react";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { Typography, Box, Avatar } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import Tree from "~/presentation/components/TreeView/Tree";

function Form({
  values,
  onChangeValue,
  onSubmit,
  errors,
  permissions,
  onPermissionChange,
  isLoading,
}) {
  const keysArray = (values?.permission && Object.keys(values?.permission)) || [];

  const renderUsers = () => {
    if (values?.users?.length > 0) {
      return values.users.map((u, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar sx={{ textTransform: "capitalize", fontWeight: 700 }}>
            {u.name.split("")[0]}
          </Avatar>
          <Typography sx={formStyles.subtitle}>{u.name || u.email}</Typography>
        </Box>
      ));
    }
  };
  return (
    <>
      <Box sx={{ ...formStyles.container, p: { xs: 1, md: 2 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3,
          }}
        >
          <Box sx={styles.halfContainer}>
            <Typography sx={{ ...formStyles.title, color: errors.name && "#d32f2f" }}>
              Descrição
            </Typography>
            <Input
              title="Nome"
              type="text"
              value={values.name}
              name="name"
              onChange={(e) => {
                onChangeValue("name", e.target.value);
              }}
              error={errors.name}
            />
            <Box sx={{ marginTop: 1 }}>
              <Typography sx={formStyles.subtitle}>Usuários do Perfil</Typography>
              <Box
                sx={{
                  background: "white",
                  borderRadius: "12px",
                  border: "1px solid #7D7D7D",
                  width: "100%",
                  padding: "8px 12px",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                  height: "400px",
                  gap: 1,
                }}
              >
                {renderUsers()}
              </Box>
            </Box>
          </Box>

          <Box sx={styles.halfContainer}>
            <Typography sx={{ ...formStyles.title, color: errors.permissionIDs && "#d32f2f" }}>
              Permissões
            </Typography>
            <Tree
              data={permissions}
              onPermissionChange={onPermissionChange}
              isLoading={isLoading}
              arrayLoading={keysArray}
              inputSearchStyle={{
                marginLeft: "none",
                marginBottom: 4,
                backgroundColor: "white",
                border: "1px solid #7D7D7D",
              }}
              iconColor="#222222"
              treeStyles={{
                background: "white",
                borderRadius: "12px",
                border: "1px solid #7D7D7D",
              }}
              error={errors.permissionIDs}
            />
            {errors.permissionIDs && (
              <Typography sx={{ ...formStyles.errorMsg, marginTop: "auto" }}>
                {errors.permissionIDs}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={onSubmit}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
}

export default Form;

const styles = {
  halfContainer: {
    width: "100%",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    height: { md: "550px" },
    maxHeight: "550px",
    borderRadius: "12px",
  },
};
