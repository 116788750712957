import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";

const Service = ({ cnaesUseCases, serviceUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [services, setServices] = useState([]);

  //Select
  const [cnaes, setCnaes] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "lg",
      fullWidth: true,
      customComponent: () => (
        <CreateEdit
          data={data}
          onCloseForm={closeDialog}
          refreshData={fetchData}
          getSelectOptions={getSelectOptions}
          cnaes={cnaes}
        />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const apiService = await serviceUseCases.listService({ checkPermission: true });
      setServices(apiService);
    } catch (error) {
      showError(error, "Erro ao carregar códigos de serviço");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "code", headerName: "Código", flex: 1, align: "center" },
    { field: "description", headerName: "Descrição", flex: 6 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForm(params.row)}
          editSecurePath={[adminPerms.editServiceCodes]}
          handleDelete={() => deleteDialog(params.row.id)}
          deleteSecurePath={[adminPerms.deleteServiceCodes]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo serviço");
      await serviceUseCases.deleteServiceCode(id);
      setServices((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir código de serviço");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este serviço?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getSelectOptions = async () => {
    try {
      openBackdrop(true, "Carregando cnaes");
      const { data } = await cnaesUseCases.listCnaes({ checkPermission: false });
      setCnaes(data);
    } catch (error) {
      showError("Erro ao carregar cnaes.");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title="Códigos LCP 116"
        titleAccess="Novo código"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addServiceCodes]}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={services} />
      </Box>
    </>
  );
};

export default Service;
