import React from "react";
// controller
import MessagesController from "~/presentation/views/Admin/components/MessagesController";
// data
import filtersList from "~/presentation/views/Admin/TaxInformation/List/FilterList.js";
// components
import ListActions from "~/presentation/views/Admin/components/MessageListActions";
// material ui
import { FilterList as FilterListIcon } from "@mui/icons-material";
import MessageInfoList from "../../components/MessageInfoList";
import OpenPopover from "~/presentation/components/Popover";
import PageListHeader from "../../components/PageListHeader";
import { Box, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid2";

const TaxInformation = ({ taxInformationUseCases }) => {
  
  const getTaxInformation = async () => {
    return await taxInformationUseCases.getAll();
  };

  const handleDeleteTaxInformation = (ids) => {
    return taxInformationUseCases.deleteTaxInformation(ids);
  };

  const {
    mobile,
    selectedMessages,
    messages,
    setSelectedMessages,
    handleDelete,
    handleRefresh,
    numberOfPages,
    page,
    setPage,
    fetchMessagesWithFilter,
    CustomFilterList,
    handleSelectMessage,
    handleClickMessage,
    filter,
  } = MessagesController({
    getMessages: getTaxInformation,
    filtersList,
    replyMessagePath: "/admin/informacoes-fiscais/responder/",
    onDelete: handleDeleteTaxInformation,
  });

  return (
    <Box>
      <PageListHeader title="Informações Fiscais" />
      <Box sx={{ margin: 3 }}>
        <Grid container spacing={3}>
          <Grid size={3}>
            <Box sx={styles.box}>{!mobile && <CustomFilterList />}</Box>
          </Grid>
          <Grid size={9}>
            <ListActions
              selectedItems={selectedMessages}
              items={messages}
              onChange={setSelectedMessages}
              handleDelete={handleDelete}
              handleRefresh={handleRefresh}
              renderAfter={
                mobile &&
                (() => (
                  <OpenPopover icon={FilterListIcon} label={filter.alias}>
                    {() => <CustomFilterList />}
                  </OpenPopover>
                ))
              }
            />
            <Box sx={styles.box}>
              <MessageInfoList
                messages={messages}
                selectedMessages={selectedMessages}
                handleSelectMessage={handleSelectMessage}
                disableGutters={mobile}
                onClick={handleClickMessage}
              />
            </Box>

            <Pagination
              size="small"
              count={numberOfPages}
              page={page}
              onChange={(event, selectedPage) => [
                setPage(page),
                fetchMessagesWithFilter({ page: selectedPage }),
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TaxInformation;

const styles = {
  box: {
    backgroundColor: "#E6E6E6",
    borderRadius: 2,
    height: "100%",
  },
};
