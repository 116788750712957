import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import blogNavbarItems from "~/presentation/views/Blog/data/blogNavbarItems";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import ContentCard from "~/presentation/views/Blog/components/ContentCard";

import {
  Container,
  SectionHeader,
  SearchField,
  FiltersWrapper,
  CategoryButton,
  ClearFilterButton,
  CategoriesWrapper,
  SearchFieldWrapper,
  SearchAndFilterWrapper,
  CardsWrapper,
  Pagination,
  InboxIcon,
  NoPostsFound,
} from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, CircularProgress, PaginationItem } from "@mui/material";

const ITEMS_PER_PAGE = 15;
const DEFAULT_IMAGE = "/assets/bg-contentblog.png";

const BlogList = ({ blogUseCases, blogCategoryUseCases }) => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState();
  const [blogCategories, setBlogCategories] = useState();
  const [numberOfPages, setNumberOfPages] = useState();
  const query = new URLSearchParams(useLocation().search);
  const serie = query.get("categoryId");
  const search = query.get("text");
  const page = query.get("page") || 1;

  const getPosts = async (serie, search, page) => {
    const response = await blogUseCases.getBlogPosts(true, {
      pageIndex: page,
      limit: ITEMS_PER_PAGE,
      isSummary: true,
      text: search,
      categoryId: serie,
    });
    const { posts, totalPages } = response;
    setPosts(posts);
    setNumberOfPages(totalPages);
    if (serie || search) {
      const filteredPosts = posts.filter((post) => {
        if (serie) {
          return post.categoryId === parseInt(serie);
        }
        if (search) {
          return (
            post.title.toLowerCase().includes(search.toLowerCase()) ||
            post.text.toLowerCase().includes(search.toLowerCase())
          );
        }
      });
      setPosts(filteredPosts);
      const numberOfPages = Math.ceil(filteredPosts.length / ITEMS_PER_PAGE);
      setNumberOfPages(numberOfPages);
    }
  };

  const getBlogCategories = async () => {
    setBlogCategories(await blogCategoryUseCases.getBlogCategories({ checkPermission: false }));
  };

  const handleSearch = (value) => goToList(serie, value, 1);

  const handleFilter = (category) => goToList(category, search, 1);

  const clearFilter = () => goToList(undefined, search, 1);

  const goToPage = (page) => {
    goToList(serie, search, page);
  };

  const goToList = (serieId, search, page) => {
    const url = `/blog/list?`;
    const serie = serieId ? "categoryId=" + serieId : "";
    const searchStr = search ? "&text=" + search : "";
    const pageStr = "&page=" + page;
    const encodedUrl = encodeURI(url + serie + searchStr + pageStr);
    navigate(encodedUrl);
  };

  const handleNavigateToPost = (id) => {
    navigate("/blog/post/" + id);
  };

  const dataLoaded = () => !!posts;

  useEffect(() => {
    getBlogCategories();
  }, []);

  useEffect(() => {
    getPosts(serie, search, page);
  }, [serie, search, page]);

  return (
    <HomeLayout
      className="blog"
      navbarItems={blogNavbarItems}
      loggedNavbarItems={clientNavbarItems()}
    >
      {({ openModal }) =>
        !dataLoaded() ? (
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#FFF",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <Container>
              <SectionHeader text="Posts do Blog" />
              <SearchAndFilterWrapper>
                <FiltersWrapper>
                  <h3>Filtrar por Categoria:</h3>
                  <CategoriesWrapper>
                    {blogCategories &&
                      blogCategories.map((category) => (
                        <CategoryButton
                          key={"category-" + category.title}
                          onClick={() => handleFilter(category.id)}
                          className={category.id == serie && "focused"}
                        >
                          {category.title}
                        </CategoryButton>
                      ))}
                    <ClearFilterButton onClick={clearFilter}>Limpar</ClearFilterButton>
                  </CategoriesWrapper>
                </FiltersWrapper>
                <SearchFieldWrapper>
                  <h3>Pesquisar</h3>
                  <SearchField
                    initialValue={search}
                    onSearch={handleSearch}
                    clearSearch
                    maxWidth={300}
                  />
                </SearchFieldWrapper>
              </SearchAndFilterWrapper>

              <CardsWrapper>
                {posts && posts.length > 0 ? (
                  [
                    posts.map((post) => (
                      <ContentCard
                        key={post.id}
                        imgSrc={post.imageSrc}
                        title={post.title}
                        author={post.autor}
                        date={post.publishDate}
                        numberOfComments={post.numberOfComments}
                        tags={post.tags}
                        text={post.text}
                        onClick={() => handleNavigateToPost(post.id, openModal)}
                        defaultImage={DEFAULT_IMAGE}
                      />
                    )),
                    <Pagination
                      key="pagination"
                      size="small"
                      count={numberOfPages}
                      onChange={(_, selectedPage) => {
                        goToPage(selectedPage);
                      }}
                      page={Number(page)}
                      renderItem={(item) => (
                        <PaginationItem
                          {...item}
                          // style={{ color: "#fff", backgroundColor: "#1341a1" }}
                        />
                      )}
                    />,
                  ]
                ) : (
                  <NoPostsFound>
                    <InboxIcon />
                    <p>Não foram encontrados Posts do Blog correspondentes à pesquisa.</p>
                  </NoPostsFound>
                )}
              </CardsWrapper>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                size="small"
                style={{ position: "fixed", top: "120px", left: "10px", backgroundColor: "#fff" }}
                startIcon={<ArrowBackIcon />}
              >
                Voltar
              </Button>
            </Container>
          </>
        )
      }
    </HomeLayout>
  );
};

export default BlogList;

// const styles = {
//   goBack: {
//     position: "absolute",
//     top: "100px",
//     left: "10px",
//     borderRadius: "50%",
//     width: 64,
//     height: 64,
//   },
// };
