import React, { useContext, useEffect, useState } from "react";
import PageListHeader from "../../components/PageListHeader";
import Form from "./Form/Form";
import { makeUserProfilesUseCases } from "~/main/factories/use-cases/user-profiles";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validation from "./Form/validation";

function CreateEditProfiles({ data, onCloseForm, refreshData }) {
  const userProfilesUseCases = makeUserProfilesUseCases();
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const isCreate = data?.id === 0;
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchPermissions = async (id) => {
    setIsLoading(true);
    try {
      const data = await userProfilesUseCases.checkProfilePermissions(id);
      setPermissions({
        admins: data.admins || [],
        clients: data.clients || [],
        reinfs: data.reinfs || [],
      });
    } catch (error) {
      showError(error, "Erro ao carregar permissões. ");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setValues(data);
      fetchPermissions(data.id || 0);
    }
  }, [data]);

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const extractAllowedPermissions = (permissions) => {
    //Criação do array com os ID's das permissões checked
    let allowedPermissions = [];
    Object.keys(permissions).forEach((categoryKey) => {
      permissions[categoryKey].forEach((category) => {
        category.items.forEach((item) => {
          if (item.isAllow) {
            if (!allowedPermissions.includes(item.id)) {
              allowedPermissions.push(item.id);
            }
          }
        });
      });
    });
    return allowedPermissions;
  };

  const handleValidation = async () => {
    const submitValues = {
      id: values.id,
      name: values.name,
      isInternalUse: values.isInternalUse,
      companyId: companyId,
      permissionIDs: extractAllowedPermissions(permissions),
    };

    validation
      .validate(submitValues, { abortEarly: false })
      .then(async () => handleSubmit(submitValues))
      .catch((errors) => {
        openBackdrop(false);
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };
  const handleSubmit = async (submitValues) => {
    try {
      openBackdrop(true, `${isCreate ? "Criando" : "Editando"} perfil`);
      if (isCreate) {
        await userProfilesUseCases.createProfile(submitValues);
      } else {
        await userProfilesUseCases.updateProfile(submitValues);
      }
      showSuccess(`Perfil ${isCreate ? "criado" : "editado"} com sucesso!`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "criar" : "editar"} perfil`);
    } finally {
      openBackdrop(false);
    }
  };

  const handlePermissionChange = (category, itemId) => {
    setPermissions((prev) => ({
      ...prev,
      [category]: prev[category].map((item) => ({
        ...item,
        items: item.items.map((subItem) =>
          subItem.id === itemId ? { ...subItem, isAllow: !subItem.isAllow } : subItem
        ),
      })),
    }));
    clearError("permissionIDs");
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Perfil`}
        handleBack={onCloseForm}
        fontSize={26}
      />
      {values && (
        <Form
          values={values}
          errors={errors}
          onChangeValue={handleChangeValue}
          onSubmit={handleValidation}
          permissions={permissions}
          onPermissionChange={handlePermissionChange}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default CreateEditProfiles;
