import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import { makeBlogHome, makeBlogPost, makeBlogList } from "~/main/factories/pages/blog";

const BlogRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={makeBlogHome()} />
      <Route path="/list/" element={makeBlogList()} />
      <Route path="/post/:id" element={makeBlogPost()} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default BlogRoutes;
