import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import validation from "./validation";
import removeMask from "~/presentation/functions/removeMask";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";

function ModalCreateEdit({
  data,
  closeModal,
  matrizCompanyId,
  establishmentsUseCases,
  refreshData,
}) {
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);

  const isEdit = data?.id > 0;

  const [values, setValues] = useState({
    id: 0,
    cnoCnpj: "",
    name: "",
    codeUG: "",
    companyId: matrizCompanyId,
  });
  const [errors, setErrors] = useState({});

  const onChangeField = (field, value) => {
    setValues((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (field) clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (data?.id) {
      setValues(data);
    }
  }, [data?.id]);

  const handleVerify = () => {
    const data = {
      ...values,
      cnoCnpj: removeMask(values.cnoCnpj),
    };
    validation
      .validate(data, { abortEarly: false })
      .then(async () => {
        handleSubmit(data);
      })
      .catch((errors) => {
        setErrors(
          errors?.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };
  const handleSubmit = async (data) => {
    openBackdrop(true, "Submetendo informação");
    setErrors({});
    try {
      if (isEdit) {
        await establishmentsUseCases.updateEstablishment(data);
      } else {
        await establishmentsUseCases.registEstablishment(data);
      }
      showSuccess(`Informações registradas com sucesso!`);
      closeModal();
      refreshData();
    } catch (error) {
      showError(error);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Box sx={styles.content}>
      <Typography sx={styles.Title}>{isEdit ? "Atualizar" : "Incluir"} Estabelecimento</Typography>
      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Input
            type="mask"
            title="CNO/CNPJ"
            mask={"00.000.000/0000-00"}
            placeholder="00.000.000/0000-00"
            value={values?.cnoCnpj}
            onChange={(value) => {
              onChangeField("cnoCnpj", value);
            }}
            error={errors.cnoCnpj}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Input
            type="text"
            title="Nome"
            placeholder="Nome do Estabelecimento"
            value={values?.name}
            onChange={(e) => {
              onChangeField("name", e.target.value);
            }}
            error={errors.name}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Input
            type="text"
            title="Código da Unidade Gestora"
            placeholder="Código UG"
            value={values?.codeUG}
            onChange={(e) => {
              onChangeField("codeUG", e.target.value);
            }}
            error={errors.codeUG}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 1, justifyContent: "end" }}>
        <Grid size={{ xs: 12, sm: 3, md: 2 }}>
          <RButton variant="outlined" onClick={closeModal}>
            Fechar
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 3 }}>
          <RButton onClick={handleVerify}>{isEdit ? "Atualizar" : "Incluir"}</RButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ModalCreateEdit;

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  content: {
    p: 4,
    background: "#E6E6E6",
  },
};
